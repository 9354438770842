import React, { useEffect } from 'react';
import axios from 'axios';
import LabeledFrame from 'components/LabeledFrame';
import moment from 'moment';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

let axiosCancelToken = null;

export default function QuotasConfig(props) {
	const data = props.data;
	const setDataField = props.setDataField;
	const errors = props.errors;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	return (
		<React.Fragment>
			<LabeledFrame 
				label="Configuración de cuotas"
				buttonsBackground="primary"
				className="mb-3"
			>
				<div className="row">
					<div className="col-md-4">
						<div className="mb-2">
							<CustomSelect label="Importe" className="form-control form-control-sm" onChange={(e) => setDataField('receipts_amount_calculation', e.target.value)} value={data.receipts_amount_calculation ?? ''}>
								<option value="estimate">automático</option>
								<option value="fixed">fijo</option>
							</CustomSelect>
							{/* <FieldSmallComment className="text-secondary">Si se selecciona importe "fijo" se generarán cuotas por ese importe y no se tendrá en cuenta el total del presupuesto</FieldSmallComment> */}
							{ errors.receipts_amount_calculation &&
								<div className="invalid-feedback d-block">{ errors.receipts_amount_calculation[0] }</div>
							}
						</div>
					</div>

					{ data.receipts_amount_calculation === 'fixed' &&
						<div className="col-md-4">
							<div className="mb-2 text-end">
								<CustomInput label="Importe fijo" type="number" className="form-control form-control-sm no-arrows text-end" onChange={(e) => setDataField('receipts_amount_fixed', e.target.value)} value={data.receipts_amount_fixed ?? ''} />
								{ errors.receipts_amount_fixed &&
									<div className="invalid-feedback d-block">{ errors.receipts_amount_fixed[0] }</div>
								}
							</div>
						</div>
					}

					<div className="col-md-4">
						<div className="mb-2 text-end">
							<CustomInput label="Nº emisiones" type="number" className="form-control form-control-sm no-arrows text-end" onChange={(e) => setDataField('receipts_issues_qty', e.target.value)} value={data.receipts_issues_qty ?? ''} />
							{ errors.receipts_issues_qty &&
								<div className="invalid-feedback d-block">{ errors.receipts_issues_qty[0] }</div>
							}
						</div>
					</div>

					<div className="col-md-12"></div>
					
					<div className="col-md-4">
						<div className="mb-2">
							<CustomInput label="Fecha primera emisión" type="date" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('receipts_first_issuance_date', e.target.value)} value={data.receipts_first_issuance_date ?? ''} />
							{ errors.receipts_first_issuance_date &&
								<div className="invalid-feedback d-block">{ errors.receipts_first_issuance_date[0] }</div>
							}
						</div>
					</div>

					<div className="col-md-3">
						<div className="mb-2">
							<CustomInput label="Perioricidad (cada X meses)" type="number" step="1" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('receipts_first_issuance_perioricity', e.target.value)} value={data.receipts_first_issuance_perioricity ?? ''} />
							{ errors.receipts_first_issuance_perioricity &&
								<div className="invalid-feedback d-block">{ errors.receipts_first_issuance_perioricity[0] }</div>
							}
						</div>
					</div>
					
					<div className="col-md-5">
						<div className="row">
							<div className="col-md-6 pe-0">
								<div className="mb-2">
									<CustomInput
										label="Última emisión" 
										type="text" 
										className="form-control form-control-sm no-arrows" 
										style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}} 
										defaultValue={data?.lastissuance ? '[' + data.lastissuance.number + '] ' + moment(data.lastissuance.date).format('DD-MM-YYYY') : ''} 
										readOnly 
									/>
								</div>
							</div>
							<div className="col-md-6 ps-0">
								<div className="mb-2">
									<CustomInput
										label="Próxima emisión"
										type="text" 
										className="form-control form-control-sm no-arrows" 
										style={{borderLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} 
										defaultValue={
											data?.lastissuance ? 
												'[' + (data.lastissuance.number + 1) + '] ' + moment(data.lastissuance.date).add(data?.receipts_first_issuance_perioricity ?? 0, 'months').format('DD-MM-YYYY') 
											: 
												(
													data?.receipts_first_issuance_date ? 
														'[1] ' + moment(data.receipts_first_issuance_date).format('DD-MM-YYYY') 
													: 
														''
												)
										}
										readOnly 
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-3">
						<div className="">
							<CustomInput label="Decimales redondeo" type="number" step="1" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('receipts_round_decimals', e.target.value)} value={data.receipts_round_decimals ?? ''} />
							{ errors.receipts_round_decimals &&
								<div className="invalid-feedback d-block">{ errors.receipts_round_decimals[0] }</div>
							}
						</div>
					</div>

					<div className="col-md-3">
						<div className="">
							<div><label className="d-flex align-items-center text-dark mb-1"><input type="radio" onChange={(e) => setDataField('receipts_round_direction', 'up')} checked={data.receipts_round_direction === 'up'} />&nbsp;Alza</label></div>
							<div><label className="d-flex align-items-center text-dark"><input type="radio" onChange={(e) => setDataField('receipts_round_direction', 'down')} checked={data.receipts_round_direction === 'down'} />&nbsp;Baja</label></div>
							{ errors.receipts_round_direction &&
								<div className="invalid-feedback d-block">{ errors.receipts_round_direction[0] }</div>
							}
						</div>
					</div>
					
				</div>
			</LabeledFrame>
		</React.Fragment>
	);
}