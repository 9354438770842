import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getUser } from 'helpers/user';
import { setCommunity as setCommunityGlobal } from 'helpers/community';
import { formatNumber, jsonToArray } from 'helpers/generic';

const Table = styled.table`
	width: 100%;
	font-size: 12px;
	line-height: 12px;

	tr {
		td,th {
			vertical-align: middle;

			&:nth-child(1) {
				width: 200px;
			}

			&:nth-child(3) {
				text-align: right;
				white-space: nowrap;
			}

			&:nth-child(4) {
				width: 50px;
				text-align: center;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function BankReconciliationPending() {
	let [treasuries, setTreasuries] = useState([]);
	let [user, setUser] = useState({
		id: getUser()?.id,
		name: getUser()?.name
	});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getTreasuries = async () => {
			// Get selected user data 
			let selectedUser = null;
			if ( user?.id ) {
				selectedUser = await axios.get('/api/manager/users/get/' + user.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					return response.data;
				}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}

			if ( selectedUser === undefined ) return; // Prevent first render with undefined

			// Get selected communities
			let selectedUserCommunities = jsonToArray(selectedUser?.communities_permissions).map(el => el.value.responsible ? el.key : null).filter(el => el !== null);
			if ( selectedUser?.id && selectedUserCommunities.length <= 0 ) selectedUserCommunities = [-1];
			if ( !selectedUser?.id ) selectedUserCommunities = null;

			// Get treasuries
			await axios.get('/api/manager/treasury/list', {
				params: {
					no_paginate: true,
					communities_id: selectedUserCommunities,
					type: 'bankaccount',
					with_pending_noreconciliatedbankmovements_count: true,
					with_last_reconciliation_log: true,
					with_community: true,
					sort: 'communities.name',
					direction: 'asc',
					basic_info: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				// setTreasuries([...response.data.filter(el => el.noreconciliatedbankmovements_count > 0)]);
				setTreasuries([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getTreasuries();
	}, [user]);

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: 'Comunidades de ' + el.name
				};
			});

			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const goBankReconciliation = (e, treasury) => {
		e.preventDefault();
		setCommunityGlobal(treasury.community, false, true, e.currentTarget.href);
	}

	return (
		<div className="card border-0 shadow-sm">
			<div className="card-header bg-white p-3">
				<div className="row">
					<div className="col-md-6 mb-2 mt-2 mb-md-0 mt-md-0">
						<h5 className="card-title mb-0 text-primary d-flex h-100 align-items-center justify-content-center">Estado de cuentas bancarias</h5>
					</div>
					<div className="col-md-6 mb-2 mt-2 mb-md-0 mt-md-0">
						<EmpoweredSelector
							load={loadUsers}
							onChange={value => setUser(value)}
							timeout={250}
							label={user?.name ? 'Comunidades de ' + user?.name : 'Todas las comunidades'}
							placeholder="Todas las comunidades"
							value={user?.id}
						/>
					</div>
				</div>
			</div>
			<div className="card-body">
				{ treasuries.length > 0 &&
					<div className="row">
						<div className="col-md-12">
							<Table className="table table-sm table-bordered mb-0">
								<thead className="border-top-0">
									<tr>
										<th className="fw-normal">Comunidad</th>
										<th className="fw-normal">Cuenta bancaria</th>
										<th className="fw-normal">Saldo real</th>
										<th className="fw-normal">Pendientes</th>
									</tr>
								</thead>
								<tbody>
									{ treasuries.map((el, idx) => {
										return (
											<tr key={idx}>
												<td>{el.community?.name}</td>
												<td>
													{el.name}
													{el.last_reconciliation_log &&
														<small className="text-muted d-block">
															Última conciliación el {moment(el.last_reconciliation_log.date).format('DD/MM/YYYY')}
															{el.last_reconciliation_log.user?.id && <>&nbsp;por&nbsp;{el.last_reconciliation_log.user.name}</>}
															{el.last_reconciliation_log.exists_later_movements && <>&nbsp;<i className="bi bi-circle-fill text-danger"></i></>}
														</small>
													}
												</td>
												<td>{formatNumber(el.real_balance ?? 0, 2)} €</td>
												<td>
													<NavLink to={'/bank-reconciliation/' + el.id + '/movements'} onClick={(e) => goBankReconciliation(e, el)} className="text-decoration-none">
														{el.noreconciliatedbankmovements_count}&nbsp;
														<i className="bi bi-arrow-left-right"></i>
													</NavLink>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>	
						</div>
					</div>
				}
				{ treasuries.length <= 0 && 'Todo en orden'}
			</div>
		</div>
	);
}


