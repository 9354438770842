import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopupLayout from 'layouts/PopupLayout';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getUser } from 'helpers/user';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import { CristalLoader } from 'helpers/generic';
import { openPopupWindow } from 'helpers/generic';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function OwnersForm(props) {
	const [queryParams] = useSearchParams();
	const params = useParams();

	let community = getCommunity();
	let [data, setData] = useState({
		community: {
			id: getCommunity()?.id,
			name: getCommunity()?.name
		},
		community_id: getCommunity()?.id,
		user: {
			id: getUser()?.id,
			name: getUser()?.name
		},
		user_id: getUser()?.id,
		date_from: queryParams.get('date_from') ?? moment().format('YYYY-MM-DDTHH:mm'),
		date_to: queryParams.get('date_to') ?? undefined ?? moment().add(1, 'hours').format('YYYY-MM-DDTHH:mm'),
		finished: false
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
		};
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/events/get/' + params.id, {
					params: {},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id, community?.id]);

	const deleteEvent = () => {
		const c = window.confirm('¿Quieres eliminar este evento?');
		if ( !c ) return;

		axios.delete('/api/manager/events/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Evento borrado')
	    	window.close();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = () => {
		setErrors({});

		axios.post('/api/manager/events/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			window.close();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setFinished = (status) => {
		setErrors({});

		axios.post('/api/manager/events/set-finished/' + data.id, {
			status: status
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			window.close();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	return (
		<PopupLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Evento</h1>
					<button onClick={() => window.close()} className="btn btn-sm btn-light ms-auto">Cerrar</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<div className="mb-2">
												<label>Comunidad</label>
												<EmpoweredSelector
													load={loadCommunities}
													onChange={
														(value) => setData({
															...data, 
															community: {
																id: value?.id,
																name: value?.name
															},
															community_id: value?.id
														})
													}
													timeout={250}
													label={data.community?.name ?? ''}
													placeholder="Seleccionar"
													value={data.community?.id}
												/>
												{ errors.community_id &&
													<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-2">
												<label>Usuario</label>
												<EmpoweredSelector
													load={loadUsers}
													onChange={
														(value) => setData({
															...data, 
															user: {
																id: value?.id,
																name: value?.name
															},
															user_id: value?.id
														})
													}
													timeout={250}
													label={data.user?.name ?? ''}
													placeholder="Seleccionar"
													value={data.user?.id}
												/>
												{ errors.user_id &&
													<div className="invalid-feedback d-block">{ errors.user_id[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-2">
												<label>Desde</label>
												<input type="datetime-local" className="form-control form-control-sm" value={data.date_from ? moment(data.date_from).format('YYYY-MM-DDTHH:mm') : ''} onChange={(e) => setDataField('date_from', e.target.value)} />
												{ errors.date_from &&
													<div className="invalid-feedback d-block">{ errors.date_from[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-2">
												<label>Hasta</label>
												<input type="datetime-local" className="form-control form-control-sm" value={data.date_to ? moment(data.date_to).format('YYYY-MM-DDTHH:mm') : ''} onChange={(e) => setDataField('date_to', e.target.value)} />
												{ errors.date_to &&
													<div className="invalid-feedback d-block">{ errors.date_to[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-2">
												<label>Nombre</label>
												<input type="text" className="form-control form-control-sm" value={data.name ?? ''} onChange={(e) => setDataField('name', e.target.value)} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-2">
												<label>Lugar</label>
												<input type="text" className="form-control form-control-sm" value={data.place ?? ''} onChange={(e) => setDataField('place', e.target.value)} />
												{ errors.place &&
													<div className="invalid-feedback d-block">{ errors.place[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-2">
												<label>Descripción</label>
												<textarea type="text" className="form-control form-control-sm" value={data.description ?? ''} onChange={(e) => setDataField('description', e.target.value)} rows="5" />
												{ errors.description &&
													<div className="invalid-feedback d-block">{ errors.place[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-12">
											{ data.action && 
												(() => {
													let action = null;
													if ( data.action.type === 'open-notice' ) {
														action = 
															<div className="col-md-12 mt-2 mb-2 text-primary sbold">
																<label>Evento relacionado con aviso</label>&nbsp;
																<NavLink 
																	className="btn btn-sm btn-primary text-white" 
																	to={'/notices/edit/' + data.action.id} 
																	onClick={openPopupInfoWindow}
																>
																	Abrir aviso
																</NavLink>
															</div>
														;
													}
													if ( data.action.type === 'open-contract' ) {
														action = 
															<div className="col-md-12 mt-2 mb-2 text-primary sbold">
																<label>Evento relacionado con contrato</label>&nbsp;
																<NavLink 
																	className="btn btn-sm btn-primary text-white" 
																	to={'/contracts/edit/' + data.action.id + '?popup=true'} 
																	onClick={openPopupInfoWindow}
																>
																	Abrir contrato
																</NavLink>
															</div>
														;
													}
													if ( data.action.type === 'open-meeting' ) {
														action = 
															<div className="col-md-12 mt-2 mb-2 text-primary sbold">
																<label>Evento relacionado con junta</label>&nbsp;
																<NavLink 
																	className="btn btn-sm btn-primary text-white" 
																	to={'/meetings/edit/' + data.action.id + '?popup=true'} 
																	onClick={openPopupInfoWindow}
																>
																	Abrir junta
																</NavLink>
															</div>
														;
													}
													return action;
												})()
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id="footer-fixed">
									<div className="row">
										<div className="col-6">
											{ (data.id && (!data.community?.id || (data.community && authUserPermission('delete', data.community)))) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteEvent()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((!data.community?.id || authUserPermission('edit', data.community)) && data.id) &&
												<button className={'btn btn-sm btn-'+(data.finished ? 'success' : 'warning')+' d-inline ms-3'} onClick={() => setFinished(!data.finished)}>Marcar como {data.finished ? 'no finalizado' : 'finalizado'}</button>
											}
											{ (((!data.community?.id || authUserPermission('add', data.community)) && !data.id) || ((!data.community?.id || authUserPermission('edit', data.community)) && data.id)) &&					
												<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar</button>							
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}