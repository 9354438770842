import React from 'react';
import styled from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { useEffect } from 'react';

const MapPanelStyled = styled.div`
    
    .leaflet-container {
        height: 150px;
        z-index: 1;
        cursor: pointer;

        .crosshair-cursor-enabled {
        }
    }
`;

export default function MapPanel(props) {
    const markerIcon = L.icon({
        iconUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconSize: [32, 50],
        iconAnchor: [32, 50]
    });

	return (
		<React.Fragment>
			<MapPanelStyled>
                <MapContainer center={props.position ?? [43.3617493, -8.412512]} zoom={17} attributionControl={false}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attributionControl={false}
                    />
                    <LocationMarker icon={markerIcon} position={props.position} setPosition={props.setPosition} />
                </MapContainer>
			</MapPanelStyled>	
		</React.Fragment>	
	);
}

function LocationMarker(props) {
    const map = useMap();

    useMapEvents({
        click(e) {
            props.setPosition([e.latlng.lat, e.latlng.lng]);
        }
    });

    useEffect(() => {
        if ( props.position ) {
            map.setView(props.position, map.getZoom());
        }
    }, [props.position, map]);
  
    return props.position ? <Marker icon={props.icon} position={props.position} /> : null;
  }