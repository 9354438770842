import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Navigate, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import { jsonToArray } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { getConfig } from 'helpers/config';
import { authUserPermission } from 'helpers/community';
import CustomFieldsPanel from 'components/CustomFieldsPanel';
import DocumentsPanel from 'components/DocumentsPanel';
import OwnersRentersAddressesPanel from 'components/AddressesPanel';
import Properties from './Properties';
import Notes from 'components/Notes';
import BankAccountsPanel from 'components/BankAccountsPanel';
import ButtonExportFichaPdf from 'components/ButtonExportFicha';
import OwnersRentersQuotesList from 'components/OwnersRentersQuotesList';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function RentersForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const forceCanEdit = queryParams.get('edit') === 'true';

	let community = getCommunity();
	let [data, setData] = useState({
		code: '',
		preferred_contact_method: null,
		rgpd_consent: 0,
		phone: [],
		email: [],
		addresses: [],
		bankaccounts: []
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/renters/get/' + params.id, {
					params: {
						community_id: community?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
					if ( !forceCanEdit ) setCanEdit(false);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getNextCode = async () => {
				await axios.get('/api/manager/renters/get-next-code', {
					params: {
						community_id: community?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData((prev) => ({...prev, code: response.data}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getNextCode();
		}
	}, [params.id, community?.id, forceCanEdit]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data, 'renter');
		};
	}, [data]);

	const deleteRenter = () => {
		const c = window.confirm('¿Quieres eliminar este inquilino?');
		if ( !c ) return;

		axios.post('/api/manager/renters/delete/' + data.id, {
			community_id: community?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Inquilino borrado')
	    	navigate('/renters');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = community?.id;

		axios.post('/api/manager/renters/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.renter});
			if ( goBack ) {
				if ( popup ) {
					data = response.data.renter; // Fix to send last data on before unload
					window.close();
				}
				else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/renters/edit/' + response.data.renter.id + '?edit=true' + (popup ? '&popup=true' : ''));
			}

	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	if ( !community?.id ) return <Navigate to="/" />;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de inquilino</h1>
					<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-7">
										<LabeledFrame 
											className="mb-3" 
											label="Datos básicos"
											buttons={
												<NavLink to={'/communities/edit/' + community?.id + '?popup=true'} className="btn btn-unstyled text-primary" onClick={openPopupInfoWindow}>Comunidad <i className="bi bi-eye-fill"></i></NavLink>
											}
										>
												<div className="row">
													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput 
																label="Código" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('code', e.target.value)} 
																value={data.code ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.code &&
																<div className="invalid-feedback d-block">{ errors.code[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-230">
														<div className="mb-2">
															<CustomInput 
																label="Nombre" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('name', e.target.value)} 
																value={data.name ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.name &&
																<div className="invalid-feedback d-block">{ errors.name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="DNI" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('vatnumber', e.target.value)} 
																value={data.vatnumber ?? ''} 
																readOnly={!canEdit}
															/>
															{ errors.vatnumber &&
																<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-80">
														<div className="mb-2">
															<CustomSelect 
																label="LOPD" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('rgpd_consent', e.target.value)} 
																value={data.rgpd_consent ?? ''}
																disabled={!canEdit}
															>
																<option value="0">No</option>
																<option value="1">Si</option>
															</CustomSelect>
															{ errors.rgpd_consent &&
																<div className="invalid-feedback d-block">{ errors.rgpd_consent[0] }</div>
															}
														</div>
													</div>
												</div>											
											</LabeledFrame>

											<Properties
												properties={data.properties}
												errors={errors}
												setProperties={(properties) => setData((data) => ({...data, properties: properties}))}
												readOnly={!canEdit}
											/>

											<OwnersRentersAddressesPanel
												addresses={data.addresses}
												properties={data.properties}
												errors={errors}
												setAddresses={(addresses) => setData({...data, addresses: addresses})}
												readOnly={!canEdit}
											/>

											<LabeledFrame className="mt-3 mb-3" label="Contacto">
												<div className="col-lg-4">
													<div className="mb-2">
														<CustomSelect 
															label="Contacto preferente" 
															className="form-control form-control-sm" 
															onChange={(e) => setDataField('preferred_contact_method', e.target.value)} 
															value={data.preferred_contact_method ?? ''}
															disabled={!canEdit}
														>
															<option value=""></option>
															{jsonToArray(getConfig().contactmethods).map((el, idx) => <option key={idx} value={el.key}>{el.value}</option>)}
														</CustomSelect>
														{ errors.preferred_contact_method &&
															<div className="invalid-feedback d-block">{ errors.preferred_contact_method[0] }</div>
														}
													</div>
												</div>
											</LabeledFrame>

											<BankAccountsPanel
												bankaccounts={data.bankaccounts}
												setBankaccounts={(baccounts) => setData({...data, bankaccounts: baccounts})}
												errors={errors}
												readOnly={!canEdit}
											/>
										</div>
										<div className="col-lg-5">											
											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea 
													className="form-control form-control-sm" 
													onChange={(e) => setDataField('observations', e.target.value)} 
													value={data.observations ?? ''}
													readOnly={!canEdit}
												/>
											</LabeledFrame>

											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
												readOnly={!canEdit}
											/>

											<CustomFieldsPanel 
												className="mb-3"
												type="renters"
												fields={data.customfields_array}
												setFields={(fields) => setData((data) => ({...data, customfields_array: fields}))}
												readOnly={!canEdit}
											/>

											{ data.id && 
												<React.Fragment>
													<OwnersRentersQuotesList
														type="renters"
														id={data.id}
													/>

													<DocumentsPanel
														className="mb-3"
														folderId={data.folder_id ?? null}
														community={getCommunity()}
														recipientsType='renters'
														recipientsIds={[data.id]}
													/>
												</React.Fragment>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete')) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteRenter()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add') && !data.id) || (authUserPermission('edit') && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
																<li><hr className="dropdown-divider" /></li>

																<li><NavLink className="dropdown-item" to={'/notices?petitioner_type=renter&petitioner_id=' + data.id} onClick={openPopupInfoWindow}>Ver avisos</NavLink></li>
																<li><NavLink className="dropdown-item" to={'/notices/add?community_id=' + data.community_id + '&petitioner_type=renter&renter_id=' + data.id} onClick={openPopupInfoWindow}>Nuevo aviso</NavLink></li>
																<li><ButtonExportFichaPdf entityKey='renters' entityId={data.id} fileName={'Inquilino ' + data.vatnumber} /></li>

																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}