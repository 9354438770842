import create from 'zustand';
import { isTouchEnabled } from 'helpers/generic';

// Create reducer
const reducer = (state, action) => {
	if ( action.type === "setConfig" ) {
		localStorage.setItem('config', JSON.stringify(action.data));
		return {config: action.data};
	}

	if ( action.type === "setUser" ) {
		let data = {
			user: action.data,
			forceReload: action.forceReload
		};
		localStorage.setItem('user', JSON.stringify(action.data));

		// Set empty if logout
		if ( !action.data ) {
			data.community = null;
			data.config = null;
			localStorage.setItem('config', null);
			localStorage.setItem('community', null);
		}

		return data;
	}

	if ( action.type === "setSidebarVisible" ) {
		localStorage.setItem('sidebar_visible', action.status);
		return {sidebarVisible: action.status};
	}

	if ( action.type === "setCommunity" ) {
		localStorage.setItem('community', JSON.stringify(action.data));
		return {
			community: action.data,
			forceReload: action.forceReload
		};
	}

	if ( action.type === "setStrictMode" ) {
		return {
			strictMode: action.data
		};
	}

	// Not valid type
	throw new Error();
}

// Default state
const defaultState = {
	strictMode: true,
	forceReload: null,
	debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
	config: (() => {
		let config = '';
		try {
			config = JSON.parse( localStorage.getItem('config') )
		} catch (err) {}
		return config;
	})(),
	user: (() => {
		let user = '';
		try {
			user = JSON.parse( localStorage.getItem('user') )
		} catch (err) {}
		return user;
	})(),
	sidebarVisible: (() => {
		let status = true;
		let from_local_storage = localStorage.getItem('sidebar_visible');
		if ( from_local_storage !== null ) {
			status = localStorage.getItem('sidebar_visible') === 'true' && !isTouchEnabled() ? true : false;
		}
		return status;
	})(),
	community: (() => {
		let community = '';
		try {
			community = JSON.parse( localStorage.getItem('community') )
		} catch (err) {}
		return community;
	})(),
};

// Create hook
const useStore = create(set => ({
	...defaultState,
	dispatch: (action) => set(state => reducer(state, action)),
}));

export default useStore;