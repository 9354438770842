import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import DocumentsPanel from 'components/DocumentsPanel';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader } from 'helpers/generic';
import { getRouterBasename } from 'helpers/config';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { openPopupWindow } from 'helpers/generic';
import Items from './Items';
import CounterParts from './CounterParts';
import Payments from './Payments';
import DocumentViewer from './DocumentViewer';
import DraftFileViewButton from './DraftFileViewButton';
import { downloadFile } from 'helpers/generic';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';

const File = styled.div`
	position: relative;
	height: 30px;
	display: flex;
	align-items: center;

	button {
		position: absolute;
		top: -10px;
		right: -5px;
		padding: 0;
		margin: 0;
		color: var(--bs-red);
		border: 0;
	}
`;

let axiosCancelToken = null;

// Get proxy callback from parent opener to make updates if possible
let parentWindowProxyCallback = window.opener?.PopupProxyCallback;
let parentWindowProxyCallbackProviderInvoices = window.opener?.PopupProxyCallbackProviderInvoices;

export default function ProvidersInvoiceForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const fileRef = useRef(null);

	const popup = queryParams.get('popup') === 'true';
	const pageTitle = queryParams.get('page_title') ?? 'Facturas recibidas';
	const noticeId = queryParams.get('notice_id') ?? null;
	const providerId = queryParams.get('provider_id') ?? null;
	const communityId = queryParams.get('community_id') ?? null;
	const pendinginvoiceId = queryParams.get('pendinginvoice_id') ?? null;
	const dateReception = queryParams.get('date_reception') ?? null;
	const amount = queryParams.get('amount') ?? null;
	const concept = queryParams.get('concept') ?? null;
	const forceCanEdit = queryParams.get('edit') === 'true';
	const draft = queryParams.get('draft') === 'true';
	
	let fixedTotal = queryParams.get('fixed_total');
	if ( fixedTotal !== null ) fixedTotal = parseFloat(fixedTotal);

	let [data, setData] = useState({
		// date_reception: moment().format('YYYY-MM-DD'),
		community: {
			id: getCommunity()?.id,
			name: getCommunity()?.name
		},
		community_id: getCommunity()?.id,
		items: [],
		base: 0,
		total: 0,
		file: null
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);
	let [reloadFolder, setReloadFolder] = useState(0);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data);
			parentWindowProxyCallbackProviderInvoices(data);
		};
	}, [data]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = (data) => {
			selectProvider(data);
		}
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/providers-invoices/get/' + params.id, {
					params: {
						draft: draft
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
					if ( !forceCanEdit ) setCanEdit(false);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getDataFromQueryString = async () => {
				// Notice ID from query string
				let newData = {};

				if ( noticeId ) {
					await axios.get('/api/manager/notices/get/' + noticeId, {
						params: {},
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						newData.notice = {
							id: response.data.id, 
							reference: response.data.reference
						};
						newData.notice_id = response.data.id;
				  	}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});
			  	}

				// Provider ID from query string
				if ( providerId ) {
					await axios.get('/api/manager/providers/get/' + providerId, {
						params: {},
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						newData.provider = {
							id: response.data.id, 
							business_name: response.data.business_name
						};
						newData.provider_id = response.data.id;
				  	}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});
			  	}

				// Community ID from query string
				if ( communityId ) {
					await axios.get('/api/manager/communities/get/' + communityId, {
						params: {},
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						newData.community = {
							id: response.data.id, 
							name: response.data.name
						};
						newData.community_id = response.data.id;
				  	}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});
			  	}

				// Date reception
				if ( dateReception ) {
					newData.date_reception = dateReception;
				}

			  	setData((prev) => ({...prev, ...newData}));
			}
			getDataFromQueryString();
		}
	}, [params.id, communityId, noticeId, providerId, dateReception, forceCanEdit, draft]);

	const deleteInvoice = () => {
		const c = window.confirm('¿Quieres eliminar esta factura?');
		if ( !c ) return;

		axios.delete('/api/manager/providers-invoices/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.info('Factura borrada');
			
			if ( popup ) window.close();
			else navigate('/providers-invoices');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		setLoading(true);

		let formData = new FormData();
		for(let i in data) {
			if ( data[i] === undefined || data[i] === null ) continue;
			formData.append(i, data[i]);
		}
		formData.set('items', JSON.stringify(data.items));
		formData.set('counterparts', JSON.stringify(data.counterparts));
		if ( pendinginvoiceId ) formData.set('pendinginvoice_id', pendinginvoiceId);

		axios.post('/api/manager/providers-invoices/save' + (data.id ? '/' + data.id : ''), formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: axiosCancelToken.token,
		}).then((response) => {
			setReloadFolder(Math.random());
			setData({...response.data.invoice});
			toast.success('Datos guardados');
			if ( goBack ) {
				if ( popup ) {
					data = response.data.invoice; // Fix: prevent send incompleted data when popup is closed. Not a good way, but it solves the problem.
					window.close();
				} else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/providers-invoices/edit/' + response.data.invoice.id + '?edit=true' +  (popup ? '&popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		}).then(() => {
			setLoading(false);
		});
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadCategories = (input, callback) => {
		axios.get('/api/manager/expenses-categories/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCategory = (category) => {
		setData({
			...data, 
			category: {...category},
			expensescategory_id: category?.id
		});
	}

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (community) => {
		setData({
			...data, 
			community: {...community},
			community_id: community?.id
		});
	}

	const loadProviders = (input, callback, args) => {
		axios.get('/api/manager/providers/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args?.community_id ?? null
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.business_name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProvider = (provider) => {
		setData((prev) => ({
			...prev, 
			provider: {...provider},
			provider_id: provider?.id
		}));
	}

	const loadPaymentMethods = (input, callback) => {
		axios.get('/api/manager/payment-methods/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectPaymentMethod = (paymentmethod) => {
		setData({
			...data, 
			paymentmethod: {...paymentmethod},
			paymentmethod_id: paymentmethod?.id
		});
	}

	const loadNotices = (input, callback, args) => {
		axios.get('/api/manager/notices/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: args.community?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: <div>
								<div>{el.reference}</div>
								<small className="text-secondary">{el.description}</small>
						   </div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectNotice = (notice) => {
		setData({
			...data, 
			notice: {...notice},
			notice_id: notice?.id
		});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}
 
	const onDropHandler = (e) => {
		e.preventDefault();

		// Check that drop is not inside of .documents-panel
		if ( e.target.closest('.documents-panel') ) return;

		let file = e.dataTransfer.files[0];
		setData({...data, file: file});
	}

	const openFileInput = () => {
		if ( !canEdit ) return;
		
		setData({...data, file: null});
		fileRef.current.value = null;
		fileRef.current.click();
	}

	const fileInputChange = (e) => {
		let file = e.target.files[0];
		setData({...data, file: file});
	}

	const removeFile = (e) => {
		e.stopPropagation();
		setData({...data, file: null});
	}

	const viewFile = (e, file) => {
		e.preventDefault();

		const baseUrl = getRouterBasename() + '/documents/view-file/';
		if ( file.viewable ) return openPopupWindow(baseUrl + file.id);

		return downloadFile('/api/manager/documents/download-file/' + file.id);
	}

	const isHiddenSection = (section) => {
		let hiddenSections = queryParams.get('hidden_sections');
		if ( !hiddenSections ) return false;

		hiddenSections = hiddenSections.split(',');
		return hiddenSections.includes(section);
	}

	const viewDraftFile = () => {
		const baseUrl = getRouterBasename() + '/providers-invoices/view-draft-file/' + data.id;
		// console.log(baseUrl);	
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading === true && <CristalLoader /> }

			<DocumentViewer 
				file={data.file}
			/>

			<section
				onDrop={onDropHandler}
				onDragOver={(e) => e.preventDefault()}
			>
				<div className="page-title" data-docviewheaderfix="esto es necesario para ajustar la altura del document viewer">
					<h1>{pageTitle}</h1>
					<button onClick={() => popup ? window.close() : navigate('/providers-invoices')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<LabeledFrame label="Datos básicos" className="mb-3 mb-md-0">
												<div className="row">
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																readOnly={!canEdit} 
																label="Nº documento" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('number', e.target.value)} 
																value={data.number ?? ''} 
															/>
															{ errors.number &&
																<div className="invalid-feedback d-block">{ errors.number[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																label="Fecha factura" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('date', e.target.value)} 
																value={data.date ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.date &&
																<div className="invalid-feedback d-block">{ errors.date[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																label="Fecha contable" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('date_reception', e.target.value)} 
																value={data.date_reception ?? ''} 
																// readOnly={!canEdit} 
																readOnly={true} 
															/>
															{ errors.date_reception &&
																<div className="invalid-feedback d-block">{ errors.date_reception[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-165">
														<div className="mb-2">
															<div className="input-group">
																<EmpoweredSelector
																	load={(input, callback) => loadPaymentMethods(input, callback)}
																	onChange={(value) => selectPaymentMethod(value)}
																	timeout={250}
																	label={data.paymentmethod?.name ?? ''}
																	placeholder="Forma de pago"
																	showPlaceholderHelper={true}
																	value={data.paymentmethod?.id}
																	disabled={!canEdit} 
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-light2 btn-sm text-secondary" to={'/payment-methods/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																</div>
															</div>
															{ errors.paymentmethod_id &&
																<div className="invalid-feedback d-block">{ errors.paymentmethod_id[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-250">
														<div className="mb-2">
															<div className="input-group">
																<EmpoweredSelector
																	load={(input, callback, args) => loadProviders(input, callback, args)}
																	// args={{community_id: data.community_id}}
																	onChange={(value) => selectProvider(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.provider?.business_name ?? ''}&nbsp;
																			{data.provider?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/providers/edit/' + data.provider.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Proveedor"
																	showPlaceholderHelper={true}
																	value={data.provider?.id}
																	disabled={!canEdit} 
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-light2 btn-sm text-secondary" to={'/providers/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																</div>
															</div>
															{ errors.provider_id &&
																<div className="invalid-feedback d-block">{ errors.provider_id[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																label="Pago manual" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('manual_paid_at', e.target.value)} 
																value={data.manual_paid_at ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.manual_paid_at &&
																<div className="invalid-feedback d-block">{ errors.manual_paid_at[0] }</div>
															}
														</div>
													</div>	
												</div>	
											</LabeledFrame>
										</div>
										<div className="col-md-6">
											<div className="row">
												<LabeledFrame label="Otros datos">
													<div className="row">
														<div className="col col-static-150">
															<div className="mb-3">
																<div className="input-group">
																	<EmpoweredSelector
																		load={(input, callback) => loadCategories(input, callback)}
																		onChange={(value) => selectCategory(value)}
																		timeout={250}
																		label={
																			<div>
																				{data.category?.name ?? ''}&nbsp;
																			</div>
																		}
																		placeholder="Categoría"
																		showPlaceholderHelper={true}
																		value={data.category?.id}
																		disabled={!canEdit} 
																	/>
																	<div className="input-group-append">
																		<NavLink className="btn btn-light2 btn-sm text-secondary" to={'/expenses-categories/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																	</div>
																</div>
																{ errors.expensescategory_id &&
																	<div className="invalid-feedback d-block">{ errors.expensescategory_id[0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-250">
															<div className="mb-3">
																<EmpoweredSelector
																	load={loadCommunities}
																	onChange={(value) => selectCommunity(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.community?.name ?? ''}&nbsp;
																			{data.community?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/communities/edit/' + data.community.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Comunidad"
																	showPlaceholderHelper={true}
																	value={data.community?.id}
																	disabled={!canEdit} 
																/>
																{ errors.community_id &&
																	<div className="invalid-feedback d-block">{ errors.community_id[0] }</div>
																}
															</div>
														</div>		
														
														<div className="col col-static-180">
															<div className="mb-3">
																<CustomInput 
																	label="Descripción" 
																	type="text" 
																	className="form-control form-control-sm" 
																	onChange={(e) => setDataField('description', e.target.value)} 
																	value={data.description ?? ''} 
																	readOnly={!canEdit} 
																/>
																{ errors.description &&
																	<div className="invalid-feedback d-block">{ errors.description[0] }</div>
																}
															</div>
														</div>

														<div className="col col-static-250">
															<div className="mb-2">
																<EmpoweredSelector
																	load={(input, callback, args) => loadNotices(input, callback, args)}
																	args={{community: data.community}}
																	onChange={(value) => selectNotice(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.notice?.reference ?? ''}&nbsp;
																			{data.notice?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/notices/edit/' + data.notice.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Incidencia"
																	showPlaceholderHelper={true}
																	value={data.notice?.id}
																	disabled={!canEdit} 
																/>
																{ errors.notice_id &&
																	<div className="invalid-feedback d-block">{ errors.notice_id[0] }</div>
																}
															</div>
														</div>	

														<div className="col col-static-180">
															{ draft === false &&
																<div className="mb-2">
																	<File className="form-control form-control-sm" style={{cursor: 'pointer'}} onClick={(e) => !data.file?.id ? openFileInput(e) : viewFile(e, data.file)}>
																		{ data.file?.id && 
																			<React.Fragment>
																				<button disabled={!canEdit} className="btn btn-sm p-0" onClick={removeFile}><i className="bi bi-x-circle-fill"></i></button>
																				<i className="bi bi-file-fill text-primary me-1"></i>
																			</React.Fragment>
																		}
																		{ data.file ? data.file.name : 'Seleccionar archivo' }
																	</File>
																	{ errors.file &&
																		<div className="invalid-feedback d-block">{ errors.file[0] }</div>
																	}
																	<input type="file" ref={fileRef} className="d-none" onChange={fileInputChange}  />
																</div>
															}

															{ draft === true &&
																<div className="mb-2">
																	<DraftFileViewButton invoice={data} />
																</div>
															}
														</div>
													</div>	
												</LabeledFrame>
											</div>
										</div>	
										<div className="col-md-12 mt-3">
											<Items
												data={data}
												setData={setData}
												errors={errors}

												firstItemTotal={amount}
												firstItemConcept={concept}

												readOnly={!canEdit} 
											/>
										</div>
										<div className="col-md-6 mt-3">
											{ !isHiddenSection('counterparts') &&
												<CounterParts
													data={data}
													setData={setData}
													errors={errors}
													saveData={saveData}
													readOnly={!canEdit} 
												/>
											}
										</div>
										<div className="col-md-6 mt-3">
											{ !isHiddenSection('counterparts') &&
												<Payments
													data={data}
												/>
											}
										</div>
											{ data.folder_id && 
												<div className="col-md-6 mt-3">
													<DocumentsPanel
														className="mb-3"
														folderId={data.folder_id ?? null}
														community={getCommunity()}
														reloadFolder={reloadFolder}
													/>
												</div>
											}
									</div>
								</div>
								<div className="card-footer" data-docviewfooterfix="esto es necesario para ajustar la altura del document viewer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete', data.community) && !isHiddenSection('delete_button')) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteInvoice()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add', data.community) && !data.id) || (authUserPermission('edit', data.community) && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>

																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}

													{ !isHiddenSection('save_button') &&
														<button 
															className="btn btn-sm btn-primary text-white d-inline ms-3" 
															onClick={() => saveData(false)} 
															disabled={fixedTotal !== null && fixedTotal !== data.total}
														>Guardar</button>						
													}
													<button 
														className="btn btn-sm btn-primary text-white d-inline ms-3" 
														onClick={() => saveData()} 
														disabled={fixedTotal !== null && fixedTotal !== data.total}
													>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}