import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, jsonToArray } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { getCommunity } from 'helpers/community';
import DocumentsPanel from 'components/DocumentsPanel';
import Basics from './Basics';
import Petitioner from './Petitioner';
import Actions from './Actions';
import Tasks from './Tasks';
import Intervener from './Intervener';
import Contracts from './Contracts';
import Responsibles from './Responsibles';
import ImportantOwners from 'components/ImportantOwners';
import ButtonExportFichaPdf from 'components/ButtonExportFicha';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;
let parentWindowProxyCallbackSecondary = window.opener?.PopupProxyCallbackSecondary;

export default function NoticesForm(props) {
	const [queryParams] = useSearchParams();
	const closeable = queryParams.get('closeable') === 'true';

	const community_id = queryParams.get('community_id');
	const property_id = queryParams.get('property_id');
	const owner_id = queryParams.get('owner_id');
	const renter_id = queryParams.get('renter_id');
	const provider_id = queryParams.get('provider_id');
	const client_id = queryParams.get('client_id');
	const contact_id = queryParams.get('contact_id');
	const copy_from = queryParams.get('copy_from');

	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		opened_at: moment().format('YYYY-MM-DDTHH:mm'),
		closed_at: null,
		expires_at: null,
		init_at: moment().format('YYYY-MM-DDTHH:mm'),
		priority: 3,
		status: 'pending',
		character: 'community',
		petitioner_type: queryParams.get('petitioner_type'),
		external_petitioner: {
			name: '',
			phone: '',
			email: ''
		},
		opener: {
			id: getUser()?.id,
			name: getUser()?.name
		},
		type: {
			id: null,
			name: null,
		},
		source: {
			id: null,
			name: null,
		},
		user: {
			id: getUser()?.id,
			name: getUser()?.name,
		},
		community: {
			// id: getCommunity()?.id,
			// name: getCommunity()?.name
			id: null,
			name: null
		},
		building: {
			id: null,
			name: null
		},
		property: {
			id: null,
			name: null
		},
		owner: {
			id: null,
			name: null
		},
		renter: {
			id: null,
			name: null
		},
		provider: {
			id: null,
			business_name: null
		},
		interveners: [],
		community_id: null,
		user_id: getUser()?.id,
		pendinginvoices: [],
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback();
			parentWindowProxyCallbackSecondary();
		};
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/notices/get/' + params.id, {
					params: {},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData((prev) => ({
			    		...prev, 
			    		...response.data,
			    		interveners: response.data.interveners.length > 0 ? [...response.data.interveners] : [], // Needed to show empty intervener
						character: response.data.character ?? 'community', // Fix for adcomunidad copied notices, they dont have character
			    	}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				await axios.post('/api/manager/notices/set-notified/' + params.id, {}, {
					cancelToken: axiosCancelToken.token
				});

				setLoading(false);
			}
			getData();
		} else {
			if ( copy_from ) {
				let copyFrom = async () => {
					setLoading(true);

					await axios.get('/api/manager/notices/get/' + copy_from, {
						params: {},
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						let toCopy = {
							...response.data,
							providerinvoices: [],
							interveners: response.data.interveners.length > 0 ? [...response.data.interveners] : [], // Needed to show empty intervener
							character: response.data.character ?? 'community', // Fix for adcomunidad copied notices, they dont have character
						};
						delete toCopy.id;
						delete toCopy.reference;
						delete toCopy.init_at;
						delete toCopy.opened_at;

						setData((prev) => ({
							...prev, 
							...toCopy
						}));
					}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});

					setLoading(false);
				}
				copyFrom();
			}
		}

		// Get community
		if ( community_id ) {
			axios.get('/api/manager/communities/get/' + community_id, {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData((prev) => ({...prev, community: {id: response.data.id, name: response.data.name}, community_id: response.data.id}))
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		// Get property
		if ( property_id ) {
			axios.get('/api/manager/properties/get/' + property_id, {
				params: {
					community_id: community_id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData((prev) => ({
		    			...prev, 
		    			property: {id: response.data.id, name: response.data.name}, 
		    			property_id: response.data.id,
		    			building: {id: response.data.building?.id, name: response.data.building?.name},
		    			building_id: response.data.building?.id
		    		})
		    	)
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		// Get owner
		if ( owner_id ) {
			axios.get('/api/manager/owners/get/' + owner_id, {
				params: {
					community_id: community_id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData((prev) => ({...prev, owner: {id: response.data.id, name: response.data.name}, owner_id: response.data.id}))
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		// Get renter
		if ( renter_id ) {
			axios.get('/api/manager/renters/get/' + renter_id, {
				params: {
					community_id: community_id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData((prev) => ({...prev, renter: {id: response.data.id, name: response.data.name}, renter_id: response.data.id}))
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		// Get provider
		if ( provider_id ) {
			axios.get('/api/manager/providers/get/' + provider_id, {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData((prev) => ({...prev, provider: {id: response.data.id, name: response.data.business_name}, provider_id: response.data.id}))
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		// Get client
		if ( client_id ) {
			axios.get('/api/manager/clients/get/' + client_id, {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData((prev) => ({...prev, client: {id: response.data.id, name: response.data.name}, client_id: response.data.id}))
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		// Get contact
		if ( contact_id ) {
			axios.get('/api/manager/contacts/get/' + contact_id, {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData((prev) => ({...prev, contact: {id: response.data.id, name: response.data.name}, contact_id: response.data.id}))
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		// Get default type
		axios.get('/api/manager/notices-types/list', {
			params: {
				no_paginate: true,
				default: 1
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	if ( response.data[0] ) setData((prev) => ({...prev, notice_type_id: response.data[0].id, type: {id: response.data[0].id, name: response.data[0].name}}))
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});

		// Get default source
		axios.get('/api/manager/notices-sources/list', {
			params: {
				no_paginate: true,
				default: 1
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	if ( response.data[0] ) setData((prev) => ({...prev, notice_source_id: response.data[0].id, source: {id: response.data[0].id, name: response.data[0].name}}))
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}, [params.id, community_id, property_id, owner_id, renter_id, provider_id, client_id, contact_id, copy_from]);

	useEffect(() => {
		// Only generate reference if new
		if ( data.id || params.id ) return;

		// If not type, remove reference
		if ( !data.notice_type_id ) {
			setData(data => ({...data, reference: null}));
			return;
		}

		const getReference = () => {
			axios.get('/api/manager/notices/get-new-reference', {
				params: {
					type_id: data.notice_type_id,
					community_id: data.community_id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData(data => ({...data, reference: response.data.reference}));
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getReference();
	}, [params.id, data.id, data.notice_type_id, data.community_id]);

	const deleteNotice = () => {
		const c = window.confirm('¿Quieres eliminar este aviso?');
		if ( !c ) return;

		axios.delete('/api/manager/notices/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Aviso borrado')
	    	if ( closeable ) window.close();
	    	else navigate('/notices');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (data, action = true, status) => {
		setErrors({});

		if ( status ) data.status = status;

		return axios.post('/api/manager/notices/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');

			setData({...response.data.notice});
			if ( action === 'back' ) {
				if ( closeable ) window.close();
				else navigate('/notices');
			} else {
		    	navigate('/notices/edit/' + response.data.notice.id);
			}

			return true;
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');

			return false;
		});
	}

	const copyToNew = () => {
		let newData = {...data};
		newData.id = null;
		newData.opened_at = moment().format('YYYY-MM-DDTHH:mm');
		newData.description = null;
		newData.observations = null;
		newData.tasks = [];
		newData.actions = [];
		newData.documents = [];
		newData.providerinvoices = [];
		newData.pendinginvoices = [];
		newData.interveners = [];
		newData.status = 'pending';
		newData.expires_at = null;

		setData({...newData});

    	navigate('/notices/add');
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const downloadPdf = () => {

	}

	// Autosave from childs if needed
	useEffect(() => {
		if (!data.autosave_fix) return;

		delete data.autosave_fix;
		saveData(data);
	}, [data]);

	return (
		<PopupLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de aviso</h1>
					<button onClick={() => closeable ? window.close() : navigate('/notices')} className="btn btn-sm btn-light ms-auto">{ closeable ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-7">
											<Basics
												data={data}
												setDataField={(field, newValue) => setDataField(field, newValue)}
												setData={(newState) => setData(newState)}
												errors={errors}
											/>
											<Petitioner
												data={data}
												setDataField={(field, newValue) => setDataField(field, newValue)}
												setData={(newState) => setData(newState)}
												errors={errors}
											/>

											<Tasks
												tasks={data.tasks}
												setTasks={(tasks) => setData((prev) => ({...prev, tasks: [...tasks]}))}
												errors={(() => {
													let tasksErrors = jsonToArray(errors).filter(el => el.key.indexOf('tasks.') !== -1);

													let errObj = {};
													tasksErrors.forEach(el => {
														let split = el.key.split('.');
														errObj[split[1]] = el.value[0];
													});

													return errObj;
												})()}
											/>

											{ data.community && 
												<Responsibles community_id={data.community.id} />
											}
											{ (data.community && data.community.importantowners && data.community.importantowners.length > 0) && 
												<ImportantOwners owners={data.community.importantowners} community_id={data.community.id} />
											}
											{ data.community && 
												<Contracts community_id={data.community.id} />
											}
										</div>
										<div className="col-md-5">
											<Intervener
												className="mb-3"

												data={data}
												setData={(newState) => setData(newState)}
												errors={errors}
											/>
											<Actions
												className="mb-3"
												data={data}
												setData={(newState) => setData(newState)}
												errors={errors}
											/>
											
											{ data.id ? 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													community={data.community}
												/>
												:
												<button className="btn btn-lg text-white btn-primary btn-outline" onClick={() => saveData(data)}><i className="bi bi-folder"></i> Subir documentos</button>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={'footer-fixed'} style={{zIndex: 3}}>
									<div className="row h-100 d-flex align-items-center">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteNotice()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<React.Fragment>
												{ data.id &&
													<ButtonExportFichaPdf 
														className="btn btn-sm btn-light" 
														loaderSize={[15, 15]} 
														entityKey='notices' 
														entityId={data.id} 
														fileName={'Aviso ' + data.reference + ' ' + moment(data.opened_at).format('DD-MM-YYYY')} 
														actionBeforeClick={async (continueCallback) => {
															let result = await saveData(data);
															if (!result) return;

															continueCallback();
														}}
													/>
												}
												
												<button className="btn btn-sm btn-orange text-white d-inline ms-2" onClick={() => saveData(data, 'back', 'progress')}>En curso</button>							
												<button className="btn btn-sm btn-primary text-white d-inline ms-2" onClick={() => saveData(data, 'back', 'pending')}>Pendiente</button>							
												<button className="btn btn-sm btn-success text-white d-inline ms-2" onClick={() => saveData(data, 'back', 'closed')}>Terminar</button>
												{/* { data.id &&
													<button className="btn btn-sm btn-warning d-inline ms-2" onClick={() => copyToNew()}>Crear nuevo</button>
												} */}
											</React.Fragment>						
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}