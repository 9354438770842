import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import LabeledFrame from 'components/LabeledFrame';
import { openPopupWindow, jsonToArray } from 'helpers/generic';
import { getConfig } from 'helpers/config';

const TableImportantOwners = styled.table`
	font-size: 12px;
	margin-bottom: 0;

	thead {
		tr {
			th {
				font-weight: 600;
				white-space: nowrap;

				&:first-of-type {
					width: 100px !important;
				}
			}
		}
	}

	tbody {
		border-top-color: var(--bs-gray-400) !important;

		tr {
			td {
				border-bottom-width: 0;

				&:first-of-type {
					width: 100px !important;
				}
			}
		}
	}
`;

export default function ImportantOwners(props) {

	const openOwnerWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}


	return (
		<LabeledFrame className="mb-3" label="Cargos importantes">
			<div className="table-responsive">
			<TableImportantOwners className="table table-sm">
				<thead>
					<tr>
						<th>Cargo</th>
						<th>Nombre</th>
						<th>Email</th>
						<th>Teléfono</th>
						<th>Método contacto</th>
					</tr>
				</thead>
				<tbody>
					{props.owners?.map((el, idx) => {
						return (
							<tr key={idx}>
								<td>{el.position.name}</td>
								<td>{el.name} <NavLink to={'/owners/edit/' + el.id + '?community_id='+(props.community_id ?? '')+'&popup=true'} onClick={(e) => openOwnerWindow(e)}><i className="bi bi-eye-fill"></i></NavLink></td>
								<td>{el.email_default ?? '-'} </td>
								<td>{el.phone_default ?? '-'}</td>
								<td>{ jsonToArray(getConfig().contactmethods).filter((elF) => elF.key === el.preferred_contact_method)[0]?.value ?? '-' }</td>
							</tr>
						);
					})}
				</tbody>
			</TableImportantOwners>
			</div>
		</LabeledFrame>
	);
}