import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import FieldSmallComment from 'components/FieldSmallComment';
import ImportExcelModal from './ImportExcelModal';

const TableProperties = styled.table`
	margin-bottom: 0;

	th {
		font-weight: 500;

		&:last-of-type {
			width: 50px;
			text-align: right;
		}
	}

	td {
		&:last-of-type {
			position: relative;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;	
				text-align: right;
				border: 0;
				outline: 0;
			}
		}
	}
`;

let axiosCancelToken = null;

let parentPopupProxyQuotasConceptsCallback = window.opener?.PopupProxyQuotasConceptsCallback;

export default function QuotasConceptsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		disabled_in_expenses: false,
		default: 0,
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [properties, setProperties] = useState([]);
	let [propertiesAmount, setPropertiesAmount] = useState({});
	let [showImportExcelModal, setShowImportExcelModal] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentPopupProxyQuotasConceptsCallback(data);
		};
	}, [data]);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/quotas-concepts/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);

					let properties = {};
					response.data.properties.forEach((el) => {
						properties[el.property_id] = el.amount;
					})
					setPropertiesAmount(properties);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				await axios.get('/api/manager/properties/list', {
					params: {
						community_id: getCommunity()?.id,
						no_paginate: true,
						order: 'number',
						direction: 'asc'
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setProperties(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteConcept = () => {
		const c = window.confirm('¿Quieres eliminar este concepto de cuota?');
		if ( !c ) return;

		axios.post('/api/manager/quotas-concepts/delete/' + data.id, {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Concepto de cuota borrado')
	    	navigate('/quotas-concepts');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;
		data.properties = propertiesAmount;

		axios.post('/api/manager/quotas-concepts/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');

			if ( goBack ) {
				if ( popup ) {
					data = response.data.concept; // Fix to send last data on before unload
					window.close();
				}
				else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/quotas-concepts/edit/' + response.data.concept.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setPropertyAmount = (property_id, amount) => {
		setPropertiesAmount((prev) => ({...prev, [property_id]: amount}));
	}

	const setPropertyAmountByNumber = (number, amount) => {
		let property = properties.filter(el => el.number === number)[0] ?? null;
		if ( !property?.id ) return;

		setPropertyAmount(property.id, amount);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de concepto de cuota</h1>
					<button onClick={() => popup ? window.close() : navigate('/quotas-concepts')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-3">
											<div className="mb-2">
												<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-2">
											<div className="mb-2">
												<CustomSelect label="Por defecto" className="form-control form-control-sm" onChange={(e) => setDataField('default', e.target.value)} value={data.default ?? ''}>
													<option value="0">No</option>
													<option value="1">Si</option>
												</CustomSelect>
												<FieldSmallComment className="mt-1">Si se marca por defecto aparecerá como origen preseleccionado en el formulario de presupuestos</FieldSmallComment>
												{ errors.default &&
													<div className="invalid-feedback d-block">{ errors.default[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-12">
											<div className="mb-2">
												<div className="d-flex justify-content-between align-items-end">
													<label>Cuotas preestablecidas</label>
													<button className="btn btn-sm btn-light mb-2" onClick={() => setShowImportExcelModal(true)}>
														IMPORTAR EXCEL
													</button> 
												</div>
												<div className="table-responsive">
													<TableProperties className="table table-sm table-bordered">
														<thead>
															<tr>
																<th>Propiedad / Pagador</th>
																<th>Cuota</th>
															</tr>
														</thead>
														<tbody>
															{properties?.length > 0 &&
																properties.map((el, idx) => {
																	return (
																		<tr key={idx}>
																			<td>({el.number}) {el.name}</td>
																			<td>
																				<input type="number" className="no-arrows" value={propertiesAmount[el.id] ?? ''} onChange={(e) => setPropertyAmount(el.id, e.target.value)} />
																			</td>
																		</tr>
																	);
																})
															}
															{properties?.length === 0 &&
																<tr>
																	<td colSpan="100%">No hay propiedades disponibles</td>
																</tr>
															}
														</tbody>
													</TableProperties>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-4">
											{ (data.id && data.is_deletable) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteConcept()}>Eliminar</button>							
											}
										</div>
										<div className="col-8 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{showImportExcelModal &&
				<ImportExcelModal 
					closeCallback={() => setShowImportExcelModal(false)}
					saveCallback={(data) => {
						if ( !data ) return;

						data.forEach((el) => {
							setPropertyAmountByNumber(el.number, el.amount);
						});

						setShowImportExcelModal(false);
					}}
				/>
			}
		</Layout>
	);
}