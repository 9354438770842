import useStore from "store";

export function hasCapability(capability) {
	let user = useStore.getState().user;
	return user && user.capabilities && Array.isArray(user.capabilities) && user.capabilities.includes(capability) ? true : false;
}

export const getUser = () => {
	return useStore.getState().user;
}
 
export function setUser(data, reload = false) {
	useStore.getState().dispatch({
		type: 'setUser',
		data: data,
		forceReload: reload ? Math.random() : null
	});
} 