export const assets = (asset) => {
	return process.env.PUBLIC_URL + asset;
}

export const isMobile = () => {
	if ( window.outerWidth <= 992 ) return true;
	return false;
}

export const isTouchEnabled = () => {
    return 'ontouchstart' in window;
}

export const loader = <img src={ assets('/img/loader.gif') } id="loader" alt="loader" />;

export const CristalLoader = () => {
	return (
		<div 
			className="cristal-loader"
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				background: 'transparent',
				zIndex: 9999,
				userSelect: 'none'
			}}
		>
			<img 
				src={ assets('/img/loader.svg') } 
				style={{
					width: '200px',
					userSelect: 'none',
					pointerEvents: 'none'
				}}
				alt="loader"
			/>
		</div>
	);
}

export const jsonToArray = (json) => {
	let array = [];
	for(let i in json) array.push({key: i, value: json[i]});
	return array;
}

export const formatNumber = (number, decimals = 0, force_decimals = true) => {
	if ( isNaN(number) ) number = 0;
	else number = parseFloat(number);

	const split = number.toString().split('.');
	if ( split[1] ) {
		let integer = parseInt(split[1]);
		if ( integer > 0 ) {
			if ( decimals ) {
				if ( force_decimals ) number = number.toFixed(decimals);
				else {
					let decimalsStr = split[1];
					decimalsStr.replaceAll('0', '');
					number = number.toFixed(decimalsStr.length > decimals ? decimals : decimalsStr.length);
				}
			}
		}
		else number = parseInt(number);
	} else {
		number = parseInt(number);
	}

	// Intl
	let options = {useGrouping: true};
	if ( force_decimals ) options.minimumFractionDigits = decimals;
	let intlNumber = new Intl.NumberFormat('es-ES', options).format(number);
	return intlNumber;
}

export const openPopupWindow = (href = null, name = null) => {
	let screenWidth = window.screen.width;
    let screenHeight = window.screen.height;

    let windowWidth = screenWidth * 0.90;
    let windowHeight = screenHeight * 0.70;

    let left = (screenWidth - windowWidth) / 2;
    let top = (screenHeight - windowHeight) / 4;

	let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${windowWidth},height=${windowHeight},left=${left},top=${top}`;

	if ( !name ) name = 'r' + Math.random();

	return window.open(href, name, params);
}

export const readableSize = (x) => {
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0, n = parseInt(x, 10) || 0;
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const downloadFile = (url) => {
	const axios = require('axios').default;

	return axios({
	    url: url,
	    method: 'GET',
	    responseType: 'blob',
	}).then((response) => {
	    const url = window.URL.createObjectURL(new Blob([response.data]));
	    const link = document.createElement('a');
	    link.href = url;
	    link.setAttribute('download', response.headers.file_name ?? 'file');
	    document.body.appendChild(link);
	    link.click();
	    link.parentElement.removeChild(link);
	    return true;
	}).catch((error) => {
		return error;
	});
}

export const downloadLocalFile = (data, name = 'file') => {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', name);
	document.body.appendChild(link);
	link.click();
	link.parentElement.removeChild(link);
}

export const ucfirst = (str) => {
    return str[0].toUpperCase() + str.slice(1);
}

export const getPetitionerFormatted = (type) => {
	if ( type === 'owner' ) return 'Propietario'; 
	if ( type === 'renter' ) return 'Inquilino'; 
	if ( type === 'provider' ) return 'Proveedor'; 
	if ( type === 'client' ) return 'Cliente'; 
	if ( type === 'contact' ) return 'Contacto'; 
	if ( type === 'community' ) return 'Comunidad'; 
	return type;
}

export const roundDirection = (number, direction, decimals) => {
	let multiplier = parseFloat("1" + ("".padStart(decimals, '0')));

	if ( direction === 'up' ) {
		return Math.ceil(number * multiplier) / multiplier;
	}

	if ( direction === 'down' ) {
		return Math.floor(number * multiplier) / multiplier;
	}

	return number;
}

export const delay = ms => new Promise(res => setTimeout(res, ms))