import React, { useState, useEffect } from 'react';
import axios from 'axios';
import randomatic from 'randomatic';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

export default function MetersForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		code: randomatic('A0', 5),
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/meters/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteMeter = () => {
		const c = window.confirm('¿Quieres eliminar este contador?');
		if ( !c ) return;

		axios.post('/api/manager/meters/delete/' + data.id, {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Contador')
	    	navigate('/meters');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;

		axios.post('/api/manager/meters/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.meter});

			if ( popup && goBack ) window.close();

			if ( goBack ) navigate('/meters');
			else {
		    	navigate('/meters/edit/' + response.data.meter.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de contador</h1>
					<button onClick={() => navigate('/meters')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
												{ errors.code &&
													<div className="invalid-feedback d-block">{ errors.code[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Unidad" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('unit', e.target.value)} value={data.unit ?? ''} />
												{ errors.unit &&
													<div className="invalid-feedback d-block">{ errors.unit[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Precio por unidad" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('unit_price', e.target.value)} value={data.unit_price ?? ''} />
												{ errors.unit_price &&
													<div className="invalid-feedback d-block">{ errors.unit_price[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Precio por servicio" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('service_price', e.target.value)} value={data.service_price ?? ''} />
												{ errors.service_price &&
													<div className="invalid-feedback d-block">{ errors.service_price[0] }</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											{ (data.id && data.is_deletable) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteMeter()}>Eliminar</button>							
											}
										</div>
										<div className="col-8 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}