import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { loader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import { getFromSession, appendToSession } from 'helpers/session';
import EmpoweredSelector from 'components/EmpoweredSelector';
import ExportModal from 'components/ExportModal';
import Details from './Details';

const Table = styled.table`
	& > thead > tr > th, 
	& > tbody > tr > td {
		&:nth-child(1) {
			width: 100px;
		}

		&:nth-child(2) {
			width: 20px;
			white-space: nowrap;
			text-align: right;
		}

		&:nth-child(4) {
			width: 50px;
		}

		&:nth-child(5),
		&:nth-child(6) {
			width: 50px;
			white-space: nowrap;
			text-align: right;
		}
		
		@media (max-width: 768px) {
			&:nth-child(3) {
				white-space: unset;
			}
			&:nth-child(5),
			&:nth-child(6) {
				display: none;
			}
		}
	}

	tbody {
		tr.details {
			border-bottom: 2px solid var(--bs-primary);

			#loader {
				display: block;
				width: 150px;
				margin: 0 auto;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function ReceiptsIssuances() {
	const [queryParams] = useSearchParams();

	let filters = getFromSession('receipts-issuances-list');

	let community = getCommunity();
	let [forceReload, setForceReload] = useState(null);
	let [issuances, setIssuances] = useState({});
	let [detailsOpened, setDetailsOpened] = useState(filters?.detailsOpened ?? []);
	let [search, setSearch] = useState(filters?.search ?? undefined);
	let [issuanceable, setIssuanceable] = useState(filters?.issuanceable ?? {});
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'date');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [showExportModal, setShowExportModal] = useState(false);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setIssuances({...issuances, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
		} else {
			detailsOpened = [...detailsOpened, rowId];
		}
		setDetailsOpened([...detailsOpened]);

		appendToSession('receipts-issuances-list', {detailsOpened: detailsOpened});
	}

	const loadIssuanceables = (input, callback) => {
		axios.get('/api/manager/receipts-issuances/get-issuanceables-list', {
			params: {
				search: input,
				community_id: getCommunity()?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getIssuances = async () => {
			let params = {
				community_id: community?.id,
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				issuanceable_id: issuanceable?.id,
				type: issuanceable?.type,
			};

			appendToSession('receipts-issuances-list', {...params, issuanceable: {...issuanceable}});

			await axios.get('/api/manager/receipts-issuances/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setIssuances({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getIssuances();
	}, [forceReload, page, search, sortField, sortDirection, community?.id, issuanceable]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Emisiones de recibos</h1>
					
					{ authUserPermission('add') &&
						<div className="ms-auto">
							<NavLink to="/receiptsissuances/new" className="btn btn-sm btn-light">Realizar emisión</NavLink>
							<NavLink to="/receipts/manual-add" className="btn btn-sm btn-light ms-4">Recibo manual</NavLink>	
						</div>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-3">
									<EmpoweredSelector
										load={loadIssuanceables}
										onChange={(value) => setIssuanceable(value)}
										timeout={250}
										label={issuanceable?.name ?? ''}
										value={issuanceable?.id ?? ''}
										placeholder="Origen de emisión"
									/>
								</div>
								<div className="col-md-6 text-end">
									<button className="btn btn-sm btn-light me-2" onClick={() => setShowExportModal(true)}>Exportar</button>
									<NavLink to="/receipts" className="btn btn-sm btn-light">Ver todos</NavLink>	
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'entity.name'} onClick={() => sortTableClick('entity.name')}>Origen emisión</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'description'} onClick={() => sortTableClick('description')}>Descripción</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'receipts.count'} onClick={() => sortTableClick('receipts.count')}>Recibos / cobrados</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'receipts.total'} onClick={() => sortTableClick('receipts.total')}>Total</ThSortable>
										</tr>
									</thead>
									<tbody>
										{ issuances.data?.length > 0 &&
											issuances.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>{moment(el.date).format('DD-MM-YYYY')}</td>
															<td>{el.number}</td>
															<td>{el.entity_name}</td>
															<td>{el.description}</td>
															<td>{el.receipts_count} / {el.receipts_paid_count}</td>
															<td>{el.total} €</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<Details 
																			issuanceId={el.id} 
																			forceReload={() => setForceReload(Math.random())}
																		/>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ issuances.data && !issuances.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ issuances.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={issuances?.current_page}
									max={issuances?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'receipts-issuances'}
					fileName={'Emisiones de recibos ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						date: 						'Fecha',
						number: 					'Nº',
						origin: 					'Origen',
						description: 				'Descripción',
						recount: 					'Recibos / Cobrados',
						total: 						'Total',
						with_receipts: 				'Incluir recibos',
					}}
					filters={{
						community_id: 				community?.id,
						page: 						page,
						search: 					search,
						sort: 						sortField,
						direction: 					sortDirection,
						issuanceable_id: 			issuanceable?.id,
						type: 						issuanceable?.type,
						no_paginate:				true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</LoggedLayout>
	);
}


