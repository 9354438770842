import React, { useRef, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getCommunity } from 'helpers/community';
import { formatNumber } from 'helpers/generic';
import { openPopupWindow } from 'helpers/generic';

const Table = styled.table`
	width: 100%;
	
	td,th {
		font-size: 12px;
		line-height: 12px;

		&:nth-child(1) {

		}
		&:nth-child(2) {
			width: 10%;
			position: relative;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				text-align: center;
				border: 0;
			}
		}
		&:nth-child(3) {
			width: 35px;

			button {
				display: block;
				width: 100%;
				color: var(--bs-red);
				font-size: 14px;
				background: transparent;
				border: none;
			}
		}
	}

	th {
		font-weight: 600;
		padding: 8px;
		white-space: nowrap;
	}

	td {
		&:first-of-type {
			padding: 0;

			.empowered-selector {

				.empowered-selector_label {
					border: 0;
					
					&:not(.disabled) {
						background: transparent;
					}
				}

				.empowered-selector_menu {
					box-shadow: 0px 2px 5px var(--bs-gray-300);
				}
			}
		}

		&.has-errors {
			background: var(--bs-danger);

			.empowered-selector_label,
			input[type=number] {
				color: white;
			}
		}
	}
`;

const RenterRow = styled.tr`
	
`;

let axiosCancelToken = null;

export default function Renters(props) {
	let renters = useMemo(() => props.renters ?? [], [props.renters]);
	let setRenters = props.setRenters;
	let errors = props.errors ?? [];
	let readOnly = props.readOnly ?? false;

	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current.select();
			lastRow.current = null;
		}
	}, [renters]);
 
	const addRenter = () => {
		let rts = [...renters];
		rts.push({
			id: null,
			name: null,
			percent: ''
		});
		setRenters(rts);

		lastRowNumber.current = rts.length - 1; // Needed to focus
	}

	const removeRenter = (idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar este inquilino?');
		if ( !c ) return false;

		let rts = [...renters];
		rts.splice(idx, 1);
		setRenters(rts);
	}

	const loadRenters = (input, callback) => {
		axios.get('/api/manager/renters/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: getCommunity()?.id,
				except_ids: renters.map(el => el.id)
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: <div >
								<div>{el.name}</div>
								{ el.vatnumber &&
									<small className="d-inline-block mt-1 text-secondary">{el.vatnumber}</small>
								}
						   </div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectRenter = (idx, data) => {
		let rts = [...renters];
		rts[idx].id = data?.id;
		rts[idx].name = data?.name;
		setRenters(rts);
	}

	const setPercent = (idx, value) => {
		let rts = [...renters];
		value = parseFloat(value);
		if ( isNaN(value) ) value = '';
		rts[idx].percent = value;
		setRenters(rts);
	}

	const openRenterWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openRenterWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	let rrts = [];
	renters.forEach((el, idx) => {
		rrts.push(
			<RenterRow key={idx}>
				<td className={errors['renters.' + idx + '.id'] ? 'has-errors' : ''}>
					<EmpoweredSelector
						load={(input, callback) => loadRenters(input, callback)}
						onChange={(value) => selectRenter(idx, value)}
						timeout={250}
						label={
							<div>
								{el.name ?? ''}&nbsp;
								{el.id &&
									<NavLink className="text-decoration-none text-reset" to={'/renters/edit/' + el.id + '?popup=true'} onClick={(e) => openRenterWindow(e)} onMouseDown={(e) => openRenterWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
								}
							</div>
						}
						placeholder="Seleccionar"
						value={el.id}
						disabled={readOnly}
					/>
				</td>
				<td className={errors['renters.' + idx + '.percent'] ? 'has-errors' : ''}>
					<input 
						ref={lastRowNumber.current === idx ? lastRow : null} 
						step="0.01"
						type="number" 
						className="no-arrrts" 
						value={el.percent ?? ''} 
						onChange={(e) => setPercent(idx, e.target.value)} 
						readOnly={readOnly}
					/>
				</td>
				<td>
					<button className={'btn p-0 ' + (readOnly ? 'disabled' : '')} onClick={() => removeRenter(idx)}><i className="bi bi-x-circle-fill"></i></button>
				</td>
			</RenterRow>
		);
	});

	let percent_total = 0;
	renters.forEach(el => percent_total += el.percent);
	percent_total = formatNumber(percent_total, 2);

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Inquilinos"
			buttons={
				!readOnly ? 
					<React.Fragment>
						<button className="btn btn-unstyled text-primary" onClick={() => addRenter()}><i className="bi bi-plus-circle-fill"></i></button>
					</React.Fragment>
				:
				null
			}
		>
			{ rrts.length > 0 &&
				<React.Fragment>
					<Table className="table table-sm table-bordered mb-0">
						<thead>
							<tr>
								<th>
									<div className="d-flex justify-content-between">
										Inquilino
										<NavLink className="text-decoration-none text-primary fw-normal" to={'/renters/add/?popup=true'} onClick={(e) => openRenterWindow(e)} onMouseDown={(e) => openRenterWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i> Crear ficha</NavLink>
									</div>
								</th>
								<th>% participación</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{rrts}
						</tbody>
						{rrts.length > 0 &&
							<tfoot>
								<tr 
									className={
										'fw-bold ' + 
										(percent_total < 100 ? 'text-secondary' : '') + 
										(percent_total === 100 ? 'text-primary' : '') + 
										(percent_total > 100 ? 'text-danger' : '')
									}
								>
									<td className="text-end pe-2">Total</td>
									<td className="text-center">
										{ percent_total }%
									</td>
									<td></td>
								</tr>
							</tfoot>
						}
					</Table>
					{ percent_total > 100 &&
						<div className="text-danger d-block text-end sbold">
							<i className="bi bi-exclamation-triangle"></i>&nbsp;
							Has superado el límite de participación
						</div>
					}
				</React.Fragment>
			}
		</LabeledFrame>
	);
}