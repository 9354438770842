import styled from 'styled-components';

const CommentStyled = styled.div`
	color: var(--bs-secondary);
	font-size: 10px;
	line-height: 12px;
`;

export default function FieldSmallComment(props) {
	
	return (
		<CommentStyled className={props.className} style={props.style}>
			{props.children}
		</CommentStyled>
	);
}

													