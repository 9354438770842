import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import CustomFieldsPanel from 'components/CustomFieldsPanel';
import Notes from 'components/Notes';
// import PhoneEmailPanel from 'components/PhoneEmailPanel';
import DocumentsPanel from 'components/DocumentsPanel';
import AddressesPanel from 'components/AddressesPanel';
import ActionsContextMenu from 'components/ActionsContextMenu';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ContactsForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		code: randomatic('A0', 5),
		phone: '',
		email: '',
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/contacts/get/' + params.id, {
					params: {},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data, 'contact');
		};
	}, [data]);

	const deleteProvider = () => {
		const c = window.confirm('¿Quieres eliminar este contacto?');
		if ( !c ) return;

		axios.delete('/api/manager/contacts/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Contacto borrado')
	    	navigate('/contacts');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/contacts/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( goBack ) {
				if ( popup ) {
					data = response.data.contact; // Fix to send last data on before unload
					window.close();
				}
				else navigate(-1);
			} else if ( !data.id ) {
		    	navigate('/contacts/edit/' + response.data.contact.id + (popup ? '?popup=true' : ''));
			}
			setData({...response.data.contact});
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const copyTo = (to) => {
		let c = window.confirm('¿Seguro que quieres copiar este contacto?');
		if ( !c ) return false;
		
		axios.post('/api/manager/contacts/copy/' + data.id, {
			to: to,
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate('/'+to+'/edit/' + response.data.id + (popup ? '?popup=true' : ''));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de contacto</h1>
					<button onClick={() => popup ? window.close() : navigate('/contacts')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-7">

											<LabeledFrame className="mb-3" label="Datos básicos">
												<div className="row">
													<div className="col col-static-80">
														<div className="mb-2">
															<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
															{ errors.code &&
																<div className="invalid-feedback d-block">{ errors.code[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
															{ errors.name &&
																<div className="invalid-feedback d-block">{ errors.name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput label="Nombre comercial" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('brand_name', e.target.value)} value={data.brand_name ?? ''} />
															{ errors.brand_name &&
																<div className="invalid-feedback d-block">{ errors.brand_name[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput label="CIF" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('vatnumber', e.target.value)} value={data.vatnumber ?? ''} />
															{ errors.vatnumber &&
																<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
															}
														</div>
													</div>
												</div>											

												<div className="row">
													<div className="col col-static-230">
														<div className="mb-2">
															<CustomInput label="Dirección" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} />
															{ errors.address &&
																<div className="invalid-feedback d-block">{ errors.address[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-100">
														<div className="mb-2">
															<CustomInput label="C.P." type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} />
															{ errors.postalcode &&
																<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-200">
														<div className="mb-2">
															<CustomInput label="Ciudad" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} />
															{ errors.city &&
																<div className="invalid-feedback d-block">{ errors.city[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-160">
														<div className="mb-2">
															<CustomInput label="Provincia" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} />
															{ errors.province &&
																<div className="invalid-feedback d-block">{ errors.province[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput label="País" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} />
															{ errors.country &&
																<div className="invalid-feedback d-block">{ errors.country[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput label="Teléfono" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('phone', e.target.value)} value={data.phone ?? ''} />
															{ errors.phone &&
																<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-250">
														<div className="mb-2">
															<CustomInput label="Email" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} />
															{ errors.email &&
																<div className="invalid-feedback d-block">{ errors.email[0] }</div>
															}
														</div>
													</div>
												</div>				
											</LabeledFrame>

											{/* <LabeledFrame className="mb-3" label="Contacto">
												<div className="row">
													<div className="col-lg-12">
														<div className="mb-2">
															<label>Persona de contacto</label>
															<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('contact_person', e.target.value)} value={data.contact_person ?? ''} />
															{ errors.contact_person &&
																<div className="invalid-feedback d-block">{ errors.contact_person[0] }</div>
															}
														</div>
													</div>
												</div>
												
												<PhoneEmailPanel
													phone={data.phone}
													email={data.email}
													errors={errors}
													setPhone={(phone) => setData({...data, phone: phone})}
													setEmail={(email) => setData({...data, email: email})}
												/>
											</LabeledFrame> */}

											<AddressesPanel
												addresses={data.addresses}
												errors={errors}
												setAddresses={(addresses) => setData({...data, addresses: addresses})}
											/>
										</div>
										<div className="col-lg-5">
											<CustomFieldsPanel 
												className="mb-3"
												type="contacts"
												fields={data.customfields_array}
												setFields={(fields) => setData((data) => ({...data, customfields_array: fields}))}
											/>

											<LabeledFrame className="mb-3" label="Instrucciones">
												<textarea className="form-control form-control-sm" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''}></textarea>
											</LabeledFrame>
											
											<Notes
												className="mb-3"
												notes={data.notes ?? []}
												updateParentCallback={(notes) => setData((data) => ({...data, notes: notes}))}
											/>

											{ data.id && 
												<DocumentsPanel
													className="mb-3"
													folderId={data.folder_id ?? null}
													community={getCommunity()}
												/>
											}
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{/*{ (getUser().company_role === 'principal' && data.id) &&*/}
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteProvider()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{/*{ (!data.id || (data.id && getUser().company_role === 'principal')) &&*/}
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><NavLink className="dropdown-item" to={'/notices?petitioner_type=contact&petitioner_id=' + data.id} onClick={openPopupInfoWindow}>Ver avisos</NavLink></li>
																<li><NavLink className="dropdown-item" to={'/notices/add?petitioner_type=contact&contact_id=' + data.id} onClick={openPopupInfoWindow}>Nuevo aviso</NavLink></li>
																{ getCommunity()?.id &&
																	<React.Fragment>
																		<li><hr className="dropdown-divider" /></li>
																		<li><button className="btn btn-link dropdown-item" onClick={() => copyTo('owners')}>Copiar a propietarios</button></li>
																		<li><button className="btn btn-link dropdown-item" onClick={() => copyTo('renters')}>Copiar a inquilinos</button></li>
																		<li><button className="btn btn-link dropdown-item" onClick={() => copyTo('providers')}>Copiar a proveedores</button></li>
																		<li><button className="btn btn-link dropdown-item" onClick={() => copyTo('clients')}>Copiar a clientes</button></li>
																	</React.Fragment>
																}		

																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											{/*}*/}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}