import React, { useState, useEffect, useRef } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import { formatNumber, loader } from 'helpers/generic';
import { getFromSession, appendToSession } from 'helpers/session';
import { toast } from 'react-toastify';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 80px;
		}

		&:nth-child(2) {
			/* width: 150px; */
		}

		&:nth-child(3) {
			width: 120px;
			text-align: right;
		}

		&:nth-child(4),
		&:nth-child(5) {
			width: 120px;
			text-align: right;
		}

		&:nth-child(6) {
			width: 150px;
		}
	}
`;

const Dragging = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	font-size: 25px;
	z-index: 99;

	button {
		font-size: 20px;
		color: red;
	}
`;

const Uploading = styled.div`
	background: white;
	border: 1px solid var(--bs-primary);
	border-radius: 5px;
	margin-top: 20px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 10px;
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Treasury() {
	const [queryParams] = useSearchParams();

	const fileRef = useRef(null);

	let filters = getFromSession('bank_reconciliation-list');

	let community = getCommunity();
	let [file, setFile] = useState(null);
	let [fileErrors, setFileErrors] = useState([]);
	let [uploading, setUploading] = useState(false);
	let [dragging, setDragging] = useState(null);
	let [treasuries, setTreasuries] = useState({});
	let [search, setSearch] = useState(filters?.search ?? undefined);
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'asc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'name');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setTreasuries({...treasuries, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getTreasuries = async () => {
			let params = {
				community_id: community?.id,
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				type: 'bankaccount',
				with_balance: true,
				with_pending_noreconciliatedbankmovements_count: true
			};

			appendToSession('bank_reconciliation-list', params);

			await axios.get('/api/manager/treasury/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setTreasuries({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getTreasuries();
	}, [page, search, sortField, sortDirection, community?.id]);

	const onDropHandler = (e) => {
		e.preventDefault();

		setDragging(false);

		let file = e.dataTransfer.files[0];
		setFile(file);
	}

	const onDragHover = (e) => {
		e.preventDefault();
		setDragging(true);
	}

	const openFileInput = () => {
		setFile(null);
		fileRef.current.value = null;
		fileRef.current.click();
	}

	const fileInputChange = (e) => {
		let file = e.target.files[0];
		setFile(file);
	}

	useEffect(() => {
		if ( !file ) return;
		setFileErrors([]);

		let formData = new FormData();
		formData.append('file', file);
		formData.append('community_id', getCommunity()?.id);

		const uploadFile = async () => {
			setUploading(true);
			await axios.post('/api/manager/bank-reconciliation/upload-file', formData, {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	if ( response.data.status === 0 ) setFileErrors([...response.data.errors]);
				if ( response.data.status === 1 ) toast.success('Fichero subido');
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
			setFile(null);
			setUploading(false);
		}
		uploadFile();
	}, [file])

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section
				onDrop={onDropHandler}
				onDragOver={(e) => onDragHover(e)}
				// onDragLeave={(e) => setDragging(false)}
			>
				{ dragging && 
					<Dragging>
						Suelta el archivo aquí
						<div>
							<button className="btn btn-link btn-sm text-danger" onClick={() => setDragging(false)}>Cancelar</button>
						</div>
					</Dragging> }
				<div className="page-title">
					<h1>Conciliación bancaria</h1>
					
					{ authUserPermission('add') &&
						<button className="btn btn-sm btn-light ms-auto" onClick={openFileInput}>Subir fichero norma 43</button>
					}
				</div>

				{ (uploading || fileErrors.length > 0) &&
					<Uploading>
						{ uploading && loader }
						{ fileErrors.length > 0 && 
							fileErrors.map((el, idx) => {
								return <div key={idx} className="text-danger">- {el}</div>
							})
						}
					</Uploading>
				}

				<div className="page-content">
					<input type="file" ref={fileRef} className="d-none" onChange={fileInputChange}  />


					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'code'} onClick={() => sortTableClick('code')}>Código</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'contableaccount.balance'} onClick={() => sortTableClick('contableaccount.balance')}>Saldo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'real_balance'} onClick={() => sortTableClick('real_balance')}>Saldo real</ThSortable>
											<th>Sin conciliar</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ treasuries.data?.length > 0 &&
											treasuries.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.code ?? '-'}</td>
															<td>
																{el.name ?? '-'}
																<div><small>{el.data?.iban}</small></div>
															</td>
															<td>{el.contableaccount ? formatNumber(el.contableaccount.balance) : 0}</td>
															<td>{el.real_balance ? formatNumber(el.real_balance) : 0}</td>
															<td>{el.noreconciliatedbankmovements_count}</td>
															<td>
																<NavLink className="btn-sm text-decoration-none border border-primary border-1 bg-white d-block" to={'/bank-reconciliation/' + el.id + '/movements'}>
																	<i className="bi bi-arrow-left-right"></i>
																	&nbsp;Ver movimientos
																</NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ treasuries.data && !treasuries.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ treasuries.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={treasuries?.current_page}
									max={treasuries?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


