import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { NavLink, useSearchParams } from 'react-router-dom';
import EmpoweredSelector from 'components/EmpoweredSelector';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { openPopupWindow } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { getCommunity } from 'helpers/community';

const Table = styled.table`
	
	tr {
		&.has-details {
			line-height: 15px;
		}

		&.details {
			border-bottom: 1px solid var(--bs-primary);
			line-height: 18px;
		}

		&.passed {
			&.has-details {
				background: #ff1e175e;
			}

			&.details {
				border-bottom: 1px solid #ff1e175e;
			}
		}

		th,td {
			font-size: 13px;

			&.date {
				width: 90px;
				min-width: 90px;
				max-width: 90px;
			}

			&.community {
				width: 150px;
				min-width: 150px;
				max-width: 150px;
			}

			&.name {

			}

			&.user {
				width: 100px;
				min-width: 100px;
				max-width: 100px;
			}
			
			@media (max-width: 768px) {
				&:nth-child(2),
				&:nth-child(4) {
					display: none;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Diary() {
	const [queryParams] = useSearchParams();

	let [events, setEvents] = useState({});
	let [forceReload, setForceReload] = useState(null);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('date_from');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	let [viewOnlyNotFinished, setViewOnlyNotFinished] = useState(1);
	const setPage = (page) => {
		setEvents({...events, data: undefined});
		_setPage(page);
	}
	let [community, setCommunity] = useState({
		id: getCommunity()?.id,
		name: getCommunity()?.name
	});
	let [user, setUser] = useState({
		id: getUser()?.id,
		name: getUser()?.name
	});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	useEffect(() => {
		const getEvents = async () => {
			await axios.get('/api/manager/events/list', {
				params: {
					page: page,
					sort: sortField,
					direction: sortDirection,
					community_id: community?.id,
					user_id: user?.id,
					finished: viewOnlyNotFinished ? 0 : null,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setEvents({...response.data});
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getEvents();
	}, [forceReload, page, sortField, sortDirection, community, user, viewOnlyNotFinished]);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	const setEventFinished = (status, id) => {
		axios.post('/api/manager/events/set-finished/' + id, {
			status: status
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	return (
		<div className="card border-0 shadow-sm">
			<div className="card-header bg-white p-3">
				<div className="row align-items-center">
					<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
						<h5 className="card-title mb-0 text-primary d-flex h-100 align-items-center justify-content-center">Agenda</h5>
					</div>
					<div className="col-md-6 mb-2 mt-2 mb-md-0 mt-md-0">
						<EmpoweredSelector
							load={loadCommunities}
							onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
							timeout={250}
							label={community?.name ?? ''}
							placeholder="Todas las comunidades"
							value={community?.id}
						/>
					</div>
					<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
						<EmpoweredSelector
							load={loadUsers}
							onChange={(value) => setUser({id: value?.id, name: value?.name})}
							timeout={250}
							label={user?.name ?? ''}
							placeholder="Todos los usuarios"
							value={user?.id}
						/>
					</div>
					<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
						<NavLink to="/events/add" className="btn btn-primary text-white btn-sm w-100" onClick={openPopupInfoWindow}><i className="bi bi-plus-circle"></i></NavLink>
					</div>
				</div>
			</div>
			<div className="card-body p-0">
				<div className="table-responsive table-responsive-carded">
					<Table className="table table-hover table-sortable table-carded">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'date_from'} onClick={() => sortTableClick('date_from')}>Fecha</ThSortable>
								{ !community?.id &&
									<ThSortable direction={sortDirection} active={sortField === 'community.name'} onClick={() => sortTableClick('community.name')}>Comunidad</ThSortable>
								}
								<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Evento</ThSortable>
								{ !user?.id &&
									<ThSortable direction={sortDirection} active={sortField === 'user.name'} onClick={() => sortTableClick('user.name')}>Usuario</ThSortable>
								}
							</tr>
						</thead>
						<tbody>
							{ events.data?.length > 0 &&
								events.data.map((el, idx) => {
									let passed = moment(el.date_to).isBefore(moment()) && !el.finished;

									let action = null;
									if ( el.action ) {
										if ( el.action.type === 'open-notice' ) {
											action = <NavLink className="btn btn-sm btn-secondary p-0 px-1" to={'/notices/edit/' + el.action.id} onClick={openPopupInfoWindow}>Abrir aviso</NavLink>;
										}
										if ( el.action.type === 'open-contract' ) {
											action = <NavLink className="btn btn-sm btn-secondary p-0 px-1" to={'/contracts/edit/' + el.action.id + '?popup=true'} onClick={openPopupInfoWindow}>Abrir contrato</NavLink>;
										}
										if ( el.action.type === 'open-meeting' ) {
											action = <NavLink className="btn btn-sm btn-secondary p-0 px-1" to={'/meetings/edit/' + el.action.id + '?popup=true'} onClick={openPopupInfoWindow}>Abrir junta</NavLink>;
										}
									}

									return ( 
										<React.Fragment key={idx}>
											<tr className={'has-details ' + (passed ? 'passed' : '')} onClick={() => toggleDetails(el.id)}>
												<td className="date">
													{ moment(el.date_from).format('DD-MM-YYYY') }
													<div><small>{ moment(el.date_from).format('HH:mm') }</small></div>
												</td>
												{ !community?.id &&
													<td className="community">{el.community?.name ?? ''}</td>
												}
												<td className="name">
													{el.name}
													{ action &&
														<div>{action}</div>
													}
												</td>
												{ !user?.id &&
													<td className="user">{el.user?.name}</td>
												}
											</tr>
											{ detailsOpened.includes(el.id) && 	
												<tr className={'details ' + (passed ? 'passed' : '')}>
													<td colSpan="100%" className="p-0 py-2">
														<div className="row ms-0 me-0">
															<div className="col-md-5">
																<div><b className="sbold">Comunidad</b>: {el.community?.name ?? '-'}</div>
																<div className="mb-3"><b className="sbold">Usuario</b>: {el.user?.name ?? '-'}</div>

																<div>
																	<b className="sbold">Desde</b>: 
																	{ moment(el.date_from).format('DD-MM-YYYY') }
																	&nbsp;a las&nbsp;
																	{ moment(el.date_from).format('HH:mm') }
																</div>
																<div className="mb-3 mb-md-0">
																	<b className="sbold">Hasta</b>: 
																	{ moment(el.date_to).format('DD-MM-YYYY') }
																	&nbsp;a las&nbsp;
																	{ moment(el.date_to).format('HH:mm') }
																</div>																
															</div>
															<div className="col-md-5">
																<div><b className="sbold">Nombre</b>: {el.name ?? '-'}</div>
																<div><b className="sbold">Lugar</b>: {el.place ?? '-'}</div>
																<div className="mb-3 mb-md-0"><b className="sbold">Descripción</b>: {el.description ?? '-'}</div>
																{ action &&
																	<div><b className="sbold">Acción</b>: {action}</div>
																}
															</div>
															<div className="col-md-2">
																<NavLink className="btn btn-primary btn-sm text-white w-100 mb-1" to={'/events/edit/' + el.id} onClick={openPopupInfoWindow}>Editar</NavLink>
																<button 
																	className={'btn btn-'+(el.finished ? 'success' : 'warning')+' btn-sm w-100'} 
																	onClick={() => setEventFinished(!el.finished, el.id)}
																>
																	{el.finished ? 'Desmarcar' : 'Finalizar'}
																</button>
															</div>
														</div>
													</td>
												</tr>
											}
										</React.Fragment>
									);
								})
							}

							{ events.data && !events.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ events.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
						</tbody>
					</Table>
				</div>
			</div>
			<div className="card-footer p-3">
				<div className="row">
					<div className="col md-6">
						<div className="form-check form-switch">
							<input className="form-check-input" type="checkbox" id="toggleNotFinished" checked={viewOnlyNotFinished} onChange={(e) => setViewOnlyNotFinished(e.target.checked)} />
							<label className="form-check-label" htmlFor="toggleNotFinished">Mostrar solo eventos sin finalizar</label>
						</div>
					</div>
					<div className="col md-6 d-flex justify-content-end">
						<div className="d-inline-block">
							<Paginator
								min={1}
								current={events?.current_page}
								max={events?.last_page}
								changeCallback={(page) => setPage(page)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


