import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { readableSize } from 'helpers/generic';

const Backdrop = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 10px;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
`;

const Modal = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 300px;
	max-width: 500px;
	max-height: 100%;
	padding: 10px;
	border-radius: 5px;
	background: white;
`;

const FilesContainer = styled.div`
	overflow-y: auto;
	height: 100%;
`;

const File = styled.div`
	padding: 5px;
	border: 1px solid ${prop => prop.error === true ? 'red' : 'var(--bs-gray-300)'};
	background: var(--bs-gray-200);
	border-radius: 3px;
	font-size: 13px;
	line-height: 16px;

	.progress {
		margin-top: 5px;
	}

	.error {
		color: var(--bs-red);
	}

	&:not(:last-of-type) {
		margin-bottom: 10px;
	}
`;

const CancelButton = styled.button`
	display: block;
	margin: 0 auto;
	margin-top: 5px;
	width: 50%;
	max-width: 120px;
`;

let axiosCancelToken = null;

export default function UploadModal(props) {
	const [files, _setFiles] = useState([]);
	const filesFix = useRef(files);
	const setFiles = (files) => {
		filesFix.current = files;
		_setFiles(files);
	}
	const [fileUploadingIdx, setFileUploadingIdx] = useState(null);
	const uploadStartedRef = useRef(false);
	const errorsRef = useRef(0);

	const filesObjects = props.files;
	const folder = props.folder;
	const close = props.close;
	const community = props.community;

	// Prepare files state
	useEffect(() => {
		let tmpFiles = [];
		props.files.forEach((el) => {
			let file = {
				name: el.name,
				size: el.size,
				progress: 0,
				error: ''
			};
			tmpFiles.push(file);
		});
		setFiles(tmpFiles);
	}, [props.files]);

	// Axios
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Fix to start upload after files status changed
	useEffect(() => {
		if ( files.length === 0 || uploadStartedRef.current ) return;

		uploadStartedRef.current = true;
		setFileUploadingIdx(0);
	}, [files]);

	const cancelUpload = () => {
		props.close();
	}

	// Upload files
	useEffect(() => {
		// Close modal if not errors
		if ( fileUploadingIdx === -1 ) {
			if ( errorsRef.current === 0 ) close();
			else toast.error('Han ocurrido errores al subir algún archivo');
			return;
		}

		// Prevent pass on first render
		if ( fileUploadingIdx === null ) return; 

		const uploadFile = (file, idx) => {
	        let formData = new FormData();
			formData.append('file', file);
			formData.append('folder_id', folder?.id);
			if ( community?.id ) formData.append('community_id', community?.id);

			return axios.post('/api/manager/documents/upload-file', formData, {
				headers: {
					'Content-Type': 'multipart/form-data' 
				},
				cancelToken: axiosCancelToken.token,
				onUploadProgress: (e) => {
					let percentCompleted = Math.round((e.loaded * 100) / e.total);

					// Set progress
					let newFiles = [...filesFix.current];
					newFiles[idx].progress = percentCompleted;
					setFiles(newFiles);
				}
			})
			.then((response) => {})
			.catch((error) => {
				if ( axios.isCancel(error) ) return;
				
				// Set error
				let newFiles = [...filesFix.current];
				newFiles[idx].error = error.response?.data?.message ?? 'error indefinido';
				setFiles(newFiles);

				// Sum errors counter
				errorsRef.current += 1;
			})
			.then(() => {
				// Set next to upload
				let nextIdx = filesFix.current[idx+1] === undefined ? -1 : idx+1; 
				setFileUploadingIdx(nextIdx);
			});
		}	

		const uploadFileAsync = async () => {
			let file = filesObjects[fileUploadingIdx];
			await uploadFile(file, fileUploadingIdx);
		}
		uploadFileAsync();
	}, [fileUploadingIdx, filesObjects, close, folder, errorsRef, community?.id]);

	return (
		<Backdrop>
			<Modal>
				<FilesContainer>
					{ files.map((el, idx) => {
						return (
							<File key={idx} error={el.error.length > 0 ? true : false}>
								<div className="name"><span className="sbold">Nombre:</span> {el.name}</div>
								<div className="size"><span className="sbold">Tamaño:</span> {readableSize(el.size)}</div>
								<div className="progress bg-light">
									<div className="progress-bar bg-primary" role="progressbar" style={{width: el.progress + '%'}}>{el.progress}%</div>
								</div>
								<div className="error">{el.error}</div>
							</File>
						);
					})}
				</FilesContainer>
				<CancelButton className={'btn btn-'+(fileUploadingIdx === -1 ? 'secondary' : 'danger')+' btn-sm text-white'} onClick={() => cancelUpload()}>{fileUploadingIdx === -1 ? 'Cerrar' : 'Cancelar'}</CancelButton>
			</Modal>
		</Backdrop>
	);
}


