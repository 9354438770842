import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

let parentWindowProxyCallback = window.opener?.PopupProxyCallback;

export default function ContableAccountsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const parent_id = queryParams.get('parent_id');
	const parent_readonly = queryParams.get('parent_readonly') === 'true';

	let [data, setData] = useState({});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/contable-accounts/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	useEffect(() => {
		axios.get('/api/manager/contable-accounts/get/' + parent_id, {
			params: {
				community_id: getCommunity()?.id,
				with_next_child_code: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	setData((prev) => ({
				...prev, 
				parent: response.data, 
				parent_id: response.data.id, 
				code: response.data.nextChildCode
			}));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}, [parent_id]);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data);
		};
	}, [data]);

	const loadContableAccounts = (input, callback) => {
		axios.get('/api/manager/contable-accounts/list-ordered', {
			params: {
				community_id: getCommunity()?.id,
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: 	<div style={{paddingLeft: (el.level * 5) + 'px'}}>
								<b className="me-1">{el.code}</b>  
								<span>{el.name}</span>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const deleteAccount = () => {
		const c = window.confirm('¿Quieres eliminar esta cuenta?');
		if ( !c ) return;

		axios.post('/api/manager/contable-accounts/delete/' + data.id, {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Cuenta borrada')
	    	navigate('/contable-accounts');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;

		axios.post('/api/manager/contable-accounts/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.account});

			if ( popup && goBack ) {
				if ( parentWindowProxyCallback ) data = response.data.account; // Fix because parent needs id and update state function on previous line from this line update state async
				window.close();
				return;
			}

			if ( goBack ) navigate('/contable-accounts');
			else {
		    	navigate('/contable-accounts/edit/' + response.data.account.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de cuenta contable</h1>
					<button onClick={() => popup ? window.close() : navigate('/contable-accounts')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-4">
											<div className="mb-2">
												<EmpoweredSelector
													load={loadContableAccounts}
													onChange={(value) => setData((prev) => ({...prev, parent: value, parent_id: value?.id}))}
													timeout={250}
													label={
														data.parent?.id ?
															<div>
																<b className="me-1">{data.parent?.code}</b>  
																<span>{data.parent?.name}</span>
															</div>
														: null
													}
													placeholder="Cuenta superior"
													showPlaceholderHelper={true}
													value={data.parent?.id}
													disabled={parent_readonly || data.id}

												/>
												{ errors.parent_id &&
													<div className="invalid-feedback d-block">{ errors.parent_id[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} disabled={data.id ? true : false} />
												{ errors.code &&
													<div className="invalid-feedback d-block">{ errors.code[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && data.is_deletable) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteAccount()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}