import { useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import LabeledFrame from 'components/LabeledFrame';
import styled from 'styled-components';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

const AddressRow = styled.div`
	&:not(:last-of-type) {
		margin-bottom: 10px;
	}
`;


const PhoneEmailRowContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	@media (max-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;

const PhoneEmailRow = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 10px;
	margin-bottom: 10px;
	position: relative;

	.btn-remove {
		position: relative;
		left: -5px;
		top: -8px;
		z-index: 0;
		height: 15px !important;
	}

	input[type=text],
	input[type=email] {
		display: inline-block !important;
	}

	.input-group-text {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		font-size: 10px;
		color: var(--bs-secondary);

		span {
			display: block;
			padding-left: 5px;
		}
	}

	.invalid-feedback {
		font-size: 12px;
		line-height: 12px;
	}
`;

let axiosCancelToken = null;

export default function AddressesPanel(props) {
	let addresses = useMemo(() => props.addresses ?? [], [props.addresses]);
	let properties = useMemo(() => props.properties ?? [], [props.properties]);
	let setAddresses = props.setAddresses;
	let errors = props.errors ?? [];
	let showRelationField = props.showRelationField ?? false;
	let readOnly = props.readOnly ?? false;

	let lastRow = useRef(null);
	let lastRowNumber = useRef(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( lastRow.current ) {
			lastRow.current.focus();
			lastRow.current = null;
		}
	}, [addresses]);
 
	const addAddress = () => {
		let addrs = [...addresses];
		addrs.push({
			default: !addrs.length ? true : false,
			observations: null,
			phone: [
				{
					phone: '',
					default: true
				}
			],
			email: [
				{
					email: '',
					default: true
				}
			],
			province: getCommunity()?.province,
			city: getCommunity()?.city,
			country: getCommunity().country,
		});
		setAddresses(addrs);

		lastRowNumber.current = addrs.length - 1; // Needed to focus
	}

	const removeAddress = (idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar esta dirección?');
		if ( !c ) return false;

		let addrs = [...addresses];
		addrs.splice(idx, 1);
		setAddresses(addrs);
	}

	const setAddressField = (idx, field, value) => {
		let addrs = [...addresses];
		if ( field === 'default' ) addrs.forEach(el => el.default = false);
		addrs[idx][field] = value;
		setAddresses(addrs);
	}

	const toggleAddressType = (idx, type, status) => {
		let address = {...addresses[idx]};
		if ( status ) {
			if ( address.type ) address.type = 'both';
			else address.type = type;
		} else {
			if ( address.type === 'both' ) {
				if ( type === 'residence' ) address.type = 'correspondence';
				else address.type = 'residence';
			} else {
				address.type = null;
			}
		}
		setAddressField(idx, 'type', address.type);
	}

	const copyAddressFromProperty = (e, addressIdx, property) => {
		e.preventDefault();

		let addrs = [...addresses];
		let address = {...addrs[addressIdx]}
		address.name = "";
		address.address = (getCommunity()?.address ?? '') + (property?.number ? ', ' + property?.number : '');
		address.postalcode = getCommunity()?.postalcode;
		address.city = getCommunity()?.city;
		address.province = getCommunity()?.province;
		address.country = getCommunity()?.country;

		addrs[addressIdx] = address;

		setAddresses(addrs);
	}

	const addPhoneRow = (addressIdx) => {
		let p = [...addresses[addressIdx].phone ?? []];
		p.push({
			phone: '',
			default: !p.length ? true : false
		});
		setAddressField(addressIdx, 'phone', p);
	}

	const removePhoneRow = (addressIdx, idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar este teléfono?');
		if ( !c ) return false;

		let p = [...addresses[addressIdx].phone ?? []];
		p.splice(idx, 1);
		setAddressField(addressIdx, 'phone', p);
	}

	const setPhoneData = (addressIdx, idx, field, value) => {
		let p = [...addresses[addressIdx].phone ?? []];
		if ( field === 'default' ) p.forEach(el => el.default = false);
		p[idx][field] = value;
		setAddressField(addressIdx, 'phone', p);
	}

	const removeEmailRow = (addressIdx, idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar este email?');
		if ( !c ) return false;

		let e = [...addresses[addressIdx].email ?? []];
		e.splice(idx, 1);
		setAddressField(addressIdx, 'email', e);
	}

	const addEmailRow = (addressIdx) => {
		let e = [...addresses[addressIdx].email ?? []];
		e.push({
			email: '',
			default: !e.length ? true : false
		});
		setAddressField(addressIdx, 'email', e);
	}

	const setEmailData = (addressIdx, idx, field, value) => {
		let e = [...addresses[addressIdx].email ?? []];
		if ( field === 'default' ) e.forEach(el => el.default = false);
		e[idx][field] = value;
		setAddressField(addressIdx, 'email', e);
	}

	const loadRelations = (input, callback) => {
		axios.get('/api/manager/addresses-relations/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectRelation = (idx, value) => {
		let addrs = [...addresses];
		addrs[idx]['relation_id'] = value?.id ?? null;
		addrs[idx]['relation'] = value;
		setAddresses(addrs);
	}

	let rows = [];
	addresses.forEach((el, idx) => {
		let label = <div className="d-flex align-items-center">
						<label className="d-inline-flex align-items-center user-select-none h-100 w-100">
							<input 
								type="checkbox" 
								className="me-1" 
								onChange={(e) => setAddressField(idx, 'default', e.target.checked)} 
								checked={el.default ?? ''} 
								disabled={readOnly}
							/>
							marcar como principal
						</label>
						
						<button 
							tabIndex="-1"
							className={'btn btn-sm text-danger p-0 ms-4 h-auto btn-remove ' + (addresses.length === 1 && idx === 0 ? 'text-primary' : '')} 
							disabled={(addresses.length === 1 && !props.deleteAll) || readOnly} 
							onClick={() => removeAddress(idx)}
						>
							<i className="bi bi-x-circle-fill"></i>
						</button>
					</div>;
		rows.push(
			<AddressRow key={idx}>
				<LabeledFrame label={label} background="gray-200" color="gray-600">
					<div className="row">
						<div className="col-md-6">
							{ (properties.length > 0 && !readOnly) &&
								<div className="dropdown">
									<button className="btn btn-default border btn-sm p-0 px-2 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
										Copiar de propiedad
									</button>
									<ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
										{ properties.map((pEl, pIdx) => {
											return (
												<li key={pIdx}><button className="dropdown-item" onClick={(e) => copyAddressFromProperty(e, idx, pEl)}>{pEl.name}</button></li>
											);
										})}
									</ul>
								</div>
							}
						</div>
						<div className="col-md-6 text-end">
							<div className="mb-0">
								<div className="d-inline-block text-start">
									<div className="form-check d-inline-block me-4">
										<input disabled={readOnly} className="form-check-input" id={'address-radio-r' + idx} type="checkbox" onChange={(e) => toggleAddressType(idx, 'residence', e.target.checked)} checked={el.type === 'residence' || el.type === 'both'} />
										<label className="form-check-label" htmlFor={'address-radio-r' + idx}>
											Residencia
										</label>
									</div>

									<div className="form-check d-inline-block">
										<input disabled={readOnly} className="form-check-input" id={'address-radio-c' + idx} type="checkbox" onChange={(e) => toggleAddressType(idx, 'correspondence', e.target.checked)} checked={el.type === 'correspondence' || el.type === 'both'} />
										<label className="form-check-label" htmlFor={'address-radio-c' + idx}>
											Correspondencia
										</label>
									</div>
								</div>
								{ errors['addresses.'+idx+'.type'] &&
									<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.type'][0] }</div>
								}
							</div>
						</div>
						<div className="col-md-12 mt-2"></div>
						<div className="col col-static-250">
							<div className="mb-2">
								<CustomInput 
									label="Nombre" 
									ref={lastRowNumber.current === idx ? lastRow : null} 
									type="text" 
									className="form-control form-control-sm" 
									onChange={(e) => setAddressField(idx, 'name', e.target.value)} 
									value={el.name ?? ''} 
									readOnly={readOnly}
								/>
								{ errors['addresses.'+idx+'.name'] &&
									<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.name'][0] }</div>
								}
							</div>
						</div>
						{ showRelationField &&
							<div className="col col-static-250">
								<div className="mb-2">
									<EmpoweredSelector
										load={(input, callback) => loadRelations(input, callback)}
										onChange={(value) => selectRelation(idx, value)}
										timeout={250}
										label={el.relation?.name ?? ''}
										placeholder="Relación"
										showPlaceholderHelper={true}
										value={el.relation?.id}
										disabled={readOnly}
									/>
									{ errors['addresses.'+idx+'.relation_id'] &&
										<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.relation_id'][0] }</div>
									}
								</div>
							</div>
						}
						<div className="col col-static-300">
							<div className="mb-2">
								<CustomInput 
									label="Dirección" 
									type="text" 
									className="form-control form-control-sm" 
									onChange={(e) => setAddressField(idx, 'address', e.target.value)} 
									value={el.address ?? ''} 
									readOnly={readOnly}
								/>
								{ errors['addresses.'+idx+'.address'] &&
									<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.address'][0] }</div>
								}
							</div>
						</div>
						<div className="col col-static-80">
							<div className="mb-2">
								<CustomInput 
									label="C.P." 
									type="text" 
									className="form-control form-control-sm" 
									onChange={(e) => setAddressField(idx, 'postalcode', e.target.value)} 
									value={el.postalcode ?? ''} 
									readOnly={readOnly}
								/>
								{ errors['addresses.'+idx+'.postalcode'] &&
									<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.postalcode'][0] }</div>
								}
							</div>
						</div>
						<div className="col col-static-140">
							<div className="mb-2">
								<CustomInput 
									label="Ciudad" 
									type="text" 
									className="form-control form-control-sm" 
									onChange={(e) => setAddressField(idx, 'city', e.target.value)} 
									value={el.city ?? ''} 
									readOnly={readOnly}
								/>
								{ errors['addresses.'+idx+'.city'] &&
									<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.city'][0] }</div>
								}
							</div>
						</div>
						<div className="col col-static-140">
							<div className="mb-2">
								<CustomInput 
									label="Provincia" 
									type="text" 
									className="form-control form-control-sm" 
									onChange={(e) => setAddressField(idx, 'province', e.target.value)} 
									value={el.province ?? ''} 
									readOnly={readOnly}
								/>
								{ errors['addresses.'+idx+'.province'] &&
									<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.province'][0] }</div>
								}
							</div>
						</div>
						<div className="col-md-2">
							<div className="mb-2">
								<CustomInput 
									label="País" 
									type="text" 
									className="form-control form-control-sm" 
									onChange={(e) => setAddressField(idx, 'country', e.target.value)} 
									value={el.country ?? ''} 
									readOnly={readOnly}
								/>
								{ errors['addresses.'+idx+'.country'] &&
									<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.country'][0] }</div>
								}
							</div>
						</div>

						<div className="col-md-12">
							<label>
								Teléfonos&nbsp;
								<button 
									className={'btn btn-sm text-primary p-0 h-auto ' + (readOnly ? 'disabled' : '')} 
									onClick={() => addPhoneRow(idx)}
									disabled={readOnly}
								>
									<i className="bi bi-plus-circle-fill"></i>
								</button>
							</label>
							<PhoneEmailRowContainer>
								{el.phone?.map((pEl, pIdx) => {
									return (
										<PhoneEmailRow key={pIdx}>
											<div className="input-group input-group-sm">
												<input readOnly={readOnly} type="text" maxLength="20" className="form-control form-control-sm" value={pEl?.phone ?? ''} onChange={(e) => setPhoneData(idx, pIdx, 'phone', e.target.value)} />
												<span className="input-group-text">
													<input disabled={readOnly} type="checkbox" checked={pEl?.default ?? false} onChange={(e) => setPhoneData(idx, pIdx, 'default', e.target.checked)} />
													{/* <span>Principal</span> */}
												</span>
												<button tabIndex="-1" disabled={readOnly} className="btn btn-sm text-danger p-0 h-auto btn-remove" onClick={() => removePhoneRow(idx, pIdx)}><i className="bi bi-x-circle-fill"></i></button>
											</div>
											{ errors['addresses.'+idx+'.phone.'+pIdx+'.phone'] &&
												<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.phone.'+pIdx+'.phone'][0] }</div>
											}
										</PhoneEmailRow>
									);
								})}
							</PhoneEmailRowContainer>
							{ errors['addresses.'+idx+'.phone'] &&
								<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.phone'] }</div>
							}
						</div>
						<div className="col-md-12">
							<label>
								Emails&nbsp;
								<button disabled={readOnly} className="btn btn-sm text-primary p-0 h-auto" onClick={() => addEmailRow(idx)}><i className="bi bi-plus-circle-fill"></i></button>
							</label>
							<PhoneEmailRowContainer>
								{el.email?.map((eEl, eIdx) => {
									return (
										<PhoneEmailRow key={eIdx}>
											<div className="input-group input-group-sm">
												<input readOnly={readOnly} type="text" maxLength="100" className="form-control form-control-sm" value={eEl?.email ?? ''} onChange={(e) => setEmailData(idx, eIdx, 'email', e.target.value)} />
												<span className="input-group-text">
													<input disabled={readOnly} type="checkbox" checked={eEl?.default ?? false} onChange={(e) => setEmailData(idx, eIdx, 'default', e.target.checked)} />
													{/* <span>Principal</span> */}
												</span>
												<button disabled={readOnly} tabIndex="-1" className="btn btn-sm text-danger p-0 h-auto btn-remove" onClick={() => removeEmailRow(idx, eIdx)}><i className="bi bi-x-circle-fill"></i></button>
											</div>
											{ errors['addresses.'+idx+'.email.'+eIdx+'.email'] &&
												<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.email.'+eIdx+'.email'][0] }</div>
											}
										</PhoneEmailRow>
									);
								})}
							</PhoneEmailRowContainer>
							{ errors['addresses.'+idx+'.email'] &&
								<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.email'] }</div>
							}
						</div>

						<div className="col-md-12 text-end">
							{ el.observations === null && 
								<button className="btn btn-link btn-sm text-decoration-none small p-0" onClick={() => setAddressField(idx, 'observations', '')}><i className="bi bi-plus"></i> Añadir observaciones</button>
							}
							{ el.observations !== null && 
								<div className="mb-0">
									<label className="d-block text-start">
										Observaciones
										<button disabled={readOnly} className="btn btn-link btn-sm text-decoration-none p-0 text-danger" onClick={() => setAddressField(idx, 'observations', null)}><i className="bi bi-x"></i></button>
									</label>
									<textarea readOnly={readOnly} type="text" className="form-control form-control-sm" onChange={(e) => setAddressField(idx, 'observations', e.target.value)} value={el.observations ?? ''} />
									{ errors['addresses.'+idx+'.observations'] &&
										<div className="invalid-feedback d-block">{ errors['addresses.'+idx+'.observations'][0] }</div>
									}
								</div>
							}
						</div>
					</div>
				</LabeledFrame>
			</AddressRow>
		);
	});

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Direcciones de contacto"
			buttons={
				!readOnly ?
					<button className="btn-unstyled text-primary" onClick={() => addAddress()}><i className="bi bi-plus-circle-fill"></i></button>
					:
					null
			}
		>
			{ errors['addresses'] &&
				<div className="invalid-feedback d-block mb-2">{ errors['addresses'][0] }</div>
			}
			{rows}
		</LabeledFrame>
	);
}