import React from 'react';
import LabeledFrame from 'components/LabeledFrame';
import { getCommunity } from 'helpers/community';

export default function PortalAccess(props) {
	const data = props.data;
	const setData = props.setData;
	const errors = props.errors;
	let readOnly = props.readOnly;

	return (
		<LabeledFrame 
			className="mb-3" 
			label="Acceso portal propietarios"
		>
			<div className="row">
				<div className="col-md-6 mb-2">
					<label>Usuario</label>
					<input type="text" className="form-control form-control-sm" value={getCommunity().name_short + '-' + data.vatnumber} onChange={(e) => null} readOnly />
				</div>
				<div className="col-md-6 mb-2">
					<label>Contraseña</label>
					<input readOnly={readOnly} type="text" className="form-control form-control-sm" onChange={(e) => setData({'new_app_password': e.target.value})} placeholder="******" />
					{ errors.new_app_password &&
						<div className="invalid-feedback d-block">{ errors.new_app_password[0] }</div>
					}
				</div>
			</div>	
		</LabeledFrame>
	);
}