import React from 'react';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { getRouterBasename } from 'helpers/config';
import useStore from "store";

import 'app.scss';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';

export default function AppWrapper() {

    const strictMode = useStore(state => state.strictMode);

    let StrictWrapper = null;
    if ( strictMode ) StrictWrapper = React.StrictMode;
    else StrictWrapper = React.Fragment;

	return (
		<StrictWrapper>
			<BrowserRouter basename={getRouterBasename()}>
				<App />
			</BrowserRouter>
		</StrictWrapper>
	);
}


