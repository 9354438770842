import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(1) {
				width: 40px;
			}

			&:nth-child(3),
			&:nth-child(4) {
				width: 150px;
			}

			&:nth-child(5) {
				width: 40px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(4) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Companies() {
	const [queryParams] = useSearchParams();

	let [companies, setCompanies] = useState({});
	let [search, setSearch] = useState(undefined);
	let [status, setStatus] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('id');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setCompanies({...companies, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getCompanies = async () => {
			await axios.get('/api/superadmin/companies/list', {
				params: {
					page: page,
					search: search,
					status: status,
					sort: sortField,
					direction: sortDirection,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setCompanies({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getCompanies();
	}, [page, search, status, sortField, sortDirection]);

	

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Empresas</h1>

					<NavLink to="/companies/add" className="btn btn-sm btn-light ms-auto">Nueva empresa</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setStatus(e.target.value)}>
										<option value="">- Estado -</option>
										<option value="1">Activa</option>
										<option value="0">Inactiva</option>
									</select>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'id'} onClick={() => sortTableClick('id')}>#</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'vatnumber'} onClick={() => sortTableClick('vatnumber')}>CIF</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Fecha</ThSortable>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ companies.data?.length > 0 &&
											companies.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>{el.id}</td>
															<td>
																<div className="d-flex align-items-center">
																	<div style={{width: '65px', minWidth: '65px'}}>
																		{el.status ? <span className="badge bg-success">Activa</span> : <span className="badge bg-danger">Inactiva</span>}
																	</div>
																	<div style={{lineHeight: '90%'}}>
																		{el.name}
																	</div>
																</div>
															</td>
															<td>{el.vatnumber}</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td>
																<NavLink className="btn-table" to={'/companies/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			<div><b className="sbold">Identificador</b>: {el.id}</div>
																			<div><b className="sbold">Subdominio</b>: {el.subdomain}</div>
																			<div><b className="sbold">Estado</b>: {el.status ? <span className="text-success">Activa</span> : <span className="text-danger">Inactiva</span>}</div>
																			<div><b className="sbold">Nombre</b>: {el.name}</div>
																			<div><b className="sbold">CIF</b>: {el.vatnumber}</div>
																			<div><b className="sbold">Dirección</b>: {el.address}</div>
																			<div><b className="sbold">Código postal</b>: {el.postalcode}</div>
																			<div><b className="sbold">Ciudad</b>: {el.city}</div>
																			<div><b className="sbold">Provincia</b>: {el.province}</div>
																			<div><b className="sbold">País</b>: {el.country}</div>
																		</div>
																		<div className="col-md-8">
																			<div><b className="sbold">Persona de contacto</b>: {el.contact_person}</div>
																			<div><b className="sbold">Teléfono</b>: {el.phone}</div>
																			<div><b className="sbold">Email</b>: {el.email}</div>
																			<div><b className="sbold">Fecha registro</b>: { moment(el.created_at).format('DD-MM-YYYY HH:mm') }</div>
																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ companies.data && !companies.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ companies.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={companies?.current_page}
									max={companies?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


