import React, { useState, useEffect } from 'react';
import { useSearchParams, Navigate } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import { getCommunity } from 'helpers/community';
import Skeleton from 'react-loading-skeleton';
import Paginator from 'components/Paginator';
import moment from 'moment';

const FinancialYear = styled.div`
	background: white;
	border-radius: 10px;
	margin-bottom: 20px;
	font-size: 30px;
	line-height: 40px;
	text-align: center;
	color: var(--bs-gray-600);
	padding: 20px;
	border: 1px solid var(--bs-gray-200);
	position: relative;

	${props => props.new ? 'cursor: pointer; background: var(--bs-primary); color: white; font-size: 25px; border: 0;' : ''}

	button {
		position: absolute;
		top: -10px;
		right: -10px;
		background: var(--bs-danger);
		color: white;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 15px;
		border: 0;

	}
`;

let axiosCancelToken = null;

export default function FinancialYears() {
	const [queryParams] = useSearchParams();

	let [forceReload, setForceReload] = useState(null);
	let [financialYears, setFinancialYears] = useState(undefined);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setFinancialYears({...financialYears});
		_setPage(page);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getDataAsync = async() => {
			setFinancialYears(undefined);
			await axios.get('/api/manager/financial-years/list', {
				params: {
					community_id: getCommunity()?.id,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setFinancialYears({...response.data});
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getDataAsync();
	}, [page, forceReload]);

	const newFinancialYear = () => {
		const c = window.confirm('¿Seguro que quieres abrir un nuevo ejercicio fiscal?');
		if ( !c ) return false;

		axios.post('/api/manager/financial-years/add', {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then(() => {
			setForceReload(Math.random());
			setPage(1);
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const deleteFinancialYear = (id) => {
		const c = window.confirm('¿Seguro que quieres eliminar este año fiscal?');
		if ( !c ) return false;

		axios.post('/api/manager/financial-years/delete/' + id, {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then(() => {
			setForceReload(Math.random());
			setPage(1);
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	if ( !getCommunity()?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Ejercicios fiscales</h1>
				</div>

				<div className="page-content">
					<div className="row">
						{ parseInt(moment().format('YYYY')) !== (financialYears?.data[0]?.year ?? null) && 
							<div className="col-md-4">
								<FinancialYear new={true} onClick={newFinancialYear}>
									<i className="bi bi-plus"></i> Abrir nuevo ejercicio	
								</FinancialYear>
							</div>
						}

						{ financialYears?.data.length > 0 &&
							financialYears.data.map((el, idx) => {
								return ( 
									<div key={idx} className="col-md-4">
										<FinancialYear>
											{el.year}
											{ el.is_deletable &&
												<button onClick={() => deleteFinancialYear(el.id)}><i className="bi bi-x"></i></button>
											}			
										</FinancialYear>
									</div>
								);
							})
						}

						{ financialYears && !financialYears.data.length && <div>No hay datos disponibles</div> }

						{ financialYears === undefined && 
							[1,2,3].map((el, idx) => {
								return (
									<div key={idx} className="col-md-4">
										<Skeleton height={100} /> 
									</div>

								);
							})
						}

						<div className="col-md-12 text-center">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={financialYears?.current_page}
									max={financialYears?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


