import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import randomatic from 'randomatic';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

export default function PaymentMethodsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		code: randomatic('A0', 5),
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/payment-methods/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteMethod = () => {
		const c = window.confirm('¿Quieres eliminar este método de pago?');
		if ( !c ) return;

		axios.delete('/api/manager/payment-methods/delete/' + data.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Método de pago borrado')
	    	navigate('/payment-methods');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/payment-methods/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.method});
			if ( goBack ) navigate('/payment-methods');
			else {
		    	navigate('/payment-methods/edit/' + response.data.method.id);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de método de pago</h1>
					<button onClick={() => popup ? window.close() : navigate('/payment-methods')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-6">
											<div className="mb-2">
												<CustomInput label="Código" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('code', e.target.value)} value={data.code ?? ''} />
												{ errors.code &&
													<div className="invalid-feedback d-block">{ errors.code[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-6">
											<div className="mb-2">
												<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteMethod()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}