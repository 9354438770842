import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { getConfig } from 'helpers/config';

const EditorWrapper = styled.div`
	.tox.tox-tinymce {
		${props => !props.border ? 'border: 0 !important;' : ''}
	}
`;	

export default function TextEditor(props) {
    let editorRef = useRef(null);
    let typeRef = useRef(null);

    const text = props.text;
    const setText = props.setText;
    const type = props.type;

    useEffect(() => {
        typeRef.current = type;
    }, [type]);

    return (
        <EditorWrapper border={props.border ?? true}>
            <Editor tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                value={text ?? ''}
                onEditorChange={(newValue) => setText(newValue)}
                init={{
                    // height: 400,
                    min_height: 400,
                    menubar: false,
                    // statusbar: false,
                    resize: true,
                    branding: false,
                    language: 'es',
                    plugins: ['lists', 'autolink', 'link', 'table'],
                    toolbar: 'bold italic | forecolor backcolor | fontsize | lineheight | alignleft aligncenter alignright alignjustify | bullist numlist link | table tabledelete | etiquetas',
                    line_height_formats: "0 0.1 0.5 1 1.1 1.2 1.3 1.4 1.5 2",
                    font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
                    setup: (editor) => {																	
                        editor.ui.registry.addMenuButton('etiquetas', {
                            text: 'Etiquetas',
                            fetch: (callback) => {
                                let items = [];

                                const generateSubMenu = (name, key) => {
                                    return {
                                        type: 'menuitem',
                                        text: name + ' ›',
                                        getSubmenuItems: () => {
                                            let labels = getConfig().templateslabels[key] ?? [];
                                            let subitems = [];

                                            for(let idx in labels) {
                                                let label = labels[idx];
                
                                                subitems.push({
                                                    type: 'menuitem',
                                                    text: label.name,
                                                    onAction: function () {
                                                        editor.insertContent('[' + idx + ']');
                                                    }
                                                });
                                            }

                                            return subitems;
                                        }
                                    };
                                }

                                // Prepare always visible labels
                                let alwaysVisibleLabelsKeys = [
                                    {key: 'general', value: 'General'}, 
                                    {key: 'company', value: 'Empresa'}, 
                                    {key: 'community', value: 'Comunidad'}
                                ];
                                alwaysVisibleLabelsKeys.forEach((el) => {
                                    items.push(generateSubMenu(el.value, el.key));
                                });

                                // Append depending on type
                                if ( typeRef.current === 'properties' ) {
                                	items.push(generateSubMenu('Propiedad', 'property'));
                                }

                                // Append depending on type
                                if ( typeRef.current === 'owners' ) {
                                	items.push(generateSubMenu('Propietario', 'owner'));
                                }

                                // Append depending on type
                                if ( typeRef.current === 'renters' ) {
                                	items.push(generateSubMenu('Inquilino', 'renter'));
                                }

                                // Append depending on type
                                if ( typeRef.current === 'meetings' ) {
                                	items.push(generateSubMenu('Junta', 'meeting'));
                                }
                                
                                callback(items);
                            }
                        });
                    }}
                }
            />
        </EditorWrapper>
    )
}