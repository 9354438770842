import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Linkify from 'linkify-react';
import LabeledFrame from './LabeledFrame';
import { getUser } from 'helpers/user';

const NotesContainer = styled.div`
	
`;

const EditNoteForm = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	background: var(--bs-gray-200);
	padding: 10px;
	border-radius: 3px;

	.user {
		color: var(--bs-primary);
		font-weight: 600;
		margin-top: -3px;
		margin-bottom: 5px;
		font-size: 12px;
	}

	textarea {
		margin-bottom: 10px;
	}

	.bottom {
		display: flex;

		input[type=datetime-local] {
			width: 170px;
			min-width: 170px;
			margin-right: auto;
		}

		.buttons {
			display: flex;
			padding-left: 10px;

			button {
				padding: 2px 10px;

				&:last-of-type {
					margin-left: 10px;
				}
			}

			@media (max-width: 992px) {
				text-align: right;
				flex-direction: column;

				button {
					&:first-of-type {
						margin-right: 0;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
`;

const Note = styled.div`
	position: relative;

	&:not(:last-of-type) {
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid var(--bs-gray-500);
	}

	&:hover {
		.buttons {
			display: flex;
		}
	}

	.buttons {
		display: none;
		position: absolute;
		top: 0;
		right: 0;

		.btn {
			padding: 1px 4px;
			border-radius: 50%;

			&:hover {
				transform: scale(1.2);
				background: var(--bs-gray-200);
			}
		}

		.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
	}

	.date {
		font-size: 10px;
	}

	.user {
		color: var(--bs-primary);
		font-weight: 600;
		font-size: 12px;
	}

	.description {
		font-size: 12px;
	}
`;

export default function Notes(props) {
	const notes = props.notes ? [...props.notes] : [];
	notes.sort((a,b) => { 
		const aDate = moment(a.date);
		const bDate = moment(b.date);

		if ( aDate.isBefore(bDate) ) return 1;
		if ( aDate.isAfter(bDate) ) return -1;
		return 0;
	});
	
	const [showEditActionForm, setShowEditActionForm] = useState(null);
	const readOnly = props.readOnly ?? false;

	const descriptionRef = useRef(null);
	const dateRef = useRef(null);

	const addNote = () => {
		// Empty note
		let newNote = {
			date: null,
			description: '',

			// Users fields are needed to show full data on actions that are not saved on database yet
			user_id: getUser()?.id,
			user: getUser() ?? {}
		};

		// Add
		let newNotes = [...notes];
		newNotes.unshift(newNote);
		props.updateParentCallback(newNotes);

		// Show form
		setShowEditActionForm(0);
	}

	const saveNote = (idx) => {
		// Get
		let date = dateRef.current.value;
		let description = descriptionRef.current.value;

		// Check
		if ( !date || description.replaceAll(' ', '').length === 0 ) return;

		// Set data
		let newNotes = [...notes];
		newNotes[idx].date = date;
		newNotes[idx].description = description;
		
		// Update
		props.updateParentCallback(newNotes);

		// Close form
		setShowEditActionForm(null);
	}

	const deleteNote = (idx) => {
		let c = window.confirm('¿Seguro que quieres eliminar esta nota?');
		if ( !c ) return false;

		// Remove
		let newNotes = [...notes];
		if ( newNotes[idx].id ) newNotes[idx].remove = true;
		else newNotes.splice(idx, 1);

		// Update
		props.updateParentCallback(newNotes);

		// Hide form if is showed
		setShowEditActionForm(null);
	}

	// Set focus on opened form
	useEffect(() => {
		if ( showEditActionForm !== null && descriptionRef.current ) {
			descriptionRef.current.focus();
		}
	}, [showEditActionForm]);

	return (
		<LabeledFrame 
			className={props.className ?? ''}
			label={props.title ?? 'Notas'}
			buttons={
				!readOnly ?
					<React.Fragment>
						<button className="btn-unstyled text-primary" onClick={() => showEditActionForm === null ? addNote() : null}>
							<i className="bi bi-plus-circle-fill"></i>
						</button>
					</React.Fragment>
				:
				null
			}
		>
			<NotesContainer>
				{ notes?.length > 0 &&
					<React.Fragment>
						{notes.map((el, idx) => {
							if ( el.remove ) return false;

							return (
								<React.Fragment key={idx}>
									{ 
										showEditActionForm === idx ?
											<EditNoteForm>
												<div className="user">{el.user?.name}</div>
												<textarea className="form-control form-control-sm" ref={descriptionRef} defaultValue={el.description ?? ''} placeholder={'Escribe aquí...'} maxLength="1000"></textarea>
												<div className="bottom">
													<input type="datetime-local" className="form-control form-control-sm" ref={dateRef} defaultValue={moment(el.date ? el.date : new Date()).format('YYYY-MM-DDTHH:mm')} />
													<div className="buttons">
														{ (idx === 0 && !el.description.length) &&
															<button className="btn btn-sm btn-secondary text-white" onClick={() => deleteNote(idx)}>Cancelar</button>
														}
														<button className="btn btn-sm btn-primary text-white" onClick={() => saveNote(idx)}>Aceptar</button>
													</div>
												</div>
											</EditNoteForm>
										:
											<Note>
												<div className="buttons">
													<button className={'btn btn-sm btn-link me-3 ' + ((el.user_id !== getUser()?.id || readOnly) ? 'disabled ' : '')} onClick={() => setShowEditActionForm(idx)}><i className="bi bi-pencil"></i></button>
													<button className={'btn btn-sm btn-link text-danger ' + (readOnly ? 'disabled' : '')} onClick={() => deleteNote(idx)}><i className="bi bi-trash"></i></button>
												</div>
												
												<div className="date">{moment(el.date).format('DD-MM-YYYY')} a las {moment(el.date).format('HH:mm')}</div>
												<div className="user">{el.user?.name}</div>
												<div className="description">
													<Linkify options={{target: '_blank'}}>{el.description}</Linkify>
												</div>
											</Note>
									}
								</React.Fragment>
							);
						})}
					</React.Fragment>
				}
			</NotesContainer>
		</LabeledFrame>
	);
}