const setToSession = (key, value) => {
    let toSave = value;
    if ( toSave instanceof Object ) toSave = JSON.stringify(value);
    sessionStorage.setItem(key, toSave);
};

const getFromSession = (key) => {
    let data = sessionStorage.getItem(key);
    
    try {
        data = JSON.parse(data);
    } catch (e) {}

    return data;
}

const appendToSession = (key, newData) => {
    let data = getFromSession(key);

    if ( !data ) {
        if ( typeof newData === 'object' ) {
            data = {...newData};
        }

        if ( typeof newData === 'string' ) {
            data = newData;
        }
    }
    
    if ( typeof data === 'object' ) {
        data = {...data, ...newData};
    }

    if ( typeof data === 'string' ) {
        data += newData;
    }

    setToSession(key, data);
}

const removeFromSession = (key) => {
    sessionStorage.removeItem(key);
}

export { setToSession, getFromSession, removeFromSession, appendToSession };