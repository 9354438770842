import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { openPopupWindow } from 'helpers/generic';
import { getCommunity } from "helpers/community";
import { getRouterBasename } from "helpers/config";
import Receipt from './Receipt';
import ReceiptsRefund from './ReceiptsRefund';
import ProviderInvoice from './ProviderInvoice';
import ClientInvoice from './ClientInvoice';
import NewProviderInvoice from './NewProviderInvoice';
import NewClientInvoice from './NewClientInvoice';
import CounterParts from './CounterParts';
import AccountDelivery from './AccountDelivery';
import BankRemittance from './BankRemittance';
import NewManualReceipt from './NewManualReceipt';

const ModalStyled = styled.div`
	background: rgba(0,0,0, 0.7);
`;

const NoTypeSelected = styled.div`
	& > div {
		display: flex;

		button {
			width: 50%;
		}
	}

	button {
		margin: 10px;
		padding: 10px;
		font-size: 20px;
		line-height: 20px;

		small {
			display: block;
			font-size: 13px;
			line-height: 13px;
			margin-top: 10px;
		}
	}
`;

let axiosCancelToken = null;

let newProviderInvoiceWindow = null;
let newClientInvoiceWindow = null;
let newManualReceiptWindow = null;

export default function ReconciliationModal(props) {
	const modalRef = useRef(null);

	const movements = props.movements;
	const closeCallback = props.closeCallback;
	const [type, setType] = useState('');
	const [unselectSelectedMovements, setUnselectSelectedMovements] = useState(false);
	const [showSkeletonOnHide, setShowSkeletonOnHide] = useState(false);
	
	useEffect(() => {
		let type = '';
		movements.forEach((el) => {
			if ( el.class === 'App\\Models\\ProviderInvoice' ) {
				type = 'provider-invoice';
				return;
			}
			if ( el.class === 'App\\Models\\ClientInvoice' ) {
				type = 'client-invoice';
				return;
			}
			if ( el.class === 'App\\Models\\Receipt' ) {
				type = 'receipt';
				return;
			}
			if ( el.class === 'App\\Models\\BankRemittance' ) {
				type = 'bankremittance';
				return;
			}
		});
		setType(type);
	}, [movements]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
			if ( e.target !== modal._element ) return; // Fix to avoid close when a child modal is closed

           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();

			closeCallback(unselectSelectedMovements, showSkeletonOnHide);

			// Close new invoice window if modal is closed and new window is opened
			if ( newProviderInvoiceWindow && !newProviderInvoiceWindow.closed ) newProviderInvoiceWindow.close();
			if ( newClientInvoiceWindow && !newClientInvoiceWindow.closed ) newClientInvoiceWindow.close();
			if ( newManualReceiptWindow && !newManualReceiptWindow.closed ) newManualReceiptWindow.close();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);
		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback, unselectSelectedMovements, showSkeletonOnHide]);

	const hideModal = (unselectSelectedMovements = false, showSkeletonOnHide = false) => {
		setUnselectSelectedMovements(unselectSelectedMovements);
		setShowSkeletonOnHide(showSkeletonOnHide);

		// Fix on timeout because we need to execute this after render
		setTimeout(() => {
			const modal = Modal.getInstance(modalRef.current);
			if ( modal ) modal.hide();
		}, 100);
	}

	const newProviderInvoice = () => {
		let bankmovement = movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0] ?? null; 

		newProviderInvoiceWindow = openPopupWindow(
			getRouterBasename() + '/providers-invoices/add?popup=true' + 
			'&community_id=' + getCommunity()?.id + 
			'&date_reception=' + moment(bankmovement.date).format('YYYY-DD-MM') +
			'&amount=' + (bankmovement.amount * -1) +
			'&concept=' + bankmovement.concept +
			'&hidden_sections=save_button'
		);
		setType('new-provider-invoice');
	}

	const newClientInvoice = () => {
		let bankmovement = movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0] ?? null; 

		newClientInvoiceWindow = openPopupWindow(
			getRouterBasename() + '/clients-invoices/add?popup=true' + 
			'&community_id=' + getCommunity()?.id + 
			'&date_reception=' + moment(bankmovement.date).format('YYYY-MM-DD')
		);
		setType('new-client-invoice');
	}

	const newManualReceipt = () => {
		let bankmovement = movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0] ?? null; 

		newManualReceiptWindow = openPopupWindow(
			getRouterBasename() + '/receipts/manual-add?popup=true' + 
			'&due_date=' + moment(bankmovement.date).format('YYYY-MM-DD') +
			'&mandatory_amount=' + bankmovement.amount
		);
		setType('new-manual-receipt');
	}

	const newAccountDelivery = () => {
		setType('account-delivery');
	}

	let notSingleBankMovement = movements.filter((item) => item.class.indexOf('BankMovement') !== -1).length !== 1;
	let positiveAmount = (movements.filter((item) => item.class.indexOf('BankMovement') !== -1)[0].amount ?? 0) > 0;

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className={'modal-dialog modal-' + (type?.length > 0 ? 'lg' : 'md')}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Conciliación de movimientos seleccionados</h5>
						<button type="button" className="btn-close" onClick={() => hideModal(false, false)}></button>
					</div>
					<div className="modal-body">
						{ type === '' && 
							<NoTypeSelected> 
								<div>
									<button className="btn btn-sm btn-primary text-white" onClick={() => newProviderInvoice()}>Crear factura de proveedor</button>
									<button className="btn btn-sm btn-primary text-white" onClick={() => newClientInvoice()}>Crear factura de cliente</button>
								</div>
								<div>
									<button 
										className="btn btn-sm btn-primary text-white" 
										onClick={() => setType('counterparts')}
										disabled={notSingleBankMovement}
									>
										Seleccionar contrapartida
										{notSingleBankMovement && <small>(solo un movimiento<br />bancario a la vez)</small>}
									</button>
									<button className="btn btn-sm btn-primary text-white" onClick={() => newAccountDelivery()}>Entrega a cuenta<small>(pago de deuda)</small></button>
								</div>
								<div>
									<button className="btn btn-sm btn-primary text-white" onClick={() => newManualReceipt()}>Crear recibo manual</button>

									<button 
										className="btn btn-sm btn-primary text-white" 
										onClick={() => setType('receipts-refund')}
										disabled={notSingleBankMovement || positiveAmount}
									>
										Devolver recibos
										{notSingleBankMovement && <small>(solo un movimiento<br />bancario a la vez)</small>}
										{positiveAmount && <small>(solo movimientos con saldo negativo)</small>}
									</button>
								</div>
							</NoTypeSelected> 	
						}

						{ type === 'new-manual-receipt' && <NewManualReceipt movements={movements} hideModal={hideModal} /> }
						{ type === 'receipt' && <Receipt movements={movements} hideModal={hideModal} /> }
						{ type === 'receipts-refund' && <ReceiptsRefund movements={movements} hideModal={hideModal} /> }
						{ type === 'provider-invoice' && <ProviderInvoice movements={movements} hideModal={hideModal} /> }
						{ type === 'client-invoice' && <ClientInvoice movements={movements} hideModal={hideModal} /> }
						{ type === 'new-provider-invoice' && <NewProviderInvoice movements={movements} hideModal={hideModal} /> }
						{ type === 'new-client-invoice' && <NewClientInvoice movements={movements} hideModal={hideModal} /> }
						{ type === 'counterparts' && <CounterParts movements={movements} hideModal={hideModal} /> }
						{ type === 'bankremittance' && <BankRemittance movements={movements} hideModal={hideModal} /> }
						{ type === 'account-delivery' && <AccountDelivery movements={movements} hideModal={hideModal} /> }
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


