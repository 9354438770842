import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams, NavLink } from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import LabeledFrame from 'components/LabeledFrame';
import { CristalLoader } from 'helpers/generic';
import { getRouterBasename } from 'helpers/config';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { openPopupWindow } from 'helpers/generic';
import Items from './Items';
import Payments from './Payments';
import { downloadFile } from 'helpers/generic';
import CustomInput from 'components/CustomInput';
import ActionsContextMenu from 'components/ActionsContextMenu';

const File = styled.div`
	position: relative;
	height: 30px;
	display: flex;
	align-items: center;

	button {
		position: absolute;
		top: -10px;
		right: -5px;
		padding: 0;
		margin: 0;
		color: var(--bs-red);
		border: 0;
	}
`;

let axiosCancelToken = null;

// Get proxy callback from parent opener to make updates if possible
let parentWindowProxyCallback = window.opener?.PopupProxyCallback;
let parentWindowProxyCallbackClientInvoices = window.opener?.PopupProxyCallbackClientInvoices;

export default function ClientsInvoiceForm(props) {
	let actionsDropdownRef = useRef(null);

	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const fileRef = useRef(null);

	const popup = queryParams.get('popup') === 'true';
	const clientId = queryParams.get('client_id') ?? null;
	const communityId = queryParams.get('community_id') ?? null;
	const pendinginvoiceId = queryParams.get('pendinginvoice_id') ?? null;
	const dateReception = queryParams.get('date_reception') ?? null;
	const forceCanEdit = queryParams.get('edit') === 'true';

	let [data, setData] = useState({
		date_reception: moment().format('YYYY-MM-DD'),
		community_id: getCommunity()?.id,
		items: [],
		base: 0,
		total: 0,
		file: null
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);
	let [canEdit, setCanEdit] = useState(true);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		window.onbeforeunload = () => {
			parentWindowProxyCallback(data);
			parentWindowProxyCallbackClientInvoices(data);
		};
	}, [data]);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/clients-invoices/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
					if ( !forceCanEdit ) setCanEdit(false);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const getDataFromQueryString = async () => {
				let newData = {};

				// Client ID from query string
				if ( clientId ) {
					await axios.get('/api/manager/clients/get/' + clientId, {
						params: {
							community_id: getCommunity()?.id
						},
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						newData.client = {
							id: response.data.id, 
							name: response.data.name
						};
						newData.client_id = response.data.id;
				  	}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});
			  	}

				// Community ID from query string
				if ( communityId ) {
					await axios.get('/api/manager/communities/get/' + communityId, {
						params: {},
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						newData.community_id = response.data.id;
				  	}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});
			  	}

				// Date reception
				if ( dateReception ) {
					newData.date_reception = dateReception;
				}

			  	setData((prev) => ({...prev, ...newData}));
			}
			getDataFromQueryString();
		}
	}, [params.id, setData, communityId, clientId, dateReception, forceCanEdit]);

	const deleteInvoice = () => {
		const c = window.confirm('¿Quieres eliminar esta factura?');
		if ( !c ) return;

		axios.delete('/api/manager/clients-invoices/delete/' + data.id + '?community_id=' + data.community_id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.info('Factura borrada');
			
			if ( popup ) {
				window.close();
				if ( parentWindowProxyCallbackClientInvoices ) parentWindowProxyCallbackClientInvoices();
			} else navigate('/incomes');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		setLoading(true);

		let formData = new FormData();
		for(let i in data) {
			if ( data[i] === undefined || data[i] === null ) continue;
			formData.append(i, data[i]);
		}
		formData.set('items', JSON.stringify(data.items));
		if ( pendinginvoiceId ) formData.set('pendinginvoice_id', pendinginvoiceId);

		axios.post('/api/manager/clients-invoices/' + (data.id ? 'edit/' + data.id : 'add'), formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: axiosCancelToken.token,
		}).then((response) => {
			setData({...response.data.invoice});
			toast.success('Datos guardados');
			if ( goBack ) {
				if ( popup ) {
					data = response.data.invoice; // Fix: prevent send incompleted data when popup is closed. Not a good way, but it solves the problem.
					window.close();
				} else navigate('/incomes');
			} else if ( !data.id ) {
		    	navigate('/clients-invoices/edit/' + response.data.invoice.id + '?edit=true' + (popup ? '&popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		}).then(() => {
			setLoading(false);
		});
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadClients = (input, callback) => {
		axios.get('/api/manager/clients/list', {
			params: {
				search: input,
				no_paginate: true,
				community_id: getCommunity()?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectClient = (client) => {
		setData((prev) => ({
			...prev, 
			client: {...client},
			client_id: client?.id
		}));
	}

	const loadPaymentMethods = (input, callback) => {
		axios.get('/api/manager/payment-methods/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectPaymentMethod = (paymentmethod) => {
		setData({
			...data, 
			paymentmethod: {...paymentmethod},
			paymentmethod_id: paymentmethod?.id
		});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}
 
	const onDropHandler = (e) => {
		e.preventDefault();

		let file = e.dataTransfer.files[0];
		setData({...data, file: file});
	}

	const openFileInput = () => {
		if ( !canEdit ) return;

		setData({...data, file: null});
		fileRef.current.value = null;
		fileRef.current.click();
	}

	const fileInputChange = (e) => {
		let file = e.target.files[0];
		setData({...data, file: file});
	}

	const removeFile = (e) => {
		if ( !canEdit ) return;

		e.stopPropagation();
		setData({...data, file: null});
	}

	const viewFile = (e, file) => {
		e.preventDefault();

		const baseUrl = getRouterBasename() + '/documents/view-file/';
		if ( file.viewable ) return openPopupWindow(baseUrl + file.id);

		return downloadFile('/api/manager/documents/download-file/' + file.id);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section
				onDrop={onDropHandler}
				onDragOver={(e) => e.preventDefault()}
			>
				<div className="page-title">
					<h1>Facturas emitidas</h1>
					<button onClick={() => popup ? window.close() : navigate('/incomes')} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<LabeledFrame label="Datos básicos" className="mb-3 mb-md-0">
												<div className="row">
												<div className="col col-static-120">
														<div className="mb-2">
															<CustomInput 
																label="Nº documento" 
																type="text" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('number', e.target.value)} 
																value={data.number ?? ''} 
																readOnly={!canEdit} 
															/>
															{ errors.number &&
																<div className="invalid-feedback d-block">{ errors.number[0] }</div>
															}
														</div>
													</div>
													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																label="Fecha factura" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('date', e.target.value)} 
																value={data.date ?? ''} 
																readOnly={!canEdit} 	
															/>
															{ errors.date &&
																<div className="invalid-feedback d-block">{ errors.date[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-150">
														<div className="mb-2">
															<CustomInput 
																readOnly={!canEdit} 
																label="Fecha contable" 
																type="date" 
																className="form-control form-control-sm" 
																onChange={(e) => setDataField('date_reception', e.target.value)} 
																value={data.date_reception ?? ''} 
															/>
															{ errors.date_reception &&
																<div className="invalid-feedback d-block">{ errors.date_reception[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-165">
														<div className="mb-2">
															<EmpoweredSelector
																load={(input, callback) => loadPaymentMethods(input, callback)}
																onChange={(value) => selectPaymentMethod(value)}
																timeout={250}
																label={data.paymentmethod?.name ?? ''}
																placeholder="Forma de pago"
																showPlaceholderHelper={true}
																value={data.paymentmethod?.id}
																disabled={!canEdit} 
															/>
															{ errors.paymentmethod_id &&
																<div className="invalid-feedback d-block">{ errors.paymentmethod_id[0] }</div>
															}
														</div>
													</div>	
													<div className="col col-static-250">
														<div className="mb-2">
															<div className="input-group">
																<EmpoweredSelector
																	load={(input, callback) => loadClients(input, callback)}
																	onChange={(value) => selectClient(value)}
																	timeout={250}
																	label={
																		<div>
																			{data.client?.name ?? ''}&nbsp;
																			{data.client?.id &&
																				<NavLink className="text-decoration-none text-reset" to={'/clients/edit/' + data.client.id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
																			}
																		</div>
																	}
																	placeholder="Cliente"
																	showPlaceholderHelper={true}
																	value={data.client?.id}
																	disabled={!canEdit} 
																/>
																<div className="input-group-append">
																	<NavLink className="btn btn-sm btn-light2 text-secondary" to={'/clients/add?popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-plus-circle-fill"></i></NavLink>
																</div>
															</div>
															{ errors.client_id &&
																<div className="invalid-feedback d-block">{ errors.client_id[0] }</div>
															}
														</div>
													</div>	
												</div>	
											</LabeledFrame>
										</div>
										<div className="col-md-6">
											<div className="row">
												<LabeledFrame label="Otros datos">
													<div className="row">
													<div className="col col-static-250">
															<div className="mb-2">
																<CustomInput 
																	label="Descripción" 
																	type="text" 
																	className="form-control form-control-sm" 
																	onChange={(e) => setDataField('description', e.target.value)} 
																	value={data.description ?? ''}
																	readOnly={!canEdit} 
																/>
																{ errors.description &&
																	<div className="invalid-feedback d-block">{ errors.description[0] }</div>
																}
															</div>
														</div>
														<div className="col col-static-180">
															<div className="mb-2">
																<File className="form-control form-control-sm" style={{cursor: 'pointer'}} onClick={(e) => !data.file?.id ? openFileInput(e) : viewFile(e, data.file)}>
																	{ data.file?.id && 
																		<React.Fragment>
																			<button disabled={!canEdit} className="btn p-0" onClick={removeFile}><i className="bi bi-x-circle-fill"></i></button>
																			<i className="bi bi-file-fill text-primary me-1"></i>
																		</React.Fragment>
																	}
																	{ data.file ? data.file.name : 'Seleccionar archivo' }
																</File>
																{ errors.file &&
																	<div className="invalid-feedback d-block">{ errors.file[0] }</div>
																}
																<input type="file" ref={fileRef} className="d-none" onChange={fileInputChange}  />
															</div>
														</div>
													</div>	
												</LabeledFrame>
											</div>
										</div>	
										<div className="col-md-12 mt-3">
											<Items
												data={data}
												setData={setData}
												errors={errors}
												readOnly={!canEdit}
											/>
										</div>
										<div className="col-md-6 mt-3">
											<Payments
												data={data}
											/>
										</div>
									</div>
								</div>
								<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
									<div className="row">
										<div className="col-6">
											{ (data.id && authUserPermission('delete', data.community)) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteInvoice()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ ((authUserPermission('add', data.community) && !data.id) || (authUserPermission('edit', data.community) && data.id)) &&
												<React.Fragment>
													{ data.id &&
														<div className="dropdown d-inline">
															<button ref={actionsDropdownRef} className="btn btn-sm btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
																Acciones
															</button>
															<ActionsContextMenu actionsDropdownRef={actionsDropdownRef} className="dropdown-menu">
																<li><button className="dropdown-item" onClick={() => setCanEdit(!canEdit)}>{canEdit ? 'Desactivar edición' : 'Editar'}</button></li>
																
																<li><hr className="dropdown-divider" /></li>
																<li><button className="dropdown-item" onClick={() => saveData(false)}>Guardar</button></li>						
																<li><button className="dropdown-item" onClick={() => saveData()}>Guardar y salir</button></li>
															</ActionsContextMenu>
														</div>
													}

													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>
												</React.Fragment>
											}							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}