import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { getCommunity } from 'helpers/community';
import { formatNumber } from 'helpers/generic';

let axiosCancelToken = null;

export default function CommunitySituation() {
	let [treasuryBalance, setTreasuryBalance] = useState(0);
	let [morosidadBalance, setMorosidadBalance] = useState(0);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getData = async () => {
			axios.get('/api/manager/treasury/list', {
				params: {
					no_paginate: true,
					community_id: getCommunity()?.id,
					with_balance: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setTreasuryBalance(response.data.reduce((carry, item) => carry+=item.contableaccount?.balance, 0));
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	

			axios.get('/api/manager/properties/list', {
				params: {
					no_paginate: true,
					community_id: getCommunity()?.id,
					only_morosos: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setMorosidadBalance(response.data.reduce((carry, item) => carry+=item.receiptscontableaccount?.balance, 0));
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getData();
	}, []);

	return (
		<React.Fragment>
			<div className="card border-0 shadow-sm mb-3 mt-3">
				<div className="card-header bg-white p-3">
					<div className="row">
						<div className="col-md-12 mb-2 mt-2 mb-md-0 mt-md-0">
							<h5 className="card-title mb-0 text-primary d-flex h-100 align-items-center justify-content-center">Situación de la comunidad</h5>
						</div>
					</div>
				</div>
				<div className="card-body">
					<div className="row">
						<div className="col-md-6">
							<NavLink 
								to={'/treasury'} 
								className="col-sm-4 text-center text-decoration-none" 
							>
								<h6 className="text-secondary mb-0">Saldo tesorería</h6>
								<h3 className="text-secondary mb-0 sbold">{formatNumber(treasuryBalance, 2)} €</h3>
							</NavLink>
						</div>
						<div className="col-md-6">
							<NavLink 
								to={'/properties?show_morosos=true'} 
								className="col-sm-4 text-center text-decoration-none" 
							>
								<h6 className="text-secondary mb-0">Morosidad</h6>
								<h3 className="text-secondary mb-0 sbold">{formatNumber(morosidadBalance, 2)} €</h3>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}


