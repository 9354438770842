import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const Tr = styled.tr`
	&:hover {
		* {
			--bs-table-accent-bg: inherit !important; // Prevent hover background for tablehover
		}
	}
`

export default function TrSkeleton(props) {
	let skeleton = [];
	for(let i=0; i<props.rows; i++) {
		let skeletonRow = [];
		for(let e=0; e<props.columns; e++) skeletonRow.push(<td key={e}><Skeleton /></td>);
		skeleton.push(<Tr key={i}>{ skeletonRow }</Tr>);
	}

	return skeleton;
}