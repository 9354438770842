import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import LabeledFrame from 'components/LabeledFrame';
import CustomInput from 'components/CustomInput';

const MovementsTable = styled.table`
	font-size: 13px;

	th,td {
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4) {
			position: relative;

			input {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
				background: transparent;
				outline: none;
				padding-right: 8px;
				padding-left: 8px;

				&[type=number] {
					text-align: right;
				}
			}
		}

		&:nth-child(3),
		&:nth-child(4) {
			text-align: right;
			width: 80px;
		}


		&:nth-child(5) {
			width: 30px;
			text-align: center;
		}
	}

	th {
		font-weight: 500;

		&:first-of-type {
			width: 300px;
		}
	}

	td {
		&:first-of-type {
			padding: 0;

			.empowered-selector {

				.empowered-selector_label {
					border: 0;
					background: transparent;
				}

				.empowered-selector_menu {
					box-shadow: 0px 2px 5px var(--bs-gray-300);
				}
			}
		}

		&.has-errors {
			background: var(--bs-danger);

			.empowered-selector_label,
			input[type=number] {
				color: white;
			}
		}
	}
`;

let axiosCancelToken = null;

const movementTemplate = {
	contableacccount: null,
	contableaccount_id: null,
	description: '',
	amount: ''
};

export default function AccountingEntriesForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		date: moment().format('YYYY-MM-DD'),
		movements: [

		]
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/accounting-entries/get/' + params.id, {
					params: {
						community_id: getCommunity()?.id
					},
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData((data) => ({
			    		...data, 
			    		...response.data, 
			    		movements: response.data.movements?.map((el) => {
			    			return {
			    				contableaccount: el,
			    				contableaccount_id: el.id,
			    				amount: el.pivot?.amount,
			    				description: el.pivot?.description
			    			}
			    		})
			    	}));
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const loadContableAccounts = (input, callback) => {
		axios.get('/api/manager/contable-accounts/list-ordered', {
			params: {
				community_id: getCommunity()?.id,
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: 	<div style={{paddingLeft: (el.level * 5) + 'px'}}>
								<b className="me-1">{el.code}</b>  
								<span>{el.name}</span>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const deleteEntry = () => {
		const c = window.confirm('¿Quieres eliminar este asiento contable?');
		if ( !c ) return;

		axios.post('/api/manager/accounting-entries/delete/' + data.id, {
			community_id: getCommunity()?.id,
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Asiento borrado')
	    	navigate('/accounting-entries');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		data.community_id = getCommunity()?.id;

		axios.post('/api/manager/accounting-entries/' + (data.id ? 'edit/' + data.id : 'add'), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData((data) => ({
	    		...data, 
	    		...response.data.entry, 
	    		movements: response.data.entry.movements?.map((el) => {
	    			return {
	    				contableaccount: el,
	    				contableaccount_id: el.id,
	    				amount: el.pivot?.amount,
	    				description: el.pivot?.description
	    			}
	    		})
	    	}));

			if ( popup && goBack ) window.close();

			if ( goBack ) navigate('/accounting-entries');
			else {
		    	navigate('/accounting-entries/edit/' + response.data.entry.id + (popup ? '?popup=true' : ''));
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const addMovement = () => {
		let movements = [...data.movements];
		movements.push({...movementTemplate});
		setDataField('movements', movements);
	}

	const deleteMovement = (idx) => {
		let movements = [...data.movements];
		movements.splice(idx, 1);
		setDataField('movements', movements);
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setMovementDataField = (idx, field, value) => {
		let movements = [...data.movements];
		movements[idx][field] = value;
		setDataField('movements', movements);
	}

	const selectMovementContableAccount = (idx, value) => {
		let movements = [...data.movements];
		movements[idx].contableaccount = value;
		movements[idx].contableaccount_id = value?.id;
		setDataField('movements', movements);
	}

	const setMovementAmount = (idx, multiplier, amount) => {
		amount = parseFloat(amount) * multiplier;
		setMovementDataField(idx, 'amount', amount);
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de asiento contable</h1>
					<button onClick={() => navigate('/accounting-entries')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Fecha" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} />
												{ errors.date &&
													<div className="invalid-feedback d-block">{ errors.date[0] }</div>
												}
											</div>
										</div>
										{ data.id &&
											<div className="col-lg-3">
												<div className="mb-2">
													<CustomInput label="Nº" type="number" className="form-control form-control-sm no-arrows" value={data.number ?? ''} readOnly />
												</div>
											</div>
										}
										<div className="col-lg-12 mt-2">
											<LabeledFrame 
												label="Movimientos"
												buttons={
													<button className="btn-unstyled text-white p-0 m-0" onClick={addMovement}><i className="bi bi-plus-circle"></i> Nuevo</button>
												}
												buttonsBackground={'primary'}
											>
												<MovementsTable className="table table-sm table-bordered mb-0">
													<thead>
														<tr>
															<th>Cuenta contable</th>
															<th>Descripción</th>
															<th>Debe</th>
															<th>Haber</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{data.movements?.map((el, idx) => {
															return (
																<tr key={idx}>
																	<td>
																		<EmpoweredSelector
																			load={(input, callback) => loadContableAccounts(input, callback)}
																			onChange={(value) => selectMovementContableAccount(idx, value)}
																			timeout={250}
																			label={
																				<div>
																					<b>{el.contableaccount?.code}</b>&nbsp;{el.contableaccount?.name}
																				</div>
																			}
																			placeholder="Seleccionar"
																			value={el.id}
																		/>
																	</td>
																	<td><input type="text" placeholder="Escribe aquí..." onChange={(e) => setMovementDataField(idx, 'description', e.target.value)} value={el.description} /></td>
																	<td><input type="number" className="no-arrows" onChange={(e) => setMovementAmount(idx, -1, e.target.value)} value={el.amount < 0 ? Math.abs(el.amount) : ''} /></td>
																	<td><input type="number" className="no-arrows" onChange={(e) => setMovementAmount(idx, 1, e.target.value)} value={el.amount > 0 ? el.amount : ''} /></td>
																	<td>
																		<button className="btn-unstyled text-danger" onClick={() => deleteMovement(idx)}><i className="bi bi-x-circle-fill"></i></button>
																	</td>
																</tr>
															);
														})}
														{data.movements?.length === 0 && <tr><td colSpan="100%" className="p-2">No hay movimientos</td></tr>}
													</tbody>
													<tfoot>
														<tr>
															<td></td>
															<td className="text-end sbold px-2">Total</td>
															<td className="text-end sbold px-2">
																{data.movements?.reduce((carry, item) => carry += parseFloat(item.amount) < 0 ? Math.abs(item.amount) : 0, 0)}
															</td>
															<td className="text-end sbold px-2">
																{data.movements?.reduce((carry, item) => carry += parseFloat(item.amount) > 0 ? parseFloat(item.amount) : 0, 0)}
															</td>
															<td></td>
														</tr>
													</tfoot>
												</MovementsTable>
											</LabeledFrame>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6">
											{ (data.id && data.is_deletable) &&
												<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteEntry()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ (!data.id || (data.id && data.is_editable)) &&
												<React.Fragment>
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
													<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>			
												</React.Fragment>
											}				
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}