import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import EmpoweredSelector from 'components/EmpoweredSelector';
import TrSkeleton from 'components/TrSkeleton';
import { openPopupWindow, formatNumber } from 'helpers/generic';
import { getUser } from 'helpers/user';
import { getRouterBasename } from 'helpers/config';

const Table = styled.table`
	width: 100%;
	font-size: 12px;
	margin-bottom: 0;

	td,th {
		vertical-align: middle;

		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6) {
			width: 50px;
			text-align: center;
		}

		&:nth-child(2) {
			color: var(--bs-primary);
		}

		&:nth-child(3) {
			color: var(--bs-orange);
		}

		&:nth-child(4) {
			color: var(--bs-danger);
		}
		&:nth-child(5) {
			color: var(--bs-success);
		}
	}

	th {
		font-weight: 600;
	}

	td {
		&:not(:nth-child(1)) {
			font-size: 15px;
			cursor: pointer;

			&:hover {
				background: var(--bs-gray-100);
			}
		}
	}
`;

let axiosCancelToken = null;

const TYPES = [
	{ id: 'notices', name: 'Avisos' },
	{ id: 'noticesbyme', name: 'Avisos creados por mi' },
	{ id: 'internaltasks', name: 'Tareas internas' },
	{ id: 'internaltasksbyme', name: 'Tareas internas creadas por mi' },
	{ id: 'meetingagreements', name: 'Acuerdos de junta' },
	{ id: 'meetingagreementsbyme', name: 'Acuerdos de junta creados por mi' },
];

export default function Notices() {
	// let [tab, setTab] = useState('notices');
	let [count, setCount] = useState(undefined);
	let [withPendingInvoices, setWithPendingInvoices] = useState([]);
	let [forceReload, setForceReload] = useState(null);

	let [community, setCommunity] = useState({
		id: null,
		name: null,
	});
	let [user, setUser] = useState({
		id: !getUser()?.disable_filter_notices ? getUser()?.id : null,
		name: !getUser()?.disable_filter_notices ? getUser()?.name : null
	});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallbackSecondary = () => {
			setForceReload(Math.random());
		}
	}, []);

	useEffect(() => {
		setCount(undefined);
		const getCount = () => {
			return axios.get('/api/manager/notices/get-count-resume', {
				params: {
					community_id: community?.id,
					user_id: user?.id,
					opener_id: getUser()?.id,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setCount(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getCount();
	// }, [forceReload, community, user, tab]);
	}, [forceReload, community, user]);

	useEffect(() => {
		const getWithPendingInvoices = async () => {
			await axios.get('/api/manager/notices/list', {
				params: {
					no_paginate: true,
					community_id: community?.id,
					user_id: user?.id,
					with_pending_invoices: true,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setWithPendingInvoices([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getWithPendingInvoices();
	}, [forceReload, community, user]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	return (
		<div className="card border-0 shadow-sm">
			<div className="card-header bg-white p-3">
				<div className="row align-items-center">
					<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
						<h5 className="card-title mb-0 text-primary d-flex h-100 align-items-center justify-content-center">Avisos</h5>
					</div>
					<div className="col-md-6 mb-2 mt-2 mb-md-0 mt-md-0">
						<EmpoweredSelector
							load={loadCommunities}
							onChange={(value) => setCommunity({id: value?.id, name: value?.name})}
							timeout={250}
							label={community?.name ?? ''}
							placeholder="Todas las comunidades"
							value={community?.id}
						/>
					</div>
					<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
						<EmpoweredSelector
							load={loadUsers}
							onChange={(value) => setUser({id: value?.id, name: value?.name})}
							timeout={250}
							label={user?.name ?? ''}
							placeholder="Todos los usuarios"
							value={user?.id}
						/>
					</div>
					<div className="col-md-1 mb-2 mt-2 mb-md-0 mt-md-0">
						<NavLink to="/notices/add" className="btn btn-primary text-white btn-sm w-100" onClick={openPopupInfoWindow}><i className="bi bi-plus-circle"></i></NavLink>
					</div>
				</div>
			</div>
			<div className="card-body">
				<div className="mb-0">
					<Table className="table table-sm table-bordered mb-0">
						<thead>
							<tr>
								<th></th>
								<th>Pendientes</th>
								<th>Progreso</th>
								<th>Vencidos</th>
								<th>Terminados</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{TYPES.map((el, idx) => {
								let url = getRouterBasename() + '/notices?community_id=' + community?.id + '&user_id=' + (el.id.indexOf('byme') !== -1 ? user?.id : 'null') + (el.id.indexOf('byme') !== -1 ? '&opener_id=' + getUser()?.id : '') + (el.id.indexOf('internaltasks') !== -1 ? '&internal_task=true' : '');

								return (
									<tr key={idx}>
										<td>{el.name}</td>
										<td onClick={() => openPopupWindow(url + '&status=pending')}>{count && count[el.id]?.pending}</td>
										<td onClick={() => openPopupWindow(url + '&status=progress')}>{count && count[el.id]?.progress}</td>
										<td onClick={() => openPopupWindow(url + '&status=expired')}>{count && count[el.id]?.expired}</td>
										<td onClick={() => openPopupWindow(url + '&status=closed')}>{count && count[el.id]?.closed}</td>
										<td onClick={() => openPopupWindow(url + '&status=')}>{count && count[el.id]?.total}</td>
									</tr>
								);
							})}
							{(count !== undefined && !count?.length) &&
								<tr>
									<td colSpan="100%">No hay avisos</td>
								</tr>
							}
							{count === undefined && <TrSkeleton rows="5" columns="6" />} */}
						</tbody>
					</Table>
				</div>
				
				{ withPendingInvoices.length > 0 &&
					<div className="row mt-3">
						<div className="col-md-12">
							<Table className="table table-sm table-bordered mb-0">
								<thead>
									<tr>
										<th colSpan="3" className="sbold text-center text-primary bg-light">
											Facturas de proveedores pendientes de recibir
										</th>
									</tr>
								</thead>
								<thead className="border-top-0">
									<tr>
										<th className="fw-normal">Aviso</th>
										<th className="fw-normal">Descripción</th>
										<th className="fw-normal">Importe</th>
									</tr>
								</thead>
								<tbody>
									{ withPendingInvoices.map((el, idx) => {
										return el.pendinginvoices.map((piEl, piIdx) => {
											return (
												<tr key={idx + '-' + piIdx}>
													<td>
														<NavLink to={'/notices/edit/' + el.id + '?closeable=true'} onClick={(e) => openPopupInfoWindow(e)} className="text-decoration-none">
															{el.reference} <i className="bi bi-eye-fill"></i>
														</NavLink>
													</td>
													<td>{piEl.description}</td>
													<td>{piEl.amount ? formatNumber(piEl.amount) + ' €' : '-'}</td>
												</tr>
											);
										});
									})}
								</tbody>
							</Table>	
						</div>
					</div>
				}
			</div>
		</div>
	);
}


