import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import { getCommunity } from 'helpers/community';
import { getFromSession, appendToSession } from 'helpers/session';
import { openPopupWindow } from 'helpers/generic';

const Table = styled.table`
	tr {
		cursor: pointer;
	}
	
	th,td {
		@media (min-width: 768px) {
			&:nth-child(1),
			&:nth-child(3) {
				width: 300px;
			}
		
			&:nth-child(2) {
				width: 100px;
			}

			&:nth-child(6) {
				width: 40px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(3),
			&:nth-child(5) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Providers() {
	const [queryParams] = useSearchParams();
	const navigate = useNavigate();

	let filters = getFromSession('providers-list');

	let [forceReload, setForceReload] = useState();
	let [providers, setProviders] = useState({});
	let [search, setSearch] = useState(filters?.search ?? undefined);
	let [sectors, setSectors] = useState([]);
	let [sectorId, setSectorId] = useState(filters?.sector_id ?? undefined);
	let [showCommunityRelatedOnly, setShowCommunityRelatedOnly] = useState((filters?.community_id ? true : false) ?? true);
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'asc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'business_name');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setProviders({...providers, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getSectors = async () => {
			await axios.get('/api/manager/sectors/list', {
				params: {
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setSectors([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getSectors();

		const getProviders = async () => {
			let params = {
				page: page,
				search: search,
				sector_id: sectorId,
				sort: sortField,
				direction: sortDirection,
				community_id: showCommunityRelatedOnly ? getCommunity()?.id : null
			};

			appendToSession('providers-list', params);

			await axios.get('/api/manager/providers/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setProviders({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getProviders();
	}, [forceReload, page, search, sectorId, sortField, sortDirection, showCommunityRelatedOnly]);

	const toggleCommunity = (provider_id, status) => {
		axios.post('/api/manager/providers/toggle-community/' + provider_id, {
			community_id: getCommunity()?.id,
			status: status
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	setForceReload(Math.random());
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupWindowFix = (e, href) => {
		e.preventDefault();
		openPopupWindow(href);
	}

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Proveedores</h1>
					
					<NavLink to="/providers/add" className="btn btn-sm btn-light ms-auto">Nuevo proveedor</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setSectorId(e.target.value)} value={sectorId ?? ''}>
										<option value="">- Sectores -</option>
										{ sectors?.map((el, idx) => {
											return <option value={el.id} key={idx}>{el.name}</option>;
										})}
									</select>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									{ getCommunity()?.id &&
										<select className="form-control form-control-sm" value={showCommunityRelatedOnly} onChange={(e) => setShowCommunityRelatedOnly(!showCommunityRelatedOnly)}>
											<option value={false}>Mostrar todos</option>
											<option value={true}>Mostrar activos en comunidad</option>
										</select>
									}
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div /*className="table-responsive table-responsive-carded"*/>
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'business_name'} onClick={() => sortTableClick('business_name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'vatnumber'} onClick={() => sortTableClick('vatnumber')}>CIF</ThSortable>
											<th>Sectores</th>
											<th>Teléfono</th>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Añadido el</ThSortable>
											<th className="text-center">
												{ getCommunity()?.id &&
													<React.Fragment>
														Activo
													</React.Fragment>
												}
											</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ providers.data?.length > 0 &&
											providers.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'p'+idx}>
														<tr onClick={() => navigate('/providers/edit/' + el.id)}>
															<td>
																{el.business_name ?? '-'}
																<div><small>{el.brand_name}</small></div>
															</td>
															<td>{el.vatnumber ?? '-'}</td>
															<td>{el.sectors?.map((sEl, sIdx) => <span key={sIdx} className="badge bg-light text-dark mx-1">{sEl.name}</span>)}</td>
															<td>{el.phone_default}</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td className="text-center">
																{ getCommunity()?.id &&
																	<React.Fragment>
																		<div className="form-check form-switch d-inline-block">
																			<input className="form-check-input" type="checkbox" checked={el.communities_ids.indexOf(getCommunity()?.id) !== -1} onChange={(e) => toggleCommunity(el.id, e.target.checked)} onClick={(e) => e.stopPropagation()} />
																		</div>
																	</React.Fragment>	
																}
															</td>
															
															<td>
																<div className="dropdown d-inline-block">
																	<button className="btn btn-sm btn-table p-0 px-1 text-primary" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
																		<i className="bi bi-three-dots"></i>
																	</button>
																	<ul className="dropdown-menu">
																		<li><NavLink className="dropdown-item" to={'/providers/edit/' + el.id}>Ficha</NavLink></li>
																		{ el.email_default &&
																			<li><NavLink className="dropdown-item" to={'/emails/add?recipients_type=providers&recipients_ids=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Enviar email</NavLink></li>
																		}
																		<li><NavLink className="dropdown-item" to={'/notices?petitioner_type=provider&petitioner_id=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Ver avisos</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/notices/add?petitioner_type=provider&provider_id=' + el.id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Nuevo aviso</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/providers-invoices?popup=true&search=' + el.vatnumber} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Ver facturas</NavLink></li>
																		<li><NavLink className="dropdown-item" to={'/documents?folder_id=' + el.folder_id} onClick={(e) => openPopupWindowFix(e, e.target.href)}>Abrir documentos</NavLink></li>
																	</ul>
																</div>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ providers.data && !providers.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ providers.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={providers?.current_page}
									max={providers?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


