import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { jsonToArray, CristalLoader } from "helpers/generic";
import { getAjaxUrl } from "helpers/config";
import useStore from "store";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

let axiosCancelToken = null;

export default function ExportModal(props) {
	const modalRef = useRef(null);

    const debug = useStore(state => state.debug);

	const fields = props.fields;
	const closeCallback = props.closeCallback;
	const exportKey = props.exportKey;
	const filters = props.filters;
	const excelAvailable = props.excelAvailable ?? true;
	const pdfAvailable = props.pdfAvailable ?? true;
	const fileName = props.fileName ?? props.exportKey;

	const [selectedFields, setSelectedFields] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		let localStorage = window.localStorage;
		let selectedFieldsStored = localStorage.getItem('export_modal-' + exportKey);
		selectedFieldsStored = JSON.parse(selectedFieldsStored);
		if ( !selectedFieldsStored ) selectedFieldsStored = jsonToArray(fields).map(el => el.key); // Default 
		setSelectedFields(selectedFieldsStored);
	}, [exportKey, fields]);

	useEffect(() => {
		if ( selectedFields === null ) return;
		
		let localStorage = window.localStorage;
		localStorage.setItem('export_modal-' + exportKey, JSON.stringify(selectedFields));
	}, [selectedFields, exportKey]);

	const toggleSelectedField = (key, status) => {
		let newSelectedFields = [...selectedFields];

		if ( status ) {
			newSelectedFields.push(key);
		} else {
			newSelectedFields.splice(newSelectedFields.indexOf(key), 1);
		}

		setSelectedFields(newSelectedFields);
	}

	const downloadFile = async (format) => {	
		setLoading(true);

		let apiUrl = 'api/manager/' + exportKey + '/export/' + format;
		let params = {
			...filters,
			fields: selectedFields.join(',')
		};

		if ( debug ) {
			let paramsQS = new URLSearchParams(params).toString();
			paramsQS = paramsQS.replaceAll('undefined', '');
			paramsQS = paramsQS.replaceAll('null', '');
			console.log(getAjaxUrl() + apiUrl + '/I?' + paramsQS);
		}

		await axios.post(apiUrl, params, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName + '.' + format);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
		
		Modal.getInstance(modalRef.current).hide();
		setLoading(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Exportar datos</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{ jsonToArray(fields).map((el, idx) => {
							return (
								<label key={idx} className="badge bg-light text-dark fw-normal d-inline-flex align-items-center m-2 user-select-none border">
									<input type="checkbox" className="me-1" checked={selectedFields ? selectedFields.includes(el.key) : false} onChange={(e) => toggleSelectedField(el.key, e.target.checked)} />
									{el.value}
								</label>
							);
						}) }
					</div>
					<div className="modal-footer">
						{ excelAvailable &&
							<button type="button" className={'btn btn-sm btn-primary text-white'} disabled={!selectedFields || !selectedFields.length} onClick={() => downloadFile('xlsx')}>Exportar Excel</button>
						}
						{ pdfAvailable &&
							<button type="button" className={'btn btn-sm btn-primary text-white'} disabled={!selectedFields || !selectedFields.length} onClick={() => downloadFile('pdf')}>Exportar PDF</button>
						}
					</div>
				</div>
			</div>
			{ loading && <CristalLoader /> }
		</ModalStyled>
	);
}


