import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { CristalLoader } from 'helpers/generic';
import readXlsxFile from 'read-excel-file'
import { toast } from "react-toastify";
import { getCommunity } from "helpers/community";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	.modal {
		position: relative;
	}
`;

const FileWrapper = styled.div`
	width: 100%;
	padding: 20px;
	background: var(--bs-light);
	border: 1px solid var(--bs-gray-300);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	input[type=file] {
		visibility: hidden;
		height: 0;
	}
`;

const Table = styled.table`
	font-size: 12px;
	font-weight: normal;

	td {
		&.with-errors {
			background: var(--bs-danger);
			color: white;

			.error {
				color: yellow;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function ImportExcelCatastroModal(props) {
	const modalRef = useRef(null);
	const fileRef = useRef(null);

	const closeCallback = props.closeCallback;

	const [file, setFile] = useState(null);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [properties, setProperties] = useState([]);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		const loadProperties = async () => {
			await axios.get('/api/manager/properties/list', {
				params: {
					community_id: getCommunity()?.id,
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setProperties([...response.data]);	
			}).catch((error) => {			
				if ( axios.isCancel(error) ) return;
			});	
		}
		loadProperties();
	}, []);

	const loadFile = async (file) => {
		// Reset
		setFile(null);
		setData([]);

		// Check if exists the file
        if ( !file ) return false;

		// Read file
		readXlsxFile(file).then((rows) => {
			console.log(rows);
			let toValidate = [...rows].filter((el, idx) => idx > 2).map((el, idx) => {
				if ( !el[0] || !el[1] || !el[2] || !el[3] ) return false;

				let split = el[1].substring(el[1].indexOf('Es:')).split(' ').filter(el => el.length);
				let stairs = split[0].split(':')[1];
				let plant = split[1].split(':')[1];
				let door = split[2].split(':')[1];

				return [
					el[0],
					el[1],
					stairs,
					plant,
					door,
					el[2],
					el[3],
					el[5] ?? 0
				]
			}).filter(el => el !== false);

			// Validate 
			let errors = {};
			toValidate
				.forEach((row, rowIdx) => {
					// Column 0 - Cadastral reference
					let cadastralReferenceExists = properties?.filter(el => el.cadastral_reference === row[0]).length > 0;
					if ( cadastralReferenceExists ) errors[rowIdx + '-' + 0] = 'Ya existe una propiedad con esa referencia catastral';				
					if ( row[0].length > 100 ) errors[rowIdx + '-' + 0] = 'El tamaño del texto es demasiado largo (máximo 100 carácteres)';				

					// Column 1 - Name
					if ( row[1].length > 100 ) errors[rowIdx + '-' + 1] = 'El tamaño del texto es demasiado largo (máximo 100 carácteres)';				
				})
			setErrors(errors);
			
			// Set data
			setData(toValidate);

			// Set file
			setFile(file);
		}).catch((error) => {
			console.error(error);
			setFile(null);
			setData([]);
			toast.error('Formato de archivo no válido')
			return;
		});	
	}


	const saveData = async () => {
		setLoading(true);

		// Prepare data
		let dataPrepared = [];
		data.forEach((el, idx) => {
			dataPrepared.push({
				'cadastral_reference'	: el[0],
				'name'					: el[1],
				'stairs'				: el[2],
				'plant'					: el[3],
				'door'					: el[4],
				'type'					: el[5],
				'surface'				: el[6],
				'coefficient'			: (""+el[7]).replace(',', '.')
			});
		});

		document.activeElement.blur();

		await axios.post('api/manager/properties/bulk-add', {
			properties: dataPrepared,
			community_id: getCommunity()?.id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Se han guardado los datos');
			
			// Hide modal
			Modal.getInstance(modalRef.current).hide();
			props.closeCallback();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	

		setLoading(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			{ loading && <CristalLoader /> }
			<div className="modal-dialog modal-xl">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Importar Excel Catastro</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<FileWrapper onClick={() => {fileRef.current.value = null; fileRef.current.click();}}>
							{ file === null ?
								<div>Pulsa para seleccionar el fichero</div>
								:
								<div className="text-center">{file.name}</div>
							}
							<input type="file" ref={fileRef} onChange={(e) => loadFile(e.target.files[0])} />
						</FileWrapper>
					
						{ data.length > 0 && 
							<Table className="table table-sm table-bordered mt-3">
								<thead>
									<tr>
										<td>Referencia Catastral</td>
										<td>Dirección</td>
										<td>Escalera</td>
										<td>Planta</td>
										<td>Puerta</td>
										<td>Tipo</td>
										<td>Superficie</td>
										<td>Coeficiente</td>
									</tr>
								</thead>
								<tbody>
									{data && data.map((el, idx) => {
										return (
											<tr key={idx}>
												{el.map((el2, idx2) => {
													return (
														<td key={idx2} className={errors[idx+'-'+idx2] ? 'with-errors' : ''}>
															{el2}
															{errors[idx+'-'+idx2] &&
																<div className="error">{errors[idx+'-'+idx2]}</div>
															}
														</td>
													);
												})}
											</tr>
										)
									})}
								</tbody>
							</Table>
						}
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => saveData()} disabled={loading || !data.length || Object.keys(errors).length > 0 }>Importar datos</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}