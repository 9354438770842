import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useParams } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import PopupLayout from "layouts/PopupLayout";
import TrSkeleton from "components/TrSkeleton";
import { getCommunity } from 'helpers/community';

const Table = styled.table`
	& > thead > tr > th, 
	& > tbody > tr > td {
		&:nth-child(1) {
			width: 120px;
		}
		&:nth-child(3),
		&:nth-child(4),
		&:nth-child(5) {
			width: 100px;
			text-align: right;
		}
	}

	tr {
		&.hidden {
			display: none;
		}
	}
`;

let axiosCancelToken = null;

export default function TreasuryStatement() {
	let params = useParams();

	let community = getCommunity();
	let [data, setData] = useState(undefined);
	let [financialYears, setFinancialYears] = useState([]);
	let [financialYear, _setFinancialYear] = useState(moment().format('YYYY'));
	let setFinancialYear = (val) => {
		_setFinancialYear(val);
		setDateFrom(moment(val + '-01-01').startOf('year').format('YYYY-MM-DD'));
		setDateTo(moment(val + '-01-01').endOf('year').format('YYYY-MM-DD'));
	};
	let [dateFrom, setDateFrom] = useState( moment().startOf('year').format('YYYY-MM-DD') );
	let [dateTo, setDateTo] = useState( moment().endOf('year').format('YYYY-MM-DD') );
	let [skeletonRows, setSkeletonRows] = useState(5);

	const prepareYears = useCallback(() => {
		let yearFrom = moment(dateFrom).format('YYYY');
		let yearTo = moment(dateTo).format('YYYY');

		let years = [];
		if ( yearTo - yearFrom > 1 ) {
			for (let i = yearFrom; i <= yearTo; i++) {
				years.push(i);
			}
		} else {
			years.push(yearFrom);
			years.push(yearTo);
		}

		return years;
	}, [dateFrom, dateTo]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getMovements = () => {
			let years = prepareYears();

			axios.get('/api/manager/treasury/statement/' + params.id, {
				params: {
					community_id: community?.id,
					financial_years: years,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setData({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		const getFinancialYears = () => {
			return axios.get('/api/manager/treasury/statement/financialyears-list/' + params.id, {
				params: {
					community_id: community?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setFinancialYears([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getMovements();
		getFinancialYears();
	}, [params.id, community?.id, prepareYears]);

	const getDateRange = (range) => {
		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;
			default: 
		}

		return {dateFrom, dateTo};
	}

	const setDates = (e, range) => {
		e.preventDefault();

		let {dateFrom, dateTo} = getDateRange(range);
		
		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const exportPdf = async () => {
		let url = 'api/manager/treasury/statement-pdf/' + params.id;

		await axios.get(url, {
			params: {
				community_id: community?.id,
				date_from: dateFrom,
				date_to: dateTo
			},
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'extracto.pdf');
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
	}

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<PopupLayout>
			<section>
				<div className="page-title">
					<h1>Extracto</h1>
					
					<button onClick={() => window.close()} className="btn btn-sm btn-light ms-auto">Cerrar</button>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fecha
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
										<span className="input-group-btn">
											<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
											<ul className="dropdown-menu dropdown-menu-end">
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thismonth')}>Este mes</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastmonth')}>Mes pasado</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'thisyear')}>Este año</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDates(e, 'lastyear')}>Año pasado</a></li>
												{financialYears.length > 0 &&  <div className="dropdown-divider"></div>}
												{financialYears.map((el, idx) => {
													return (<li key={'fy-'+idx}><a className="dropdown-item" href="." onClick={(e) => {e.preventDefault(); setFinancialYear(el);}}>Año {el}</a></li>);
												})}
											</ul>
										</span>
									</div>
								</div>
								<div className="col-md-7 text-end mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>

								<div className="col-md-1 text-end mb-2 mt-2 mb-md-0 mt-md-0">
									<button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => exportPdf()}>Descargar PDF</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Fecha</th>
											<th>Concepto</th>
											<th>Debe</th>
											<th>Haber</th>
											<th>Saldo</th>
										</tr>
									</thead>
									<tbody>
										{ data && data.movements &&
											data.movements.map((el, idx) => {
												let hidden = false;

												if ( el.date < dateFrom || el.date > dateTo ) {

													hidden = true;
												}

												return (
													<tr key={idx} className={hidden ? 'hidden' : ''}>
														<td>{moment(el.date).format('DD-MM-YYYY')}</td>
														<td>{el.concept}</td>
														<td>{el.amount < 0 ? Math.abs(parseFloat(el.amount)).toFixed(2) : ''}</td>
														<td>{el.amount >= 0 ? parseFloat(el.amount).toFixed(2) : ''}</td>
														<td style={{color: el.balance >= 0 ? 'green' : 'red'}}>{parseFloat(el.balance).toFixed(2)}</td>
													</tr>
												)
											})
										}

										{ data && data.movements && !data.movements.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ data === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}


