import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { getUser, hasCapability } from 'helpers/user';
import { getCommunity, setCommunity } from 'helpers/community';
import { isTouchEnabled, openPopupWindow } from 'helpers/generic';
import useStore from "store";
import Sidebar from 'layouts/Sidebar';
import EmpoweredSelector from 'components/EmpoweredSelector';
import LabeledFrame from 'components/LabeledFrame';
import NoticesNotifications from 'components/NoticesNotifications';

const Layout = styled.div`
	height: 100%;
	position: relative;

	@media screen and (min-width: 500px) {
		max-width: 1920px;
		margin: 0 auto;
	}
`;

const Navbar = styled.nav`
	@media screen and (min-width: 500px) {
		max-width: 1920px;
		margin: 0 auto;
	}

	background: white;
	box-shadow: 0px 2px 10px rgb(1 41 112 / 10%);
	z-index: 21;

	.navbar-brand {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-family: 'Open Sans', sans-serif;
		color: var(--bs-body-color);
		margin-right: 0;
		min-width: 250px;

		img#logo {
			max-height: 30px;
			max-width: 150px;
		}

		.badge {
			font-weight: lighter;
			font-size: 10px;
		}

		@media (max-width: 992px) {
			justify-content: start;
			padding-left: 20px;
		}
	}

	.btn-navbar-toggler {
		display: none;

		&.desktop {
			margin-left: 20px;
			padding: 0;

			@media (min-width: 993px) {
				display: inline;
			}
		}

		&.mobile {
			@media (max-width: 992px) {
				display: inline;
			}
		}		
	}

	.community_selector_wrapper {
		display: flex;
		width: 350px;
		
		& > .empowered-selector {
			margin-left: 10px;
			max-width: 310px;
			width: 100%;

			.community_selected_label {
				display: flex;
				width: 100%;

				.name {
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					display: inline-block;
					width: 75%;
				}

				.name_short {
					display: inline-flex;
					align-items: center;
				}
			}
		}

		& > a {
			/* border: 1px solid var(--bs-gray-300); */
			border-left: 0;
			background: var(--bs-light);
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		@media (max-width: 992px) {
			width: 95%;
			margin: 5px auto;
			padding-left: 0px;
		}
	}

	.navbar-nav {
		@media (max-width: 992px) {
			margin: 0 auto;
			margin-top: 5px !important;
			margin-bottom: 0 !important;

			&:first-of-type {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
			}

			&:last-of-type {
				.dropdown-menu {
					right: 0;
    				left: auto;
				}
			}

			.dropdown-menu {
				position: absolute;
			}

			.nav-link {
				padding: 0;
			}
		}
	}
`;

const DropdownTitle = styled.div`
	display: inline-flex;
	align-items: center;

	.bi {
		color: var(--bs-primary);
		font-size: 18px;
		line-height: var(--bs-body-font-size);
		margin-right: 5px;
	}
`;

const UserTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 15px;
	user-select: none;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 500;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		font-size: 15px;
		background: var(--bs-gray-700);
		color: white;
		border-radius: 50%;
		margin-left: 10px;
	}
`;

const Main = styled.main`
	&:not(.is-mobile) {
		margin-left: 80px;

		&.sidebar-visible {
			margin-left: 250px;
		}
	}
	
	transition: all 0.3s;
	position: relative;	

	.page-title {
		--top: 49px;

		display: flex;
		background: var(--bs-blue);
		margin-top: var(--top);
		padding: 20px;
		border-bottom: 1px solid var(--bs-gray-300);
		align-items: center;
		position: sticky;
		top: var(--top);
		z-index: 3;

		h1 {
			display: flex;
			align-items: center;
			max-width: 1500px;

			color: white;
			font-size: 25px;
			line-height: 25px;
			margin: 0;
		}
	}

	.page-content {
		padding: 20px;
		/* max-width: 1500px; */
	}

	.card-footer {
		position: sticky;
		bottom: 0;
		background: #f7f7f7;
	}

	@media (max-width: 992px) {
    	& {
    		margin-left: 0 !important;
    	}
    }
`;

const ConfigurationDropdownWrapper = styled.div`
	.col-md-6 {
		@media (max-width: 768px) {
			&:first-of-type {
				margin-bottom: 10px;
			}
		}
	}
	ul.dropdown-menu {
		min-width: 450px;

		@media (max-width: 550px) {
			min-width: 280px !important;
		}

		.dropdown-item {
			border-radius: 3px;
			overflow: hidden;
			font-size: 13px;
		}
	}
`;

let axiosCancelToken = null;
let lastSidebarTop = 0;
let lastSidebarleft = 0;

export default function LoggedLayout(props) {
	const navbarRef = useRef(null);
	const configurationDropdownMenuRef = useRef(null);
	const [sidebarTop, setSidebarTop] = useState(lastSidebarTop);
	const [sidebarLeft, setSidebarLeft] = useState(lastSidebarleft);
	const [pendingNoticesCount, setPendingNoticesCount] = useState(0);

    const dispatch = useStore(state => state.dispatch);
	const sidebarVisible = useStore(state => state.sidebarVisible);    
    const debug = useStore(state => state.debug);
    const config = useStore(state => state.config);
	const community = getCommunity();  

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Reposition sidebar
	useEffect(() => {
		// Top
		let newSidebarTop = navbarRef.current.getBoundingClientRect().height;
		// if ( lastSidebarTop === newSidebarTop ) return;
		lastSidebarTop = newSidebarTop;
		setSidebarTop(newSidebarTop);

		// Left
		let setSidebarLeftInterval = setInterval(() => {
			let newSidebarLeft = navbarRef.current.getBoundingClientRect().left;
			lastSidebarleft = newSidebarLeft;
			setSidebarLeft(newSidebarLeft);
		}, 500);
		
		return function cleanup() {
			clearInterval(setSidebarLeftInterval);
		}
	}, [sidebarVisible]);

	// Get pending notices
	useEffect(() => {
		let getPendingNotices = () => {
			axios.get('/api/manager/notices/list', {
				params: {
					status: 'pending',
					user_id: getUser()?.id,
					no_paginate: true,
					minified: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setPendingNoticesCount(response.data.length);
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getPendingNotices();
		let getPendingNoticesInterval = setInterval(() => getPendingNotices(), 10000);

		return function cleanup() {
			clearInterval(getPendingNoticesInterval);
		}
	}, []);

    const setSidebarVisible = (status) => {
	    dispatch({
			type: 'setSidebarVisible',
			status: status
		});
    }

    const loadCommunities = (input, callback) => {
		return axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: (
						<div className="d-flex align-items-center">
							<span className="badge bg-secondary text-white me-1">{el.name_short}</span>
							{el.name}
						</div>
					)
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
    }

    const selectCommunity = (community) => {
    	setCommunity(community, true);
    }

    const openPopupWindowCallback = (e, name) => {
    	e.preventDefault();

    	openPopupWindow(e.currentTarget.href, name);
    }

    const setConfigurationDropdownMenuHeight = () => {
		configurationDropdownMenuRef.current.style.height = 'auto'; // Reset height

		let boundingClientRect = configurationDropdownMenuRef.current.getBoundingClientRect();
		let windowHeight = window.innerHeight;

		configurationDropdownMenuRef.current.style.overflowY = 'auto';
		configurationDropdownMenuRef.current.style.overflowX = 'hidden';
		
		if (windowHeight < boundingClientRect.bottom) {
			let maxHeight = windowHeight - boundingClientRect.top - 20;
			configurationDropdownMenuRef.current.style.height = maxHeight + 'px';
		}
	}
    
	return (
		<Layout id="layout">
			<Navbar ref={navbarRef} className="navbar navbar-expand-lg navbar-light fixed-top">
				<div className="container-fluid px-lg-0">
					<div className="navbar-brand">
						{ config.company?.logo_base64_url &&
							<img id="logo" src={config.company.logo_base64_url} alt="company-logo" />
						}
						{ debug && 
							<span className="badge bg-primary text-white ms-2">debug</span>
						}
						<button className="btn btn-navbar-toggler desktop" tabIndex="-1" onClick={() => setSidebarVisible(!sidebarVisible)}>
							<span className="navbar-toggler-icon"></span>
						</button>
					</div>
					<button className="btn btn-navbar-toggler mobile" tabIndex="-1" onClick={() => setSidebarVisible(!sidebarVisible)}>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className={'collapse navbar-collapse ' + (sidebarVisible ? 'show' : '')}>
						{ hasCapability('select-community') &&
							<div className="community_selector_wrapper">
								<EmpoweredSelector
									tabIndex="-1"
									load={(input, callback) => loadCommunities(input, callback)}
									onChange={(value) => selectCommunity(value)}
									timeout={250}
									label={
										(community && community.id) ? 
											<div className="community_selected_label">
												<span className="name_short badge bg-secondary text-light me-1">{community.name_short}</span>
												<span className="name">{community.name}</span>
											</div>
										: 
											null
									}
									placeholder="Seleccionar comunidad"
									value={community?.id ?? null}
								/>
								{ (community && community.id) && 
									<NavLink className="btn btn-sm" to={'/communities/edit/' + community.id}><i className="bi bi-gear"></i></NavLink>
								}
							</div>
						}
						<div className="d-flex ms-2 justify-content-center py-2 py-lg-0">
							{ hasCapability('notices') &&
								<NavLink to="/notices" className={'btn btn-sm ' + (pendingNoticesCount > 0 ? 'btn-danger text-white' : 'btn-outline-primary')} onClick={(e) => openPopupWindowCallback(e, 'notices')}>
									<i className={'bi bi-bell' + (pendingNoticesCount > 0 ? '-fill' : '')}></i> Avisos
									{ pendingNoticesCount > 0 && <span className="badge rounded-pill bg-light text-dark ms-2">{pendingNoticesCount}</span> }
								</NavLink>
							}
							{ hasCapability('ioregistry') &&
								<NavLink to="/ioregistry/select-type" className={'btn btn-sm btn-outline-primary ms-2'} onClick={(e) => openPopupWindowCallback(e, 'ioregistry')}>
									<i className={'bi bi-arrow-down-up'}></i> Registro E/S
								</NavLink>
							}
							{ hasCapability('contacts') &&
								<NavLink to="/contacts?popup=true" className="btn btn-sm btn-outline-primary ms-2" onClick={(e) => openPopupWindowCallback(e, 'contacts')}><i className="bi bi-user"></i> Contactos</NavLink>
							}
							{ hasCapability('documents') &&
								<NavLink to="/documents" className="btn btn-sm btn-outline-primary ms-2" onClick={(e) => openPopupWindowCallback(e, 'documents')}><i className="bi bi-folder"></i> Documentos</NavLink>
							}
							{ hasCapability('events') &&
								<NavLink to="/calendar" className="btn btn-sm btn-outline-primary ms-2" onClick={(e) => openPopupWindowCallback(e, 'calendar')}><i className="bi bi-calendar"></i> Calendario</NavLink>
							}
							{ hasCapability('emails') &&
								<NavLink to="/emails" className={'btn btn-sm btn-outline-primary ms-2 ' + (!community?.id ? 'disabled' : '')} onClick={(e) => openPopupWindowCallback(e, 'emails')}><i className="bi bi-envelope"></i> Emails</NavLink>
							}
							<NavLink to="/search" className={'btn btn-sm btn-outline-primary ms-2 d-inline-flex align-items-center'} onClick={(e) => openPopupWindowCallback(e, 'search')}><i className="bi bi-search"></i></NavLink>
						</div>
						<div className="d-flex ms-auto">
							{ (getUser().role !== 'superadmin') &&
								<React.Fragment>
									<ul className="navbar-nav mb-2 mb-lg-0">
										<li className="nav-item">
											<ConfigurationDropdownWrapper className="dropdown">
												<a href="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" onClick={setConfigurationDropdownMenuHeight}>
													<DropdownTitle>
														<i className="bi bi-gear bi-big"></i> Configuración
													</DropdownTitle>
												</a>
												<ul className="dropdown-menu dropdown-menu-end" ref={configurationDropdownMenuRef}>
													<li>
														<div className="row mx-0 py-1">
															<UserTitle>
																{ getUser()?.name }
																<NavLink to="/logout"><i className="bi bi-power"></i></NavLink> 
															</UserTitle>
															<div className="col-md-6">
																<LabeledFrame label="Comunidad" background={!community?.id ? 'gray-300' : 'primary'} color={!community?.id ? 'gray-500' : 'white'}>
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to={'/communities/edit/' + community?.id}>Ficha</NavLink>
																	<hr className="dropdown-divider" />
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/financial-years">Ejercicios fiscales</NavLink>
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/contable-accounts">Cuentas contables</NavLink>
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/accounting-entries">Asientos contables</NavLink>
																	<hr className="dropdown-divider" />
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/quotas-concepts">Conceptos de cuotas</NavLink>
																	<hr className="dropdown-divider" />
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/meters">Contadores</NavLink>
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/meters-readings">Lecturas de contadores</NavLink>
																	<hr className="dropdown-divider" />
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/treasury">Tesorería</NavLink>
																	{ hasCapability('clients') && 
																		<React.Fragment>
																			<hr className="dropdown-divider" />
																			<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/clients">Clientes</NavLink>
																		</React.Fragment>
																	}
																	<hr className="dropdown-divider" />
																	<NavLink className={'dropdown-item ' + (!community?.id ? 'disabled' : '')} to="/appowners-bulletinboard">Tablón de anuncios portal</NavLink>
																</LabeledFrame>
															</div>
															<div className="col-md-6">
																<LabeledFrame label="General" background="primary" color="white">
																	{ getUser().company_role === 'principal' &&
																		<React.Fragment>
																			{ hasCapability('company') && <NavLink className="dropdown-item" to="/company">Empresa</NavLink> }
																			{ hasCapability('company-users') && <NavLink className="dropdown-item" to="/users">Usuarios</NavLink> }
																			<hr className="dropdown-divider" />
																			{ hasCapability('templates') && <NavLink className="dropdown-item" to="/templates">Plantillas</NavLink> }
																			{ hasCapability('useful-links') && <NavLink className="dropdown-item" to="/useful-links">Páginas mas utilizadas</NavLink> }
																			{ hasCapability('addresses-relations') && <NavLink className="dropdown-item" to="/addresses-relations">Relación (direcciones)</NavLink> }
																			<hr className="dropdown-divider" />
																			{ hasCapability('communities') && <NavLink className="dropdown-item" to="/communities">Comunidades</NavLink> }
																			{ hasCapability('community-positions') && <NavLink className="dropdown-item" to="/community-positions">Cargos de comunidades</NavLink> }
																			{ hasCapability('properties-types') && <NavLink className="dropdown-item" to="/properties-types">Tipos de propiedades</NavLink> }
																			<hr className="dropdown-divider" />
																			{ hasCapability('notices-types') && <NavLink className="dropdown-item" to="/notices-types">Tipos de avisos</NavLink> }
																			{ hasCapability('notices-sources') && <NavLink className="dropdown-item" to="/notices-sources">Origenes de avisos</NavLink> }
																			<hr className="dropdown-divider" />
																			{ hasCapability('sectors') && <NavLink className="dropdown-item" to="/sectors">Sectores de proveedores</NavLink> }
																		</React.Fragment>
																	}
																	{ hasCapability('providers') && <NavLink className="dropdown-item" to="/providers">Proveedores</NavLink> }
																	{ hasCapability('contacts') && 
																		<React.Fragment>
																			<hr className="dropdown-divider" />
																			<NavLink className="dropdown-item" to="/contacts">Contactos</NavLink>
																		</React.Fragment>
																	}
																	
																	{ getUser().company_role === 'principal' &&
																		<React.Fragment>
																			<hr className="dropdown-divider" />
																			{ hasCapability('taxes') && <NavLink className="dropdown-item" to="/taxes">Impuestos</NavLink> }
																			{ hasCapability('expenses-categories') && <NavLink className="dropdown-item" to="/expenses-categories">Categorías de gastos</NavLink> }
																			{ hasCapability('payment-methods') && <NavLink className="dropdown-item" to="/payment-methods">Métodos de pago</NavLink> }
																			
																			{ hasCapability('bank-contracts') && 
																				<>
																					<hr className="dropdown-divider" />
																					<NavLink className="dropdown-item" to="/bank-contracts">Contratos bancarios</NavLink> 
																				</>
																			}
																		</React.Fragment>
																	}
																</LabeledFrame>
															</div>
														</div>
													</li>
												</ul>
											</ConfigurationDropdownWrapper>
										</li>
									</ul>
								</React.Fragment>
							}
						</div>
					</div>
				</div>
			</Navbar>

			<Sidebar top={sidebarTop} left={sidebarLeft} />

			<Main className={(sidebarVisible ? 'sidebar-visible' : '') + ' ' + (isTouchEnabled() ? 'is-mobile' : '')}>
				{ props.children }
			</Main>

			<NoticesNotifications />
		</Layout>
	);
}