import styled from 'styled-components';
import LabeledFrame from './LabeledFrame';
import Documents from './Documents/Documents';

const DocumentsPanelContainer = styled.div`
	position: relative;

	font-size: 13px;
`;

export default function DocumentsPanel(props) {
	return (
		<LabeledFrame 
			className={props.className ?? ''}
			label={props.title ?? 'Documentos'}
		>
			{ props.folderId &&
				<DocumentsPanelContainer className="documents-panel">
					<Documents 
						panel={true}
						rootFolderId={props.folderId}
						community={props.community}
						reloadFolder={props.reloadFolder}
						recipientsType={props.recipientsType}
						recipientsIds={props.recipientsIds}
					/>
				</DocumentsPanelContainer>
			}
		</LabeledFrame>
	);
}