import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import { jsonToArray } from 'helpers/generic';
import { getConfig } from 'helpers/config';

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(2) {
				width: 150px;
			}

			&:nth-child(3) {
				width: 200px;
			}

			&:nth-child(4) {
				width: 130px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(3),
			&:nth-child(4) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Templates() {
	const [queryParams] = useSearchParams();

	let [templates, setTemplates] = useState({});
	let [type, setType] = useState(null);
	let [community, setCommunity] = useState(null);
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('title');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setTemplates({...templates, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getTemplates = async () => {
			await axios.get('/api/manager/templates/list', {
				params: {
					page: page,
					search: search,
					sort: sortField,
					direction: sortDirection,
					community_id: community?.id,
					type: type
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setTemplates({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getTemplates();
	}, [page, search, sortField, sortDirection, community?.id, type]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Plantillas</h1>

					<NavLink to="/templates/add" className="btn btn-sm btn-light ms-auto">Nueva plantilla</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadCommunities}
										onChange={(value) => setCommunity(value)}
										timeout={250}
										label={community?.name ?? ''}
										placeholder="- Comunidad -"
										value={community?.id}
									/>
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={type ?? ''} onChange={(e) => setType(e.target.value)}>
										<option value="">- Tipo -</option>
										{jsonToArray(getConfig().templatestypes).map((el, idx) => {
											return (<option key={idx} value={el.key}>{el.value}</option>);
										})}
									</select>
								</div>
								<div className="col-md-6 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'title'} onClick={() => sortTableClick('title')}>Título</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'type'} onClick={() => sortTableClick('type')}>Tipo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'community_id'} onClick={() => sortTableClick('community_id')}>Comunidad</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Añadido el</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ templates.data?.length > 0 &&
											templates.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.title ?? '-'}</td>
															<td>{getConfig().templatestypes[el.type] ?? '-'}</td>
															<td>{el.community?.name ?? '-'}</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td>
																<NavLink className="btn-table" to={'/templates/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ templates.data && !templates.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ templates.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={templates?.current_page}
									max={templates?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


