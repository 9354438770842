import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useNavigate, useSearchParams, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader, formatNumber, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import LabeledFrame from 'components/LabeledFrame';
import CustomInput from 'components/CustomInput';

const Table = styled.table`
	font-size: 13px;
	line-height: 15px;

	thead {
		tr {
			th {
				font-weight: 500;

				&:nth-child(1) {
					width: 20px;
					text-align: center;
				}

				&:nth-child(2) {
					width: 50px;
				}

				&:nth-child(3) {
					width: 150px;
				}

				&:nth-child(4) {
					width: 150px;
				}

				&:nth-child(6) {
					width: 80px;
					text-align: right;
				}
			}
		}
	}

	tbody {
		tr {
			td {
				vertical-align: middle;

				&:nth-child(2) {
					white-space: nowrap;
				}

				&:nth-child(6) {
					text-align: right;
					white-space: nowrap;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function BankRemittancesNewForm(props) {
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		date: moment().format('YYYY-MM-DD'),
		payment_date: moment().format('YYYY-MM-DD'),
		receipts: [],

	});
	let [errors, setErrors] = useState({});
	let [loading/*, setLoading*/] = useState(false);
	let [issuance, setIssuance] = useState(null);
	let [existsNotCompleted, setExistsNotCompleted] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		setData((prev) => ({...prev, receipts: []}));

		// Get pending
		axios.get('/api/manager/bankremittances/get-unremittanced-receipts-list', {
			params: {
				community_id: getCommunity()?.id,
				due_date: data.payment_date,
				issuance_id: issuance?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let receipts = response.data.map((el) => {
				return {
					checked: false,
					id: el.id,
					number_full: el.number_full,
					issuance: el.issuance,
					property: el.property,
					payer_type: el.payer_type,
					payerable: el.payerable,
					amount: el.amount
				}
			});
			setData((prev) => ({...prev, receipts: [...receipts]}));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [data.payment_date, issuance?.id]);

	const saveData = async (goBack = true) => {
		// Confirm
		let c = window.confirm('¿Seguro que quieres realizar esta remesa?');
		if ( !c ) return false;

		// Check if exists uncompleted
		let uncompleted = false;
		await axios.get('/api/manager/bankremittances/list', {
			params: {
				community_id: getCommunity()?.id,
				no_paginate: true,
				completed: 0
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			uncompleted = response.data.length ? true : false;
			setExistsNotCompleted(uncompleted);
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
		if ( uncompleted ) {
			toast.error('Ha ocurrido un error al guardar');
			return;
		}

		// Get
		setErrors({});
		data.community_id = getCommunity()?.id;

		await axios.post('/api/manager/bankremittances/add', data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( popup && goBack ) window.close();
			if ( goBack ) navigate('/bankremittances' + (popup ? '?popup=true' : ''));
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setReceiptDataField = (idx, field, value) => {
		let newData = {...data};
		newData.receipts[idx][field] = value;
		setData({...newData});
	}

	const loadTreasuries = (input, callback) => {
		axios.get('/api/manager/treasury/list', {
			params: {
				search: input,
				community_id: getCommunity()?.id,
				no_paginate: true,
				type: 'bankaccount'
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectTreasury = (value) => {
		setData({...data, treasury: {...value}, treasury_id: value?.id})
	}

	const toggleAllCheckboxes = (e) => {
		let newData = {...data};
		let equal = newData.receipts.filter(el => el.checked).length === newData.receipts.length;

		if ( e.target.checked ) {
			newData.receipts.forEach(el => el.checked = equal ? false : true);
		} else {
			newData.receipts.forEach(el => el.checked = false);
		}
		setData({...newData});
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const loadIssuances = (input, callback) => {
		axios.get('/api/manager/receipts-issuances/list', {
			params: {
				search: input,
				community_id: getCommunity()?.id,
				no_paginate: true,
				from_date: moment().subtract(2, 'years').format('YYYY-MM-DD')
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: 
						<div>
							<div>{el.entity_name}</div>
							<small>{moment(el.date).format('DD-MM-YYYY')} / {el.description}</small>
						</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Generación de remesa bancaria</h1>
					<button onClick={() => navigate('/bankremittances')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Fecha de generación" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} readOnly />
												{ errors.date &&
													<div className="invalid-feedback d-block">{ errors.date[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<CustomInput label="Fecha de pago" type="date" className="form-control form-control-sm" onChange={(e) => setDataField('payment_date', e.target.value)} value={data.payment_date ?? ''} />
												{ errors.payment_date &&
													<div className="invalid-feedback d-block">{ errors.payment_date[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-4">
											<div className="mb-2">
												<EmpoweredSelector
													load={loadTreasuries}
													onChange={(value) => selectTreasury(value)}
													timeout={250}
													label={data.treasury?.name ?? ''}
													value={data.treasury?.id}
													placeholder="Banco"
													showPlaceholderHelper={true}
												/>
												{ errors.treasury_id &&
													<div className="invalid-feedback d-block">{ errors.treasury_id[0] }</div>
												}
											</div>
										</div>
										<div className="col-lg-12">
											<div className="mb-3">
												<CustomInput label="Concepto" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('concept', e.target.value)} value={data.concept ?? ''} />
												{ errors.concept &&
													<div className="invalid-feedback d-block">{ errors.concept[0] }</div>
												}
											</div>

											{ existsNotCompleted &&
												<div className="text-danger mb-3">Hay remesas sin enviar al banco. Debes marcarlas como enviadas para poder generar una nueva remesa.</div>
											}
										</div>
										<div className="col-lg-12">
											<LabeledFrame label="Recibos disponibles para remesar">
												<div className="row mb-3">
													<div className="col-md-8"></div>
													<div className="col-md-4">
														<EmpoweredSelector
															load={loadIssuances}
															onChange={(value) => setIssuance(value)}
															timeout={250}
															label={
																issuance ? 
																	<div>
																		<div>{issuance.entity_name}</div>
																		<small className="text-secondary">{moment(issuance.date).format('DD-MM-YYYY')} / {issuance.description}</small>
																	</div>	
																:
																''
															}
															value={issuance?.id}
															placeholder="- Todas las emisiones -"
														/>
													</div>
												</div>
												
												
												<div className="table-responsive">
													<Table className="table table-sm table-bordered mb-0">
														<thead>
															<tr>
																<th>
																	<input type="checkbox" checked={data.receipts.filter(el => el.checked).length === data.receipts.length} onChange={(e) => toggleAllCheckboxes(e)} />
																</th>
																<th>Nº</th>
																<th>Emisión</th>
																<th>Propiedad</th>
																<th>Deudor</th>
																<th>Total</th>
															</tr>
														</thead>
														<tbody>
															{data.receipts.map((el, idx) => {
																return (
																	<tr key={idx}>
																		<td>
																			<input type="checkbox" checked={el.checked} onChange={(e) => setReceiptDataField(idx, 'checked', e.target.checked)} />
																		</td>
																		<td>{el.number_full}</td>
																		<td>
																			{el.issuance?.entity_name}&nbsp;
																			<span className="badge bg-light text-secondary">Nº: {el.issuance?.number}</span>
																		</td>
																		<td>
																			<span className="badge bg-light text-secondary">{el.property?.building?.name}</span>
																			&nbsp;{el.property?.name}
																			&nbsp;<NavLink to={'/properties/edit/' + el.property.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
																		</td>
																		<td>
																			{el.payerable?.name}&nbsp;
																			{ el.payerable?.id &&
																				<NavLink to={'/'+el.payer_type+'s/edit/' + el.payerable?.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
																			}
																		</td>
																		<td>{formatNumber(el.amount)} €</td>
																	</tr>
																);
															})}
															{data.receipts.length <= 0 &&
																<tr><td colSpan="100%">No hay recibos para remesar</td></tr>
															}
														</tbody>
														<tfoot>
															<tr>
																<td colSpan="5" className="sbold text-end">Total</td>
																<td className="sbold text-end">{formatNumber(data.receipts?.filter(el => el.checked).reduce((carry, el) => carry+=parseFloat(el.amount), 0))} €</td>
															</tr>
														</tfoot>
													</Table>
												</div>
												{ errors['receipts'] && 
													<div className="invalid-feedback d-block">{errors['receipts']}</div>
												}
											</LabeledFrame>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-4">
											
										</div>
										<div className="col-8 text-end">							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Generar remesa</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
}