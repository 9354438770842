import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import { getFromSession, appendToSession } from 'helpers/session';
import { formatNumber } from 'helpers/generic';
import ExportModal from 'components/ExportModal';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 20px;
		}

	
		&:nth-child(3),
		&:nth-child(4) {
			width: 100px;
		}

		&:nth-child(5) {
			width: 100px;
			text-align: right;
		}

		&:nth-child(6) {
			width: 100px;
			text-align: right;
		}
		
		@media (max-width: 768px) {
			&:nth-child(3),
			&:nth-child(4) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function MetersReadings() {
	const [queryParams] = useSearchParams();

	let meter_id = queryParams.get('meter_id');

	let filters = getFromSession('meters-readings-list');

	let community = getCommunity();
	let [readings, setReadings] = useState({});
	let [meter, setMeter] = useState(filters?.meter ?? {});
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'date_to');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	let [detailsOpened, setDetailsOpened] = useState(filters?.detailsOpened ?? []);
	let [showExportModal, setShowExportModal] = useState(false);
	const setPage = (page) => {
		setReadings({...readings, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
		} else {
			detailsOpened = [...detailsOpened, rowId];
		}

		setDetailsOpened([...detailsOpened]);
		appendToSession('meters-readings-list', {detailsOpened: detailsOpened});
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !meter_id ) return;

		axios.get('/api/manager/meters/get/' + meter_id, {
			params: {
				community_id: community?.id,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	setMeter({...response.data});
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [meter_id, community?.id]);

	useEffect(() => {
		const getReadings = async () => {
			let params = {
				community_id: community?.id,
				meter_id: meter?.id,
				page: page,
				sort: sortField,
				direction: sortDirection,
			};
			appendToSession('meters-readings-list', {...params, meter: meter});

			await axios.get('/api/manager/meters-readings/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setReadings({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getReadings();
	}, [page, sortField, sortDirection, community?.id, meter?.id]);

	const loadMeters = (input, callback, args) => {
		axios.get('/api/manager/meters/list', {
			params: {
				community_id: args.community?.id,
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Lecturas de contadores</h1>
					
					{ authUserPermission('add') &&
						<NavLink to={'/meters-readings/add' + (meter?.id ? '?meter_id=' + meter?.id : '')} className="btn btn-sm btn-light ms-auto">Nueva lectura</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadMeters}
										args={{community}}
										onChange={(value) => setMeter({id: value?.id, name: value?.name})}
										timeout={250}
										label={meter?.name ?? ''}
										placeholder="Todos los contadores"
										value={meter?.id}
									/>
								</div>
								<div className="col-md-3">
								</div>
								<div className="col-md-6 text-end">
									<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Número</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'meter.name'} onClick={() => sortTableClick('meter.name')}>Contador</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date_from'} onClick={() => sortTableClick('date_from')}>Desde</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date_to'} onClick={() => sortTableClick('date_to')}>Hasta</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'read'} onClick={() => sortTableClick('read')}>Consumos</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'total'} onClick={() => sortTableClick('total')}>Importe</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ readings.data?.length > 0 &&
											readings.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>{el.number}</td>
															<td>{el.meter?.name ?? '-'}</td>
															<td>{moment(el.date_from).format('DD-MM-YYYY')}</td>
															<td>{moment(el.date_to).format('DD-MM-YYYY')}</td>
															<td>{formatNumber(el.read ?? 0)}</td>
															<td>{formatNumber(el.total ?? 0)} €</td>
															<td>
																<NavLink className="btn-table" to={'/meters-readings/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			<div><b className="sbold">Número</b>: {el.number ?? '-'}</div>
																			<div className="mb-3"><b className="sbold">Contador</b>: {el.meter?.name ?? '-'}</div>
																			
																			<div><b className="sbold">Desde</b>: {moment(el.date_from).format('DD-MM-YYYY')}</div>
																			<div className="mb-3"><b className="sbold">Hasta</b>: {moment(el.date_to).format('DD-MM-YYYY')}</div>

																			<div><b className="sbold">Consumos</b>: {formatNumber(el.read ?? 0)}</div>
																			<div><b className="sbold">Importe</b>: {formatNumber(el.total ?? 0)}</div>

																		</div>
																		<div className="col-md-4">
																			
																		</div>
																		<div className="col-md-4">

																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ readings.data && !readings.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ readings.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={readings?.current_page}
									max={readings?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'meters-readings'}
					fileName={'Lecturas de contadores ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						number: 					'Número',
						meter: 						'Contador',
						date_from: 					'Desde',
						date_to: 					'Hasta',
						consumptions: 				'Consumos',
						amount: 					'Importe'
					}}
					filters={{
						community_id: 				community?.id,
						meter_id: 					meter?.id,
						page: 						page,
						sort: 						sortField,
						direction: 					sortDirection,
						no_paginate:				true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</LoggedLayout>
	);
}


