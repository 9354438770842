import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);
`;

let axiosCancelToken = null;

export default function RefundModal(props) {
	const modalRef = useRef(null);

	const closeCallback = props.closeCallback;

	let receipt = props.receipt;
	let [data, setData] = useState({
		new: false,
		refunded_at: moment().format('YYYY-MM-DD'),
		expenses: 0,
		quotasconcept: null
	});
	let [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {
			backdrop: false
		});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const saveRefund = () => {
		let c = window.confirm('¿Seguro que quieres realizar la devolución?');
		if ( !c ) return;

		data.community_id = getCommunity()?.id;

		axios.post('/api/manager/receipts/refund/' + receipt.id, data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Devolución realizada');
			closeCallback();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const loadConcepts = (input, callback) => {
		axios.get('/api/manager/quotas-concepts/list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectConcept = (data) => {
		setData((prev) => ({...prev, quotasconcept: data, quotasconcept_id: data?.id}));
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Realizar devolución</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-2">
							<label>Devolución realizada el</label>
							<input type="date" className="form-control form-control-sm" onChange={(e) => setDataField('refunded_at', e.target.value)} value={data.refunded_at ?? ''} />
							{ errors.refunded_at &&
								<div className="invalid-feedback d-block">{ errors.refunded_at[0] }</div>
							}
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className="mb-2">
									<label>Gastos</label>
									<input type="number" className="form-control form-control-sm" onChange={(e) => setDataField('expenses', e.target.value)} value={data.expenses ?? ''} />
									{ errors.expenses &&
										<div className="invalid-feedback d-block">{ errors.expenses[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-2">
									<label>Concepto de gastos</label>
									<EmpoweredSelector
										load={loadConcepts}
										onChange={(value) => selectConcept(value)}
										timeout={250}
										label={data.expenses ? data.quotasconcept?.name ?? '' : ''}
										value={data.expenses ? data.quotasconcept?.id : ''}
										disabled={!data.expenses}
									/>
									{ errors.quotasconcept_id &&
										<div className="invalid-feedback d-block">{ errors.quotasconcept_id[0] }</div>
									}
								</div>
							</div>
						</div>
						<div className="mt-2 text-center">
							<div className="form-check form-switch d-inline-flex">
								<input className="form-check-input me-2" type="checkbox" onChange={(e) => setDataField('new', e.target.checked)} checked={data.new ?? ''} />
								<label className="form-check-label">¿Generar nuevo recibo?</label>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-primary text-white'} onClick={() => saveRefund()}>Devolver</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}


