import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import { CristalLoader } from 'helpers/generic';

let axiosCancelToken = null;

export default function CompaniesForm(props) {
	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		status: 1,
		user: {}
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(params.id ? true : false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		if ( params.id ) {
			const getData = async () => {
				setData([]);

				await axios.get('/api/superadmin/companies/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					let data = {...response.data};
			    	setData(data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [params.id]);

	const deleteCompany = () => {
		const c = window.confirm('¿Quieres eliminar esta empresa?');
		if ( !c ) return;

		axios.delete('/api/superadmin/companies/delete/' + params.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Empresa borrada')
	    	navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/superadmin/companies/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( goBack ) navigate(-1);
			else {
				setData(response.data.company);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setUserField = (field, value) => {
		let user = {...data.user};
		user = {...user, [field]: value};
		setData({
			...data, 
			user: user
		});
	}

	return (
		<LoggedLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Empresas</h1>
					<button onClick={() => navigate(-1)} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-8">
											<div className="mb-2">
												<label>Nombre</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Subdominio</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('subdomain', e.target.value)} value={data.subdomain ?? ''} />
												{ errors.subdomain &&
													<div className="invalid-feedback d-block">{ errors.subdomain[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>CIF</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('vatnumber', e.target.value)} value={data.vatnumber ?? ''} />
												{ errors.vatnumber &&
													<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Dirección</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} />
												{ errors.address &&
													<div className="invalid-feedback d-block">{ errors.address[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Código postal</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} />
												{ errors.postalcode &&
													<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Ciudad</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} />
												{ errors.city &&
													<div className="invalid-feedback d-block">{ errors.city[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Provincia</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} />
												{ errors.province &&
													<div className="invalid-feedback d-block">{ errors.province[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>País</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} />
												{ errors.country &&
													<div className="invalid-feedback d-block">{ errors.country[0] }</div>
												}
											</div>
										</div>

										<div className="col-md-12 mt-4"></div>

										<div className="col-md-5">
											<div className="mb-2">
												<label>Persona de contacto</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('contact_person', e.target.value)} value={data.contact_person ?? ''} />
												{ errors.contact_person &&
													<div className="invalid-feedback d-block">{ errors.contact_person[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-3">
											<div className="mb-2">
												<label>Teléfono</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('phone', e.target.value)} value={data.phone ?? ''} />
												{ errors.phone &&
													<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Email</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} />
												{ errors.email &&
													<div className="invalid-feedback d-block">{ errors.email[0] }</div>
												}
											</div>
										</div>

										<div className="col-md-12 mt-4"></div>

										<div className="col-md-8"></div>

										<div className="col-md-4">
											<div className="mb-2">
												<label>Estado</label>
												<select className="form-control form-control-sm" onChange={(e) => setDataField('status', e.target.value)} value={data.status ?? ''}>
													<option value="1">Activa</option>
													<option value="0">Inactiva</option>
												</select>
												{ errors.status &&
													<div className="invalid-feedback d-block">{ errors.status[0] }</div>
												}
											</div>
										</div>

										<div className="col-md-12 mt-4"></div>

										<div className="col-md-4">
											{ (!data.id && data.user) &&
												<div className="form-check form-switch mb-2">
													<input className="form-check-input" type="checkbox" id="create_user" onChange={(e) => setUserField('create', e.target.checked ? 1 : 0)} checked={data.user?.create === 1} />
													<label className="form-check-label" htmlFor="create_user">Crear usuario principal</label>
												</div>
											}
										</div>

										<div className="col-md-12"></div>

										{ (!data.id && data.user?.create === 1) &&
											<React.Fragment>
												<div className="col-md-4">
													<div className="mb-2">
														<label>Nombre del usuario</label>
														<input type="text" className="form-control form-control-sm" onChange={(e) => setUserField('name', e.target.value)} value={data.user.name ?? ''} />
														{ errors['user.name'] &&
															<div className="invalid-feedback d-block">{ errors['user.name'][0] }</div>
														}
													</div>
												</div>
												<div className="col-md-4">
													<div className="mb-2">
														<label>Email</label>
														<input type="text" className="form-control form-control-sm" onChange={(e) => setUserField('email', e.target.value)} value={data.user.email ?? ''} />
														{ errors['user.email'] &&
															<div className="invalid-feedback d-block">{ errors['user.email'][0] }</div>
														}
													</div>
												</div>
												<div className="col-md-4">
													<div className="mb-2">
														<label>Contraseña</label>
														<input type="text" className="form-control form-control-sm" onChange={(e) => setUserField('password', e.target.value)} value={data.user.password ?? ''} />
														{ errors['user.password'] &&
															<div className="invalid-feedback d-block">{ errors['user.password'][0] }</div>
														}
													</div>
												</div>
											</React.Fragment>
										}
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6">
											{ (data.id && !parseInt(data.status)) &&
												<button className="btn btn-sm btn-link text-danger" onClick={() => deleteCompany()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ data.id &&
												<span className="fw-bold text-danger">ID: { data.id }</span>
											}
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}