import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from 'layouts/PopupLayout';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { getCommunity } from 'helpers/community';
import { authUserPermission } from 'helpers/community';
import { getFromSession, appendToSession } from 'helpers/session';
import ExportModal from 'components/ExportModal';
import Details from './Details';

const Table = styled.table`
	& > thead > tr > th, 
	& > tbody > tr > td {
		&:nth-child(1) {
			width: 20px;
		}

		&:nth-child(2) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(3) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(5) {
			width: 50px;
			text-align: right;
		}

		&:nth-child(6) {
			width: 50px;
			white-space: nowrap;
			text-align: right;
		}
		
		@media (max-width: 768px) {
			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				display: none;
			}
		}
	}

	tbody {
		tr.details {
			border-bottom: 2px solid var(--bs-primary);

			#loader {
				display: block;
				width: 150px;
				margin: 0 auto;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function BankRemittances() {
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';
	const id = queryParams.get('id');

	let filters = getFromSession('bankremittances-list');

	let community = getCommunity();
	let [forceReload, setForceReload] = useState(null);
	let [remittances, setRemittances] = useState({});
	let [detailsOpened, setDetailsOpened] = useState(filters?.detailsOpened ?? []);
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'number');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	let [showExportModal, setShowExportModal] = useState(false);
	const setPage = (page) => {
		setRemittances({...remittances, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
		} else {
			detailsOpened = [...detailsOpened, rowId];
		}

		setDetailsOpened([...detailsOpened]);
		appendToSession('bankremittances-list', {detailsOpened: detailsOpened});
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getRemittances = async () => {	
			let params = {
				community_id: community?.id,
				page: page,
				sort: sortField,
				direction: sortDirection,
				id: id,
				minified: true
			};

			appendToSession('bankremittances-list', params);
			
			await axios.get('/api/manager/bankremittances/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setRemittances({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getRemittances();
	}, [forceReload, page, sortField, sortDirection, community?.id, id]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	useEffect(() => {
		if ( remittances.data && remittances.data.length > 0 ) {
			toggleDetails(parseInt(id));
		}
	}, [remittances.data, id]);

	if ( !community?.id ) return <Navigate to="/" />;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<section>
				<div className="page-title">
					<h1>Remesas bancarias</h1>
					
					{ (authUserPermission('add') && !popup) &&
						<NavLink to="/bankremittances/new" className="btn btn-sm btn-light ms-auto">Realizar remesa</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">

						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
								<div className="col-md-3">
									
								</div>
								<div className="col-md-6 text-end">
									<button className="btn btn-sm btn-light me-2" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>

						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Nº</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'payment_date'} onClick={() => sortTableClick('payment_date')}>Fecha de pago</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'treasury.name'} onClick={() => sortTableClick('treasury.name')}>Banco</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'receipts.count'} onClick={() => sortTableClick('receipts.count')}>Recibos</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'receipts.total'} onClick={() => sortTableClick('receipts.total')}>Total</ThSortable>
										</tr>
									</thead>
									<tbody>
										{ remittances.data?.length > 0 &&
											remittances.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>{el.number}</td>
															<td>{moment(el.date).format('DD-MM-YYYY')}</td>
															<td>{moment(el.payment_date).format('DD-MM-YYYY')}</td>
															<td>{el.treasury?.name}</td>
															<td>{el.receipts_count}</td>
															<td>{el.total} €</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<Details 
																			remittanceId={el.id} 
																			forceReload={() => setForceReload(Math.random())}
																		/>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ remittances.data && !remittances.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ remittances.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end" id={popup ? 'footer-fixed' : ''}>
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={remittances?.current_page}
									max={remittances?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'bankremittances'}
					fileName={'Remesas bancarias ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						number: 					'Nº',
						date: 						'Fecha',
						payment_date: 				'Fecha de pago',
						bank: 						'Banco',
						receipts_count:				'Nº de recibos',
						total: 						'Total',
						with_receipts: 				'Incluir recibos'
					}}
					filters={{
						community_id: 				community?.id,
						page: 						page,
						sort: 						sortField,
						direction: 					sortDirection,
						no_paginate:				true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</Layout>
	);
}


