import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import LabeledFrame from 'components/LabeledFrame';
import PermissionsTable from './PermissionsTable';
import CustomInput from 'components/CustomInput';

let axiosCancelToken = null;

export default function UsersForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const popup = queryParams.get('popup') === 'true';

	let [data, setData] = useState({
		company_role: '',
		communities_permissions: {}
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/manager/users/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteUser = () => {
		const c = window.confirm('¿Quieres eliminar este usuario?');
		if ( !c ) return;

		axios.delete('/api/manager/users/delete/' + params.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Usuario borrado')
	    	navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/manager/users/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( goBack ) {
				if ( popup ) window.close();
				else navigate(-1);
			} else {
		    	setData(response.data.user);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const setPermissions = (permissions) => {
		setData({...data, communities_permissions: permissions});
	}

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<React.Fragment>
			<Layout>
				{ loading && <CristalLoader /> }

				<section>
					<div className="page-title">
						<h1>Ficha de usuario</h1>
						<button onClick={() => popup ? window.close() : navigate(-1)} className="btn btn-sm btn-light ms-auto">{popup ? 'Cerrar' : 'Volver'}</button>
					</div>

					<div className="page-content">
						<div className="row justify-content-center">
							<div className="col-md-12">
								<div className="card border-0 shadow-sm">
									<div className="card-body">
										<div className="row">
											<div className="col-md-7">
												<LabeledFrame label="Datos básicos" className="mb-3">
													<div className="row">
														<div className="col-md-8">
															<div className="mb-2">
																<CustomInput label="Nombre" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
																{ errors.name &&
																	<div className="invalid-feedback d-block">{ errors.name[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="DNI" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('vatnumber', e.target.value)} value={data.vatnumber ?? ''} />
																{ errors.vatnumber &&
																	<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="Email" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} />
																{ errors.email &&
																	<div className="invalid-feedback d-block">{ errors.email[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="Teléfono" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('phone', e.target.value)} value={data.phone ?? ''} />
																{ errors.phone &&
																	<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
																}
															</div>
														</div>

														<div className="col-md-12 mt-4"></div>

														<div className="col-md-8">
															<div className="form-check form-switch">
																<input className="form-check-input" type="checkbox" id="principal" onChange={(e) => setDataField('company_role', e.target.checked ? 'principal' : null)} checked={data.company_role === 'principal'} />
																<label className="form-check-label" htmlFor="principal">Gestor principal de la empresa</label>
															</div>
															<div style={{fontSize: '12px', lineHeight: '12px', color: 'var(--bs-gray-600)'}}>Los gestores principales pueden acceder al menú de configuración y añadir nuevas comunidades</div>
														</div>

														<div className="col-md-4">
															<div className="mb-2">
																<CustomInput label="Contraseña" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('password', e.target.value)} value={data.password ?? ''} />
																{ data.id &&
																	<small className="text-secondary d-block" style={{fontSize: '10px', lineHeight: '12px'}}>Dejar en blanco para no cambiar la contraseña</small>
																}
																{ errors.password &&
																	<div className="invalid-feedback d-block">{ errors.password[0] }</div>
																}
															</div>
														</div>
													</div>
												</LabeledFrame>

												<LabeledFrame label="Dirección">
													<div className="row">
														<div className="col-md-10">
															<div className="mb-2">
																<CustomInput label="Calle" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} />
																{ errors.address &&
																	<div className="invalid-feedback d-block">{ errors.address[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-2">
															<div className="mb-2">
																<CustomInput label="C.P." type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} />
																{ errors.postalcode &&
																	<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-5">
															<div className="mb-2">
																<CustomInput label="Ciudad" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} />
																{ errors.city &&
																	<div className="invalid-feedback d-block">{ errors.city[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-5">
															<div className="mb-2">
																<CustomInput label="Provincia" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} />
																{ errors.province &&
																	<div className="invalid-feedback d-block">{ errors.province[0] }</div>
																}
															</div>
														</div>
														<div className="col-md-2">
															<div className="mb-2">
																<CustomInput label="País" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} />
																{ errors.country &&
																	<div className="invalid-feedback d-block">{ errors.country[0] }</div>
																}
															</div>
														</div>
													</div>
												</LabeledFrame>
											</div>
											<div className="col-md-5">
												<LabeledFrame label="Comunidades">
													<PermissionsTable 
														permissions={data.communities_permissions} 
														setPermissions={(data) => setPermissions(data)} 
														principalUser={data.company_role === 'principal'}
													/>			
												</LabeledFrame>

												<LabeledFrame label="Otros" className="mt-3">
													<div className="row">
														<div className="col-md-10">
															<div className="mb-2">
																<div className="form-check form-switch">
																	<input className="form-check-input" type="checkbox" id="disable_filter_notices" onChange={(e) => setDataField('disable_filter_notices', e.target.checked)} checked={data.disable_filter_notices ?? false} />
																	<label className="form-check-label" htmlFor="disable_filter_notices">No filtrar avisos por defecto</label>
																</div>
																<div style={{fontSize: '12px', lineHeight: '12px', color: 'var(--bs-gray-600)'}}>Los usuarios con esta opción habilitadan podrán ver todos los avisos sin el filtro de usuario por defecto.</div>
															</div>
														</div>
													</div>
												</LabeledFrame>
											</div>
										</div>
									</div>
									<div className="card-footer" id={popup ? 'footer-fixed' : ''}>
										<div className="row">
											<div className="col-6">
												{ data.id &&
													<button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteUser()}>Eliminar</button>							
												}
											</div>
											<div className="col-6 text-end">
												<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
												<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</React.Fragment>
	);
}