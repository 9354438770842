import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams, Navigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { getCommunity } from 'helpers/community';
import { getFromSession, appendToSession } from 'helpers/session';
import { formatNumber, openPopupWindow } from 'helpers/generic';
import ExportModal from 'components/ExportModal';

const Table = styled.table`
	thead,
	tbody {
		th,td {
			&:nth-child(1) {
				width: 100px;
			}

			&:nth-child(4) {
				width: 120px;
			}

			&:nth-child(5) {
				width: 80px;
				text-align: right;
			}

			&:nth-child(6) {
				width: 40px;
				min-width: 40px;
				text-align: center;
			}

			@media (max-width: 768px) {
				/* &:nth-child(4),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(7) {
					display: none;
				} */
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Incomes() {
	const [queryParams] = useSearchParams();

	let filters = getFromSession('incomes-list');

	let community = getCommunity();
	let [forceReload, setForceReload] = useState(null);
	let [incomes, setIncomes] = useState({});
	let [dateFrom, setDateFrom] = useState(filters?.date_from ?? moment().startOf('month').format('YYYY-MM-DD'));
	let [dateTo, setDateTo] = useState(filters?.date_to ?? moment().endOf('month').format('YYYY-MM-DD'));
	let [type, setType] = useState(filters?.type ?? null);
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'date');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setIncomes({...incomes, data: undefined});
		_setPage(page);
	}
	let [showExportModal, setShowExportModal] = useState(false);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getIncomes = async () => {
			let params = {
				page: 				page,
				community_id: 		community?.id,
				sort: 				sortField,
				direction: 			sortDirection,
				type: 				type,
				date_from: 			dateFrom,
				date_to: 			dateTo
			};

			appendToSession('incomes-list', params);

			await axios.get('/api/manager/incomes/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setIncomes({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getIncomes();
	}, [forceReload, page, community?.id, sortField, sortDirection, type, dateFrom, dateTo]);

	useEffect(() => {
		window.PopupProxyCallbackClientInvoices = (data) => {
			setForceReload(Math.random());
		}
	}, []);

	const setDatesRange = (e, range) => {
		e.preventDefault();

		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;

			default:
		}

		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const openPopupWindowFix = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href + '?popup=true');
	}

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Ingresos</h1>

					<NavLink to={'/clients-invoices/add'} className="btn btn-sm btn-light ms-auto">Nuevo ingreso</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" value={type ?? ''} onChange={(e) => setType(e.target.value)}>
										<option value="">- Tipo -</option>
										<option value="receipts">Recibos</option>
										<option value="clients-invoices">Facturas clientes</option>
										<option value="provider-invoices">Devoluciones proveedores</option>
										<option value="counterparts">Contrapartidas</option>
									</select>
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fecha
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
										<span className="input-group-btn">
											<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
											<ul className="dropdown-menu dropdown-menu-end">
												<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thismonth')}>Este mes</a></li>
												<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastmonth')}>Mes pasado</a></li>
												<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thisyear')}>Este año</a></li>
												<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastyear')}>Año pasado</a></li>
											</ul>
										</span>
									</div>
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'type'} onClick={() => sortTableClick('type')}>Tipo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Documento</ThSortable>
											<th>Origen</th>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'total'} onClick={() => sortTableClick('total')}>Total</ThSortable>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ incomes.data?.length > 0 &&
											incomes.data.map((el, idx) => {
												let to_url = '';
												if ( el.type === 'receipts' ) to_url = '/receipts/edit/' + el.id_to_open;
												if ( el.type === 'provider-invoices' ) to_url = '/providers-invoices/edit/' + el.id_to_open;
												if ( el.type === 'client-invoices' ) to_url = '/clients-invoices/edit/' + el.id_to_open;
												if ( el.type === 'counterparts' ) to_url = '/estimates/edit/' + el.id_to_open;
												return ( 
													<React.Fragment key={'p'+idx}>
														<tr>
															<td>
																{el.type === 'receipts' &&
																	<span className="badge bg-primary">Recibo</span>
																}
																{el.type === 'client-invoices' &&
																	<span className="badge bg-success text-white">Factura cliente</span>
																}
																{el.type === 'provider-invoices' &&
																	<span className="badge bg-warning text-dark">Devolución proveedor</span>
																}
																{el.type === 'counterparts' &&
																	<span className="badge bg-secondary">Contrapartida</span>
																}
															</td>
															<td>{el.name ?? '-'}</td>
															<td>{el.payer ?? '-'}</td>
															<td>{ el.date ? moment(el.date).format('DD-MM-YYYY') : '-' }</td>
															<td>{ formatNumber(el.total) } €</td>
															<td><NavLink className="btn-table" to={to_url} onClick={(e) => openPopupWindowFix(e)}><i className="bi bi-search"></i></NavLink></td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ incomes.data && !incomes.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ incomes.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
									</tbody>
									<tfoot>
										<tr className="sbold">
											<td colSpan="4"></td>
											<td className="text-end">{ formatNumber(incomes.total_sum, 2) } €</td>
											<td></td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={incomes?.current_page}
									max={incomes?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'incomes'}
					fileName={'Ingresos ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						type:				'Tipo',
						name: 				'Nombre',
						payer: 				'Origen',
						date: 				'Fecha',
						total: 				'Total',
					}}
					filters={{
						page: 				page,
						community_id: 		community?.id,
						sort: 				sortField,
						direction: 			sortDirection,
						type: 				type,
						date_from: 			dateFrom,
						date_to: 			dateTo,
						no_paginate:		true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</LoggedLayout>
	);
}


