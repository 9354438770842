import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import PopupLayout from 'layouts/PopupLayout';
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import LabeledFrame from 'components/LabeledFrame';
import EmpoweredSelector from 'components/EmpoweredSelector';
import ImportantOwners from 'components/ImportantOwners';
import { getUser } from 'helpers/user';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import { openPopupWindow, getPetitionerFormatted } from 'helpers/generic';
import ExportModal from 'components/ExportModal';

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				width: 80px;
				white-space: nowrap;

				small {
					display: block;
				}
			}

			&:nth-child(5) {
				width: 50px;
			}

			&:nth-child(6),
			&:nth-child(7) {
				max-width: 150px;
				word-break: break-all;
			}

			&:nth-child(8),
			&:nth-child(9) {
				width: 80px;
			}

			&:nth-child(10) {
				width: 100px;
				white-space: nowrap;
			}

			&:nth-child(11) {
				width: 80px;
			}

			&:nth-child(12) {
				word-break: break-all;
			}

			&:nth-child(13) {
				min-width: 80px;
				width: 80px;

				a {

					&:hover {
						color: white;
					}
				}
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				width: 80px;
				white-space: nowrap;

				small {
					display: block;
				}
			}
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(5),
			&:nth-child(6),
			&:nth-child(7),
			&:nth-child(8),
			&:nth-child(10),
			&:nth-child(11) {
				display: none;
			}

			&:nth-child(11) {
				width: 40px;
			}
		}
	}
`;

const Action = styled.div`
	position: relative;
	text-align: ${props => props.type === 'action' ? 'left' : 'right'};

	&:not(:last-of-type) {
		padding-bottom: 10px;
		margin-bottom: 10px;
		border-bottom: 1px solid var(--bs-gray-500);
	}

	.date {
		font-size: 10px;
	}

	.user {
		color: var(${props => props.type === 'action' ? '--bs-primary' : '--bs-orange'});
		font-weight: 500;
		font-size: 12px;
	}

	.description {
		font-size: 12px;
	}
`;

const PetitionerSelectRow = styled.div`
	position: relative;

	.name {

	}

	.type {
		background: var(--bs-secondary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
		margin-right: 5px;
	}

	.community {
		&:empty {
			display: none;
		}

		background: var(--bs-primary);
		color: white;
		padding: 2px 8px;
		font-size: 10px;
		border-radius: 3px;
		display: inline-block;
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Notices() {
	const [queryParams] = useSearchParams();

	let pageNo = queryParams.get('page') ?? undefined;
	let status_ = queryParams.get('status') ?? 'pending,progress,expired';
	let community_id = parseInt(queryParams.get('community_id')) > 0 ? queryParams.get('community_id') : '';
	let user_id = parseInt(queryParams.get('user_id')) > 0 ? queryParams.get('user_id') : null;
	if ( !user_id && !getUser()?.disable_filter_notices ) {
		user_id = getUser()?.id;
	}
	if ( queryParams.get('user_id') === "null" ) user_id = null;
	let opener_id = parseInt(queryParams.get('opener_id')) > 0 ? queryParams.get('opener_id') : '';
	let type_id = parseInt(queryParams.get('type_id')) > 0 ? queryParams.get('type_id') : '';
	let petitioner_type = queryParams.get('petitioner_type') ?? null;
	let petitioner_id = queryParams.get('petitioner_id') ?? null;

	let [notices, setNotices] = useState({});
	let [sessionLoaded, setSessionLoaded] = useState(false);

	let [search, _setSearch] = useState(queryParams.get('search') ?? undefined);
	let [searchFix, setSearchFix] = useState(search);
	let setSearch = (value, timeout = 1000) => {
		// Timeout to ajax
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearchFix(value);
			setPage(1);
		}, timeout);

		// Set search
		_setSearch(value);
		window.sessionStorage.setItem('search', value);
	}

	let [sortDirection, _setSortDirection] = useState('desc');
	let setSortDirection = (value) => {
		_setSortDirection(value);
		window.sessionStorage.setItem('sortDirection', value);
	}

	let [sortField, _setSortField] = useState('initat.pending');
	let setSortField = (value) => {
		_setSortField(value);
		window.sessionStorage.setItem('sortField', value);
	}

	let [internalTask, _setInternalTask] = useState(queryParams.get('internal_task') ?? '')
	let setInternalTask = (value) => {
		_setInternalTask(value);
		setPage(1);
		window.sessionStorage.setItem('internalTask', value);
	}

	let [detailsOpened, _setDetailsOpened] = useState([]);
	let setDetailsOpened = (value) => {
		_setDetailsOpened(value);
		window.sessionStorage.setItem('detailsOpened', value);
	}

	let [community, _setCommunity] = useState({
		id: community_id,
		name: null
	});
	let setCommunity = (value) => {
		_setCommunity(value);
		setPage(1);
		window.sessionStorage.setItem('community', JSON.stringify(value));
	}

	let [user, _setUser] = useState({
		id: user_id,
		name: null,
	});
	let setUser = (value) => {
		_setUser(value);
		setPage(1);
		window.sessionStorage.setItem('user', JSON.stringify(value));
	}

	let [userCreated, _setUserCreated] = useState({
		id: null,
		name: null,
	});
	let setUserCreated = (value) => {
		_setUserCreated(value);
		setPage(1);
		window.sessionStorage.setItem('userCreated', JSON.stringify(value));
	}

	let [type, _setType] = useState({
		id: null,
		name: null
	});
	let setType = (value) => {
		_setType(value);
		setPage(1);
		window.sessionStorage.setItem('type', JSON.stringify(value));
	}

	let [source, _setSource] = useState({
		id: null,
		name: null
	});
	let setSource = (value) => {
		_setSource(value);
		window.sessionStorage.setItem('source', JSON.stringify(value));
	}

	let [status, _setStatus] = useState(status_);
	let setStatus = (value) => {
		_setStatus(value);
		setPage(1);
		window.sessionStorage.setItem('status', value);
	}

	let [character, _setCharacter] = useState('');
	let setCharacter = (value) => {
		_setCharacter(value);
		setPage(1);
		window.sessionStorage.setItem('character', value);
	}

	let [responsible, _setResponsible] = useState(undefined);
	let setResponsible = (value) => {
		_setResponsible(value);
		setPage(1);
		window.sessionStorage.setItem('responsible', JSON.stringify(value));
	}

	let [provider, _setProvider] = useState({
		id: null,
		name: null
	});
	let setProvider = (value) => {
		_setProvider(value);
		setPage(1);
		window.sessionStorage.setItem('provider', JSON.stringify(value));
	}

	let [skeletonRows, setSkeletonRows] = useState(5);

	let [page, _setPage] = useState(pageNo);
	const setPage = (page) => {
		setNotices({...notices, data: undefined});
		_setPage(page);
		window.sessionStorage.setItem('page', page);
	}

	let [petitioner, _setPetitioner] = useState({
		id 		: null,
		name 	: null,
		type 	: null
	});
	let setPetitioner = (value) => {
		_setPetitioner(value);
		setPage(1);
		window.sessionStorage.setItem('petitioner', JSON.stringify(value));
	}

	let [dateFrom, _setDateFrom] = useState(null);
	let setDateFrom = (value) => {
		_setDateFrom(value);
		setPage(1);
		window.sessionStorage.setItem('dateFrom', value);
	}

	let [dateTo, _setDateTo] = useState(null);
	let setDateTo = (value) => {
		_setDateTo(value);
		setPage(1);
		window.sessionStorage.setItem('dateTo', value);
	}

	let [showExportModal, setShowExportModal] = useState(false);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( !sessionLoaded ) return;

		const getDataAsync = async () => {
			let params = {
				page: page,
				search: searchFix,
				community_id: community?.id,
				responsible_id: responsible?.id,
				status: status,
				type_id: type?.id,
				source_id: source?.id,
				character: character,
				user_id: user?.id,
				provider_id: provider?.id,
				sort: sortField,
				direction: sortDirection,
				internal_task: internalTask,
				created_by_id: userCreated?.id,	
				date_from: dateFrom,
				date_to: dateTo,
				minified: true
			};

			// Petitioner
			if ( petitioner?.type && petitioner?.id ) {
				params[petitioner.type+'_id'] = petitioner.id;
			}

			// Load notices list
			await axios.get('/api/manager/notices/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setNotices({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getDataAsync();
	}, [sessionLoaded, page, searchFix, sortField, sortDirection, status, community?.id, responsible?.id, type?.id, source?.id, user?.id, userCreated?.id, provider?.id, internalTask, character, petitioner?.id, petitioner?.type, dateFrom, dateTo]);

	useEffect(() => {
		let getDataAsync = async () => {
			let getSessionFilters = () => {
				let search = sessionStorage.getItem('search');
				if (search !== 'null') setSearch(search, 0);

				let sortDirection = sessionStorage.getItem('sortDirection');
				if (sortDirection !== 'null') setSortDirection(sortDirection);

				let sortField = sessionStorage.getItem('sortField');
				if (sortField !== 'null') setSortField(sortField);

				let internalTask = sessionStorage.getItem('internalTask');
				if (internalTask !== 'null') setInternalTask(internalTask);

				let status = sessionStorage.getItem('status');
				if (status !== 'null') {
					setStatus(prev => {
						return prev === null ? status : prev;
					}); // prevent set null status
				}

				let character = sessionStorage.getItem('character');
				if (character !== 'null') setCharacter(character);

				let page = sessionStorage.getItem('page');
				if (page !== 'null') setPage(page);

				let dateFrom = sessionStorage.getItem('dateFrom');
				if (dateFrom !== 'null') setDateFrom(dateFrom);

				let dateTo = sessionStorage.getItem('dateTo');
				if (dateTo !== 'null') setDateTo(dateTo);

				// let detailsOpened = JSON.parse(sessionStorage.getItem('detailsOpened'));
				// setDetailsOpened(detailsOpened ?? []);

				let community = JSON.parse(sessionStorage.getItem('community'));
				if ( community ) setCommunity(community);

				let user = JSON.parse(sessionStorage.getItem('user'));
				if ( user ) {
					setUser(user);
					user_id = user?.id; // Fix: needed because on getDataFromAjax function we get data of user from db
				}

				let userCreated = JSON.parse(sessionStorage.getItem('userCreated'));
				if ( userCreated ) setUserCreated(userCreated);

				let type = JSON.parse(sessionStorage.getItem('type'));
				if ( type ) setType(type);

				let source = JSON.parse(sessionStorage.getItem('source'));
				if ( source ) setSource(source);

				let responsible = JSON.parse(sessionStorage.getItem('responsible'));
				if ( responsible ) setResponsible(responsible);

				let provider = JSON.parse(sessionStorage.getItem('provider'));
				if ( provider ) setProvider(provider);

				let petitioner = JSON.parse(sessionStorage.getItem('petitioner'));
				if ( petitioner ) setPetitioner(petitioner);
			}
			getSessionFilters();

			let getDataFromAjax = async () => {
				let user = null;
				let userCreated = null;
				let community = null;
				let type = null;

				if ( user_id ) {
					await axios.get('/api/manager/users/get/' + user_id, {
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						user = {id: response.data.id, name: response.data.name};
					}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});	
				}

				if ( opener_id ) {
					await axios.get('/api/manager/users/get/' + opener_id, {
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						userCreated = {id: response.data.id, name: response.data.name};
					}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});	
				}

				if ( community_id ) {
					await axios.get('/api/manager/communities/get/' + community_id, {
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						community = {id: response.data.id, name: response.data.name};
					}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});	
				}

				if ( type_id ) {
					await axios.get('/api/manager/notices-types/get/' + type_id, {
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						type = {id: response.data.id, name: response.data.name};
					}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});	
				}

				if ( petitioner_type && petitioner_id ) {
					let petitioner = null;

					await axios.get('/api/manager/' + (petitioner_type) + 's/get/' + petitioner_id, {
						cancelToken: axiosCancelToken.token
					}).then((response) => {
						petitioner = {
							id 				: response.data.id,
							name 			: petitioner_type === 'provider' ? response.data.business_name : response.data.name,
							petitioner_type : petitioner_type,
							community 		: response.data.community
						}
					}).catch((error) => {
						if ( axios.isCancel(error) ) return;
					});	

					if ( petitioner ) selectPetitioner(petitioner);
				}

				if ( user ) setUser(user);
				if ( userCreated ) setUserCreated(userCreated);
				if ( community ) setCommunity(community);
				if ( type ) setType(type);
			}
			await getDataFromAjax();
			
			setTimeout(() => {
				setSessionLoaded(true);
			}, 500);
		}
		getDataAsync();
	}, [user_id, opener_id, community_id, type_id, petitioner_type, petitioner_id]);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (value) => {
		setCommunity({
			id: value?.id,
			name: value?.name
		});
	}

	const loadTypes = (input, callback) => {
		axios.get('/api/manager/notices-types/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectType = (value) => {
		setType({
			id: value?.id,
			name: value?.name
		});
	}

	const loadSources = (input, callback) => {
		axios.get('/api/manager/notices-sources/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectSource = (value) => {
		setSource({
			id: value?.id,
			name: value?.name
		});
	}

	const loadUsers = (input, callback) => {
		axios.get('/api/manager/users/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectUser = (value) => {
		setUser({
			id: value?.id,
			name: value?.name
		});
	}

	const selectUserCreated = (value) => {
		setUserCreated({
			id: value?.id,
			name: value?.name
		});
	}

	const loadProviders = (input, callback) => {
		axios.get('/api/manager/providers/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: <div>
								{el.business_name}
								<div><small>{el.brand_name}</small></div>
						   </div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectProvider = (value) => {
		setProvider({
			id: value?.id,
			name: value?.business_name
		});
	}

	const printPriority = (number) => {
		let elements = [];
		for(let i = 0; i<parseInt(number); i++) {
			elements.push(<i key={i} className="bi bi-star-fill text-warning"></i>)
		}
		return elements;
	}

	const loadPetitioners = (input, callback) => {
		axios.get('/api/manager/notices/petitioners-list', {
			params: {
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <PetitionerSelectRow>
								<div className="name">{el.name}</div>
								<div className="type">{getPetitionerFormatted(el.petitioner_type)}</div>
								<div className="community">{el.community?.name}</div>
						 	</PetitionerSelectRow>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectPetitioner = useCallback((value) => {
		if ( value?.petitioner_type === 'provider' ) value.petitioner_type = 'provider_petitioner'; // Fix needed to make a correct search on backend 

		let petitioner = {
			id 				: value?.id,
			name 			: value?.name,
			type 			: value?.petitioner_type,
			community_id 	: value?.community?.id
		};

		setPetitioner(petitioner);
	}, []);

	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	const openPopupWindowMouseDownFix = (e) => {
		e.preventDefault();
		e.stopPropagation();
		document.activeElement.blur();
	}

	const setDatesRange = (e, range) => {
		e.preventDefault();

		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'today':
				dateFrom = moment().format('YYYY-MM-DD');
				dateTo = moment().format('YYYY-MM-DD');
			break;

			case 'yesterday':
				dateFrom = moment().subtract(1, 'days').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'days').format('YYYY-MM-DD');
			break;

			case 'thisweek':
				dateFrom = moment().startOf('week').format('YYYY-MM-DD');
				dateTo = moment().endOf('week').format('YYYY-MM-DD');
			break;

			case 'lastweek':
				dateFrom = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');
			break;

			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;

			default:
		}

		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	return (
		<PopupLayout>
			<section>
				<div className="page-title">
					<h1>Avisos</h1>

					<div className="ms-auto">
						<NavLink to="/notices/add" className="btn btn-sm btn-light ms-3">Nuevo aviso</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-12">
							<div className="card border-0 shadow-sm">
								<div className="card-header bg-white p-3">
									<div className="row">

										<div className="col-md-3 col-xl-2 mb-2 mb-xl-0">
											<EmpoweredSelector
												load={loadCommunities}
												onChange={(value) => selectCommunity(value)}
												timeout={250}
												label={community?.name ?? ''}
												placeholder="- Comunidad -"
												showPlaceholderHelper={true}
												value={community?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-xl-0">
											<EmpoweredSelector
												load={loadUsers}
												onChange={(value) => setResponsible({id: value?.id, name: value?.name})}
												timeout={250}
												label={responsible?.name ?? ''}
												placeholder="- Responsable comunidad -"
												showPlaceholderHelper={true}
												value={responsible?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-xl-0">
											<EmpoweredSelector
												load={loadSources}
												onChange={(value) => selectSource(value)}
												timeout={250}
												label={source?.name ?? ''}
												placeholder="- Procedencia -"
												showPlaceholderHelper={true}
												value={source?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-xl-0">
											<EmpoweredSelector
												load={loadTypes}
												onChange={(value) => selectType(value)}
												timeout={250}
												label={type?.name ?? ''}
												placeholder="- Tipo -"
												showPlaceholderHelper={true}
												value={type?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-xl-0">
											<CustomSelect label="Origen" className="form-control form-control-sm" value={character ?? ''} onChange={(e) => setCharacter(e.target.value)}>
												<option value=""></option>
												<option value="community">Comunitario</option>
												<option value="private">Privativo</option>
												<option value="external">Externo</option>
											</CustomSelect>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-xl-0">
											<CustomSelect label="Estado" className="form-control form-control-sm" value={status ?? ''} onChange={(e) => setStatus(e.target.value)}>
												<option value="">Todos</option>
												<option value="" disabled className="text-center sbold">Independientes</option>
												<option value="pending">Pendiente</option>
												<option value="progress">Progreso</option>
												<option value="expired">Vencido</option>
												<option value="closed">Cerrado</option>
												<option value="" disabled className="text-center sbold">Agrupados</option>
												<option value="pending,expired">Pendiente / Vencido</option>
												<option value="pending,progress,expired">Pendiente / Progreso / Vencido</option>
												<option value="expired,closed">Vencido / Cerrado</option>
											</CustomSelect>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-md-0 mt-xl-2">
											<EmpoweredSelector
												load={loadProviders}
												onChange={(value) => selectProvider(value)}
												timeout={250}
												label={provider?.name ?? ''}
												placeholder="- Proveedor -"
												showPlaceholderHelper={true}
												value={provider?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-md-0 mt-xl-2">
											<EmpoweredSelector
												load={loadUsers}
												onChange={(value) => selectUser(value)}
												timeout={250}
												label={user?.name ?? ''}
												placeholder="- Gestor -"
												showPlaceholderHelper={true}
												value={user?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-2 mb-md-0 mt-xl-2">
											<EmpoweredSelector
												load={loadUsers}
												onChange={(value) => selectUserCreated(value)}
												timeout={250}
												label={userCreated?.name ?? ''}
												placeholder="- Creadas por -"
												showPlaceholderHelper={true}
												value={userCreated?.id}
											/>
										</div>

										<div className="col-md-3 col-xl-2 mb-md-0 mt-xl-2">
											<CustomSelect label="Mostrar avisos y tareas internas" className="form-control form-control-sm" value={internalTask ?? ''} onChange={(e) => setInternalTask(e.target.value)}>
												<option value=""></option>
												<option value="false">Mostrar sólo avisos</option>
												<option value="true">Mostrar sólo tareas internas</option>
											</CustomSelect>
										</div>

										<div className="col-md-3 col-xl-2 mb-md-0 mt-xl-2">
											<CustomInput label="Buscar" type="search" className="form-control form-control-sm" onChange={(e) => setSearch(e.target.value)} value={search ?? ''} />
										</div>

										<div className="col-md-3 col-xl-2 mb-md-0 mt-xl-2">
											<EmpoweredSelector
												load={loadPetitioners}
												onChange={(value) => selectPetitioner(value)}
												timeout={250}
												label={
													<div>
														{petitioner?.name ?? ''}&nbsp;
														{petitioner?.id &&
															<NavLink className="text-decoration-none text-reset" to={'/'+petitioner.type+'s/edit/' + petitioner.id + '?community_id=' + petitioner.community_id + '&popup=true'} onClick={(e) => openPopupInfoWindow(e)} onMouseDown={(e) => openPopupWindowMouseDownFix(e)}><i className="bi bi-eye text-primary"></i></NavLink>
														}
													</div>
												}
												placeholder="Solicitante"
												showPlaceholderHelper={true}
												value={petitioner?.id}
											/>
										</div>

										<div className="col-md-4 mt-2 mb-md-0 mt-xl-2">
											<div className="input-group input-group-sm">
												<span className="input-group-text">
													Fechas
												</span>
												<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
												<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
												<span className="input-group-btn">
													<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
													<ul className="dropdown-menu dropdown-menu-end">
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'today')}>Hoy</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'yesterday')}>Ayer</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thisweek')}>Esta semana</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastweek')}>Semana pasada</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thismonth')}>Este mes</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastmonth')}>Mes pasado</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'thisyear')}>Este año</a></li>
														<li><a className="dropdown-item" href="/" onClick={(e) => setDatesRange(e, 'lastyear')}>Año pasado</a></li>
													</ul>
												</span>
											</div>
										</div>
										<div className="col-md-2 mt-2 mb-md-0 mt-xl-2">
											<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>	
										</div>
									</div>
								</div>
								<div className="card-body p-0 pb-1">
									<div className="table-responsive table-responsive-carded">
										<Table className="table table-hover table-sortable table-carded">
											<thead>
												<tr>
													<ThSortable direction={sortDirection} active={sortField === 'openedat.pending'} onClick={() => sortTableClick('openedat.pending')}>Fecha</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'initat.pending'} onClick={() => sortTableClick('initat.pending')}>Inicio</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'expires_at'} onClick={() => sortTableClick('expires_at')}>Vencimiento</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'last.actiondate'} onClick={() => sortTableClick('last.actiondate')}>Última acción</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'priority'} onClick={() => sortTableClick('priority')}>Prioridad</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'community_id'} onClick={() => sortTableClick('community_id')}>Comunidad</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'user_id'} onClick={() => sortTableClick('user_id')}>Gestor</ThSortable>													
													<ThSortable direction={sortDirection} active={sortField === 'notice_source_id'} onClick={() => sortTableClick('notice_source_id')}>Procedencia</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'notice_type_id'} onClick={() => sortTableClick('notice_type_id')}>Tipo</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'reference'} onClick={() => sortTableClick('reference')}>Referencia</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'status'} onClick={() => sortTableClick('status')}>Estado</ThSortable>
													<ThSortable direction={sortDirection} active={sortField === 'title.fix'} onClick={() => sortTableClick('title.fix')}>Título</ThSortable>
													<th style={{width: '40px'}}></th>
												</tr>
											</thead>
											<tbody>
												{ notices.data?.length > 0 &&
													notices.data.map((el, idx) => {
														return ( 
															<React.Fragment key={idx}>
																<tr className="has-details" onClick={() => toggleDetails(el.id)}>
																	<td>
																		{ moment(el.created_at).format('DD-MM-YYYY') }
																		<small className="d-block">{ moment(el.created_at).format('HH:mm') }</small>
																	</td>
																	<td>
																		{el.init_at &&
																			<React.Fragment>
																				{ moment(el.init_at).format('DD-MM-YYYY') }
																				<small className="d-block">{ moment(el.init_at).format('HH:mm') }</small>
																			</React.Fragment>
																		}
																	</td>
																	<td>
																		{el.expires_at &&
																			<React.Fragment>
																				{ moment(el.expires_at).format('DD-MM-YYYY') }
																				<small className="d-block">{ moment(el.expires_at).format('HH:mm') }</small>
																			</React.Fragment>
																		}
																	</td>
																	<td>
																		{el.lastaction_date &&
																			<React.Fragment>
																				{ moment(el.lastaction_date).format('DD-MM-YYYY') }
																				&nbsp;<small>{ moment(el.lastaction_date).format('HH:mm') }</small>
																			</React.Fragment>
																		}
																		{!el.lastaction_date ? '-' : ''}
																	</td>
																	<td className="text-center">
																		{ printPriority(el.priority) }
																	</td>
																	<td>{el.community?.name}</td>
																	<td>{el.user?.name}</td>
																	<td>{el.source?.name}</td>
																	<td>{el.type?.name}</td>
																	<td>{el.reference}</td>
																	<td>
																		{el.status === 'pending' && <span className="badge bg-primary">Pendiente</span>}
																		{el.status === 'progress' && <span className="badge bg-orange">Progreso</span>}
																		{el.status === 'expired' && <span className="badge bg-danger">Vencido</span>}
																		{el.status === 'closed' && <span className="badge bg-success">Cerrado</span>}
																	</td>
																	<td>{el.title ?? (el.description ?? '-')}</td>
																	<td>
																		<NavLink className="btn btn-outline-primary me-2" to={'/notices/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
																		<NavLink 
																			className="btn btn-outline-secondary" 
																			to={'/notices/add?copy_from=' + el.id} 
																			onClick={(e) => {
																				e.stopPropagation();

																				if ( !window.confirm('¿Seguro que quieres copiar este aviso?') ) {
																					e.preventDefault();
																				}
																			}}
																		>
																			<i className="bi bi-copy"></i>
																		</NavLink>
																	</td>
																</tr>
																{ detailsOpened.includes(el.id) && 	
																	<tr className="details">
																		<td colSpan="100%">
																			<div className="row ms-0 me-0">
																				<div className="col-md-6">
																					<div>
																						<b className="sbold">Referencia</b>: {el.reference}
																					</div>
																					<div>
																						<b className="sbold">Fecha</b>:&nbsp; 
																						{ moment(el.created_at).format('DD-MM-YYYY') }
																						&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
																					</div>
																					{ el.expires_at &&
																						<div>
																							<b className="sbold">Fecha de vencimiento</b>:&nbsp; 
																							{ moment(el.expires_at).format('DD-MM-YYYY') }
																							&nbsp;<small>{ moment(el.expires_at).format('HH:mm') }</small>
																						</div>
																					}
																					<div>
																						<b className="sbold">Prioridad</b>:&nbsp;
																						{ printPriority(el.priority) }
																					</div>
																					<div className="mb-3">
																						<b className="sbold">Estado</b>:&nbsp;
																						{el.status === 'pending' && <span className="badge bg-primary">Pendiente</span>}
																						{el.status === 'progress' && <span className="badge bg-warning text-dark">Progreso</span>}
																						{el.status === 'expired' && <span className="badge bg-danger">Vencido</span>}
																						{el.status === 'closed' && <span className="badge bg-success">Cerrado</span>}
																					</div>

																					{ el.opener &&
																						<div>
																							<b className="sbold">Aviso abierto por</b>:&nbsp;
																							{el.opener?.name}
																						</div>
																					}
																					<div className="mb-3">
																						<b className="sbold">Gestor</b>:&nbsp;
																						{el.user?.name}
																					</div>

																					<div><b className="sbold">Tipo</b>:&nbsp;{el.type?.name ?? '-'}</div>																			
																					<div><b className="sbold">Comunidad</b>:&nbsp;{el.community?.name ?? '-'}</div>	

																					{ (el.community && el.community.importantowners && el.community.importantowners.length > 0) && 
																						<div className="mt-3">
																							<ImportantOwners owners={el.community.importantowners} community_id={el.community.id} />
																						</div>
																					}																		
																				</div>
																				<div className="col-md-6">
																					<div><b className="sbold">Solicitante</b>: {el.petitioner?.name ?? '-'}</div>
																					<div><b className="sbold">Teléfono</b>: {el.petitioner?.phone_default ?? '-'}</div>
																					<div className="mb-3"><b className="sbold">Email</b>: {el.petitioner?.email_default ?? '-'}</div>
																					
																					<div><b className="sbold">Descripción</b>: {el.description ?? '-'}</div>
																					<div className="mb-3"><b className="sbold">Instrucciones</b>: {el.instructions ?? '-'}</div>

																					{el.actions.length > 0 &&
																						<LabeledFrame 
																							className="mb-3"
																							label="Registro de acciones" 
																							buttonsBackground={'primary'}
																						>	
																							{el.actions.map((aEl, idx) => {
																								return (
																									<Action key={idx} type={aEl.type}>
																										<div className="date">{moment(aEl.date).format('DD-MM-YYYY')} a las {moment(aEl.date).format('HH:mm')}</div>
																										<div className="user">{aEl.user?.name}</div>
																										<div className="description">{aEl.description}</div>
																									</Action>
																								);
																							})}
																						</LabeledFrame>
																					}
																				</div>
																			</div>
																		</td>
																	</tr>
																}
															</React.Fragment>
														);
													})
												}

												{ notices.data && !notices.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

												{ notices.data === undefined && <TrSkeleton rows={skeletonRows} columns={13} /> }
											</tbody>
										</Table>
									</div>
								</div>
								<div className="card-footer d-flex justify-content-end" id="footer-fixed">
									<div className="d-inline-block">
										<Paginator
											min={1}
											current={notices?.current_page}
											max={notices?.last_page}
											changeCallback={(page) => setPage(page)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'notices'}
					fileName={'Avisos ' + moment().format('DD-MM-YYYY HH:mm')}
					excelAvailable={false}
					fields={{
						opened_at: 					'Fecha',
						expires_at: 				'Fecha de vencimiento',
						last_action:				'Última acción',
						priority: 					'Prioridad',
						community: 					'Comunidad',
						user: 						'Gestor',
						source:						'Procedencia',
						type:						'Tipo',
						reference:					'Referencia',
						status:						'Estado',
						title:						'Título',
					}}
					filters={{
						page: 						page,
						search: 					searchFix,
						community_id: 				community?.id,
						responsible_id: 			responsible?.id,
						status: 					status,
						type_id: 					type?.id,
						source_id: 					source?.id,
						character: 					character,
						user_id: 					user?.id,
						provider_id: 				provider?.id,
						sort: 						sortField,
						direction: 					sortDirection,
						internal_task: 				internalTask,
						created_by_id: 				userCreated?.id,	
						date_from:					dateFrom,
						date_to:					dateTo
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</PopupLayout>
	);
}


