import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import ExportModal from 'components/ExportModal';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';

const Table = styled.table`
	tr {
		cursor: pointer;
	}

	th,
	td {
		&:nth-child(1) {
			width: 30px;
			white-space: nowrap;
		}

		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6) {
			width: 100px;
		}

		&:nth-child(7) {
			width: 40px;
		}
	}	

`;


let axiosCancelToken = null;

export default function List(props) {
	const [queryParams] = useSearchParams();
	let navigate = useNavigate();

	const filters = props.filters;
	const returnFilters = props.returnFilters;

	const search = props.search;
	const community_id = props.communityId;
	const sectorsId = props.sectorsId;
	const showExportModal = props.showExportModal;
	const exportModalCloseCallback = props.exportModalCloseCallback;

	let [contracts, setContracts] = useState({});
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'date');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setContracts({...contracts, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getContracts = async () => {
			let params = {
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				community_id: community_id,
				sectors_id: sectorsId,
			};
			returnFilters(params);

			await axios.get('/api/manager/contracts/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setContracts({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getContracts();
	}, [page, search, sortField, sortDirection, sectorsId, community_id]);

	return (
		<>
			<div className="card-body p-0">
				<div className="table-responsive table-responsive-carded">
					<Table className="table table-hover table-sortable table-carded">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'community.name'} onClick={() => sortTableClick('community.name')}>Comunidad</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Código</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'provider.name'} onClick={() => sortTableClick('provider.name')}>Proveedor</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'sector.name'} onClick={() => sortTableClick('sector.name')}>Sector</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Comienza el</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'due_date'} onClick={() => sortTableClick('due_date')}>Finaliza el</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'status.number'} onClick={() => sortTableClick('status.number')}>Estado</ThSortable>
								<th style={{width: '40px'}}></th>
							</tr>
						</thead>
						<tbody>
							{ contracts.data?.length > 0 &&
								contracts.data.map((el, idx) => {
									return ( 
										<React.Fragment key={'c'+idx}>
											<tr onClick={() => navigate('/contracts/edit/' + el.id)}>
												<td>{el.community?.name ?? '-'}</td>
												<td>{el.number ?? '-'}</td>
												<td>{el.provider?.business_name ?? '-'}</td>
												<td>{el.provider?.sectors?.map(sEl => <span key={'s' + sEl.id} className="badge bg-light text-dark">{sEl.name}</span>)}</td>
												<td>{ moment(el.date).format('DD-MM-YYYY') }</td>
												<td>{ el.due_date ? moment(el.due_date).format('DD-MM-YYYY') : '' }</td>
												<td>
													<div className={'text-white text-center bg-' + (el.status ? 'success' : 'danger')}>
														{ el.status ? 'Vigente' : 'No vigente' }
													</div>	
												</td>
												<td>
													<NavLink className="btn-table" to={'/contracts/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
												</td>
											</tr>
										</React.Fragment>
									);
								})
							}

							{ contracts.data && !contracts.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ contracts.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
						</tbody>
					</Table>
				</div>
			</div>
			<div className="card-footer p-3 d-flex justify-content-end">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={contracts?.current_page}
						max={contracts?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>

			{ showExportModal &&
				<ExportModal
					exportKey={'contracts'}
					fileName={'Contratos ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						code: 					'Código',
						provider: 				'Proveedor',
						community:				'Comunidad',
						date: 					'Fecha',
						due_date: 				'Fecha vencimiento',
						status:					'Estado',
					}}
					filters={{
						// page: 					page,
						search: 				search,
						sort: 					sortField,
						direction: 				sortDirection,
						community_id: 			community_id,
						no_paginate:			true
					}}
					closeCallback={() => exportModalCloseCallback()}
				/>
			}
		</>
	);
}

