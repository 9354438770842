import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import { getCommunity } from 'helpers/community';
import { getFromSession, appendToSession } from 'helpers/session';
import ExportModal from 'components/ExportModal';

const Table = styled.table`
	tr {
		cursor: pointer;
	}

	th,
	td {
		&:nth-child(2),
		&:nth-child(3), 
		&:nth-child(6) {
			width: 100px;
		}

		&:nth-child(4),
		&:nth-child(5) {
			width: 60px;
		}

		&:nth-child(7) {
			width: 40px;
		}
	}	
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Meetings() {
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();

	let filters = getFromSession('meetings-list');

	let [meetings, setMeetings] = useState({});
	let [search, setSearch] = useState(filters?.search ?? undefined);
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'desc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'date');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	let [showExportModal, setShowExportModal] = useState(false);
	const setPage = (page) => {
		setMeetings({...meetings, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getMeetings = async () => {
			let params = {
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				community_id: getCommunity()?.id
			};
			appendToSession('meetings-list', params);

			await axios.get('/api/manager/meetings/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setMeetings({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getMeetings();
	}, [page, search, sortField, sortDirection]);

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Juntas</h1>
					
					<NavLink to="/meetings/add" className="btn btn-sm btn-light ms-auto">Crear nueva junta</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
								<div className="col-md-7 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									<button className="btn btn-sm btn-light me-2" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'title'} onClick={() => sortTableClick('title')}>Título</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'type'} onClick={() => sortTableClick('type')}>Tipo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'first_announcement'} onClick={() => sortTableClick('first_announcement')}>1ªC.</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'second_announcement'} onClick={() => sortTableClick('second_announcement')}>2ªC.</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'summoner'} onClick={() => sortTableClick('summoner')}>Convocada</ThSortable>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ meetings.data?.length > 0 &&
											meetings.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'c'+idx}>
														<tr onClick={() => navigate('/meetings/edit/' + el.id)}>
															<td>{el.title ?? '-'}</td>
															<td>{el.type === 'ordinary' ? 'Ordinaria' : 'Extraordinaria'}</td>
															<td>{ moment(el.date).format('DD-MM-YYYY') }</td>
															<td>{el.first_announcement ?? '-'}</td>
															<td>{el.second_announcement ?? '-'}</td>
															<td>{el.summoner === 'president' ? 'Presidente' : 'Administrador'}</td>
															<td>
																<NavLink className="btn-table" to={'/meetings/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ meetings.data && !meetings.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ meetings.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={meetings?.current_page}
									max={meetings?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'meetings'}
					fileName={'Juntas ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						title: 					'Título',
						type: 					'Tipo',
						date: 					'Fecha',
						first_announcement: 	'Primera convocatoria',
						second_announcement:	'Segunda convocatoria',
						summoner:				'Convocada por',
					}}
					filters={{
						page: 					page,
						search: 				search,
						sort: 					sortField,
						direction: 				sortDirection,
						community_id: 			getCommunity()?.id,
						no_paginate:			true
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</LoggedLayout>
	);
}


