import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import EmpoweredSelector from 'components/EmpoweredSelector';
import TreeRow from './TreeRow';

const TreeStyled = styled.div`
	width: 100%;
	height: 100%;
	padding: 10px;
	overflow-y: auto;

	background: var(--bs-light);

	.empowered-selector {
		margin-bottom: 10px;

		.empowered-selector_label {
			background: white;
		}
	}
`;



let axiosCancelToken = null;

export default function Tree(props) {
	let community = props.community;
	let setCommunity = props.setCommunity;
	let reload = props.reload;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const loadCommunities = (input, callback) => {
    	axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: (
						<div className="d-flex align-items-center">
							<span className="badge bg-secondary text-white me-1">{el.name_short}</span>
							{el.name}
						</div>
					)
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
    }

	const openFolder = (el) => {
		props.openFolder(el);

		// Dropdown if closed
		// if ( el && openedIds.indexOf(el.id) === -1 ) dropdownClick(null, el.id, true);
	}

	// const generateTreeRecursive = (tree, level = 0) => {
	// 	let elements = [];
	// 	tree.forEach((el, idx) => {

	// 		// FIX: If communities folder and community selected
	// 		if ( el.code === 'communities' && community?.id ) {
	// 			el.name = 'Documentos comunidad';
	// 			el.id = community?.folder_id;
	// 			el.has_content = false;
	// 			el.subfolders = null;
	// 		}

	// 		elements.push(
	// 			<TreeRow key={idx}>
	// 				<TreeLink className={el.id === props.folder?.id ? 'selected' : ''} level={level} onClick={() => openFolder(el)}>
	// 					<div className="btn-dropdown-content">
	// 						{ el.has_content &&
	// 							<React.Fragment>
	// 								{ openedIds.indexOf(el.id) === -1 ?
	// 									<button onClick={(e) => dropdownClick(e, el.id, true)}><i className="bi bi-caret-right"></i></button>
	// 									:
	// 									<button onClick={(e) => dropdownClick(e, el.id, false)}><i className="bi bi-caret-down"></i></button>
	// 								}
	// 							</React.Fragment>
	// 						}
	// 					</div>
	// 					<div className="icon">
	// 						<i className="bi bi-folder-fill"></i>
	// 					</div>
	// 					<div className="name">{el.name}</div>
	// 				</TreeLink>

	// 				{ openedIds.indexOf(el.id) !== -1 &&
	// 					<TreeContent>
	// 						{ el.subfolders && el.subfolders.length > 0 ? generateTreeRecursive(el.subfolders, level+1) : '' }
	// 					</TreeContent>
	// 				}					
	// 			</TreeRow>
	// 		);
	// 	});
	// 	return elements;
	// }

	return (
		<TreeStyled onDrop={(e) => e.preventDefault()} onDragOver={(e) => e.preventDefault()}>
			<EmpoweredSelector
				tabIndex="-1"
				load={(input, callback) => loadCommunities(input, callback)}
				onChange={(value) => setCommunity(value)}
				timeout={250}
				label={
					(community && community.id) ? 
						<div className="community_selected_label">
							<span className="name_short badge bg-secondary text-light me-1">{community.name_short}</span>
							<span className="name">{community.name}</span>
						</div>
					: 
						null
				}
				placeholder="Seleccionar comunidad"
				value={community?.id ?? null}
				preventClickPropagation={true}
			/>

			<TreeRow 
				folder={{
					id: 			-1,
					name: 			'Documentos',
					code: 			'documents',
					has_content: 	true
				}}
				openFolder={openFolder}
				selectedFolderId={props.folder?.id}
				community={community}
				reload={reload}
				level={0}
			/>

			{/* { generateTreeRecursive(tree) } */}
		</TreeStyled>
	);
}

