import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import { CristalLoader } from 'helpers/generic';
import EmpoweredSelector from 'components/EmpoweredSelector';

let axiosCancelToken = null;

export default function UsersForm(props) {
	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		company_role: 'principal'
	});
	let [roles, setRoles] = useState([]);
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(true);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getRoles = async () => {
			await axios.get('/api/superadmin/users/list-roles', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setRoles(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
			setLoading(false);
		}
		getRoles();

		if ( params.id ) {
			const getData = async () => {
				setLoading(true);
				setData([]);

				await axios.get('/api/superadmin/users/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
					let data = response.data;
			    	setData({
			    		...data, 
			    		company_name: data.company ? data.company.id + '# ' + data.company.name : ''
			    	});
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		}
	}, [params.id]);

	const deleteUser = () => {
		const c = window.confirm('¿Quieres eliminar este usuario?');
		if ( !c ) return;

		axios.delete('/api/superadmin/users/delete/' + params.id, {}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Usuario borrada')
	    	navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true) => {
		setErrors({});

		axios.post('/api/superadmin/users/save' + (data.id ? '/' + data.id : ''), data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( goBack ) navigate(-1);
			else {
				let data = response.data.user;
		    	setData({
		    		...data, 
		    		company_name: data.company ? data.company.id + '# ' + data.company.name : ''
		    	});
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const loadCompanies = (input, callback) => {
    	axios.get('/api/superadmin/companies/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.id + '# ' + el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
    }

    const selectCompany = (id, name) => {
    	setData({
    		...data, 
    		company_name: name,
    		company_id: id
    	});
    }

	return (
		<LoggedLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Usuarios</h1>
					<button onClick={() => navigate(-1)} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-5">
											<div className="mb-2">
												<label>Nombre</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-3">
											<div className="mb-2">
												<label>Rol</label>
												<select className="form-control form-control-sm" onChange={(e) => setDataField('role', e.target.value)} value={data.role ?? ''}>
													<option value=""></option>
													{ Object.entries(roles).map((el, idx) => {
														return <option key={idx} value={el[0]}>{el[1]}</option>;
													}) }
												</select>
												{ errors.role &&
													<div className="invalid-feedback d-block">{ errors.role[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Empresa</label>
												<EmpoweredSelector
													load={(input, callback) => loadCompanies(input, callback)}
													onChange={(value, text) => selectCompany(value, text)}
													timeout={250}
													label={data.company_name ?? ''}
													placeholder=""
													value={data.company_id}
												/>
												{ errors.company_id &&
													<div className="invalid-feedback d-block">{ errors.company_id[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>DNI</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('vatnumber', e.target.value)} value={data.vatnumber ?? ''} />
												{ errors.vatnumber &&
													<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Email</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} />
												{ errors.email &&
													<div className="invalid-feedback d-block">{ errors.email[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Teléfono</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('phone', e.target.value)} value={data.phone ?? ''} />
												{ errors.phone &&
													<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
												}
											</div>
										</div>
										
										<div className="col-md-8">
											<div className="mb-2">
												<label>Dirección</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} />
												{ errors.address &&
													<div className="invalid-feedback d-block">{ errors.address[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Código postal</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} />
												{ errors.postalcode &&
													<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Ciudad</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} />
												{ errors.city &&
													<div className="invalid-feedback d-block">{ errors.city[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>Provincia</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} />
												{ errors.province &&
													<div className="invalid-feedback d-block">{ errors.province[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<label>País</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} />
												{ errors.country &&
													<div className="invalid-feedback d-block">{ errors.country[0] }</div>
												}
											</div>
										</div>

										<div className="col-md-12 mt-4"></div>

										<div className="col-md-4">
											{ data.role === 'manager' &&
												<div className="form-check form-switch">
													<input className="form-check-input" type="checkbox" id="principal" onChange={(e) => setDataField('company_role', e.target.checked ? 'principal' : null)} checked={data.company_role === 'principal'} />
													<label className="form-check-label" htmlFor="principal">Gestor principal de la empresa</label>
												</div>
											}
										</div>

										<div className="col-md-4"></div>

										<div className="col-md-4">
											<div className="mb-2">
												<label>Contraseña</label>
												<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('password', e.target.value)} value={data.password ?? ''} />
												{ data.id &&
													<small className="text-secondary d-block" style={{fontSize: '10px', lineHeight: '12px'}}>Dejar en blanco para no cambiar la contraseña</small>
												}
												{ errors.password &&
													<div className="invalid-feedback d-block">{ errors.password[0] }</div>
												}
											</div>
										</div>
									</div>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6">
											{ data.id &&
												<button className="btn btn-sm btn-link text-danger" onClick={() => deleteUser()}>Eliminar</button>							
											}
										</div>
										<div className="col-6 text-end">
											{ data.id &&
												<span className="fw-bold text-danger">ID: { data.id }</span>
											}
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}