import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import EmpoweredSelector from 'components/EmpoweredSelector';

const Table = styled.table`
	th,td {
		@media (min-width: 768px) {
			&:nth-child(1),
			&:nth-child(2) {
				width: 40px;
			}

			&:nth-child(4),
			&:nth-child(5),
			&:nth-child(6) {
				width: 150px;
			}

			&:nth-child(7) {
				width: 40px;
			}
		}
		
		@media (max-width: 768px) {
			&:nth-child(2),
			&:nth-child(4),
			&:nth-child(4),
			&:nth-child(6) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Companies() {
	const [queryParams] = useSearchParams();

	let [users, setUsers] = useState({});
	let [search, setSearch] = useState(undefined);
	let [company, setCompany] = useState({
		id: undefined,
		name: undefined
	});
	let [roles, setRoles] = useState({});
	let [role, setRole] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('id');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setUsers({...users, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const loadCompanies = (input, callback) => {
    	axios.get('/api/superadmin/companies/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.id + '# ' + el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
    }

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getRoles = async () => {
			await axios.get('/api/superadmin/users/list-roles', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setRoles(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getRoles();
	}, []);

	useEffect(() => {
		const getUsers = async () => {
			await axios.get('/api/superadmin/users/list', {
				params: {
					page: page,
					search: search,
					company_id: company.id,
					role: role,
					sort: sortField,
					direction: sortDirection,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setUsers({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getUsers();
	}, [page, search, sortField, sortDirection, company.id, role]);

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Usuarios</h1>

					<NavLink to="/users/add" className="btn btn-sm btn-light ms-auto">Nuevo usuario</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={(input, callback) => loadCompanies(input, callback)}
										onChange={(value, text) => setCompany({id: value, name: text})}
										timeout={250}
										label={company.name ?? ''}
										placeholder="- Empresas -"
										value={company.id}
									/>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setRole(e.target.value)} value={role ?? ''}>
										<option value="">- Roles -</option>
										{ Object.entries(roles).map((el, idx) => {
											return <option key={idx} value={el[0]}>{el[1]}</option>;
										}) }
									</select>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'id'} onClick={() => sortTableClick('id')}>#</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'role'} onClick={() => sortTableClick('role')}>Rol</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'vatnumber'} onClick={() => sortTableClick('vatnumber')}>CIF</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'company.name'} onClick={() => sortTableClick('company.name')}>Empresa</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Fecha</ThSortable>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ users.data?.length > 0 &&
											users.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr className="has-details" onClick={() => toggleDetails(el.id)}>
															<td>{el.id}</td>
															<td>
																<div className="d-flex align-items-center">
																	<span className="badge bg-light text-secondary">{roles[el.role]}</span>
																</div>
															</td>
															<td>{el.name}</td>
															<td>{el.vatnumber}</td>
															<td>{el.company?.name}</td>
															<td>
																{ moment(el.created_at).format('DD-MM-YYYY') }
																&nbsp;<small>{ moment(el.created_at).format('HH:mm') }</small>
															</td>
															<td>
																<NavLink className="btn-table" to={'/users/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
														{ detailsOpened.includes(el.id) && 	
															<tr className="details">
																<td colSpan="100%">
																	<div className="row ms-0 me-0">
																		<div className="col-md-4">
																			<div><b className="sbold">Identificador</b>: {el.id ?? '-'}</div>
																			<div><b className="sbold">Email</b>: {el.email ?? '-'}</div>
																			<div><b className="sbold">Nombre</b>: {el.name ?? '-'}</div>
																			<div><b className="sbold">CIF</b>: {el.vatnumber ?? '-'}</div>
																			<div><b className="sbold">Dirección</b>: {el.address ?? '-'}</div>
																			<div><b className="sbold">Código postal</b>: {el.postalcode ?? '-'}</div>
																			<div><b className="sbold">Ciudad</b>: {el.city ?? '-'}</div>
																			<div><b className="sbold">Provincia</b>: {el.province ?? '-'}</div>
																			<div><b className="sbold">País</b>: {el.country ?? '-'}</div>
																		</div>
																		<div className="col-md-8">
																			<div><b className="sbold">Rol</b>: {roles[el.role]}</div>
																			<div><b className="sbold">Empresa</b>: {el.company?.name ?? '-'}</div>
																		</div>
																	</div>
																</td>
															</tr>
														}
													</React.Fragment>
												);
											})
										}

										{ users.data && !users.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ users.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={users?.current_page}
									max={users?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


