import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import PopupLayout from 'layouts/PopupLayout';

const SectionStyled = styled.section`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.page-content {
		height: 100%;
	}

	#buttons-wrapper {

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;

		a {
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 300px;

			&:first-of-type {
				margin-bottom: 40px;
			}

			font-size: 25px;
			padding: 20px 50px;
			text-transform: uppercase;
			font-weight: bold;
			color: #333;

			i {
				position: absolute;
				left: 20px;
				font-size: 40px;
				line-height: 25px;
				margin-right: 10px;
			}
		}
	}
`;

export default function IORegistrySelectType() {
	const navigate = useNavigate();
	
	return (
		<PopupLayout>
			<SectionStyled>
				<div className="page-title">
					<h1>Registro de entrada y salida</h1>
				</div>

				<div className="page-content">
					<div className="row justify-content-center h-100">
						<div className="col-md-12">
							<div className="card border-0 h-100">
								<div className="card-body">
									<div id="buttons-wrapper">
										<NavLink to="/ioregistry/in" className="btn btn-lg btn-light"><i className="bi bi-box-arrow-in-down"></i> Entradas</NavLink>
										<NavLink to="/ioregistry/out" className="btn btn-lg btn-light"><i className="bi bi-box-arrow-in-up"></i> Salidas</NavLink>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</SectionStyled>
		</PopupLayout>
	);
}


