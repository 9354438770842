import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import { CristalLoader } from 'helpers/generic';
import useStore from "store";
import { readableSize } from 'helpers/generic';
import LabeledFrame from 'components/LabeledFrame';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

const ImageWrapper = styled.div`
	.image-square {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: var(--bs-gray-200);	
		margin-top: 10px;
		width: 100px;
		min-height: 100px;
		cursor: pointer;
		border-radius: 3px;

		img {
			display: block;
			width: 100%;
		}

		span {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			font-size: 10px;
		}

		i {
			position: absolute;
			top: 5px;
			right: 5px;
			display: inline-flex;
			padding: 2px 5px;
			color: white;
			background: var(--bs-danger);
			border-radius: 3px;
			font-size: 11px;

			&:hover {
				opacity: 0.9;
				transform: translateY(-1px);
			}
		}

		input[type=file] {
			width: 0;
			height: 0;
			opacity: 0;
		}
	}
`;

let axiosCancelToken = null;

export default function Company(props) {
	const navigate = useNavigate();
	const params = useParams();
	const logoFileInput = useRef(null);

	const config = useStore(state => state.config);

	let [data, setData] = useState({
		status: 1
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(params.id ? true : false);
	let [logo, setLogo] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const getData = async () => {
			setData([]);

			await axios.get('/api/manager/company/get', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				let data = {...response.data};
		    	setData(data);
		    	setLogo(data.logo_base64_url);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});

			setLoading(false);
		}
		getData();
		
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const saveData = (goBack = true) => {
		setErrors({});

		let formData = new FormData();
		for(let i in data) formData.append(i, data[i] ?? '');
		formData.delete('logo_base64_url'); // Fix

		if ( data.smtp && Object.keys(data.smtp).length > 0 ) {
			for(let smtpIdx in data.smtp) {
				formData.append('smtp['+smtpIdx+']', data.smtp[smtpIdx]);
			}
		} else formData.delete('smtp');

		axios.post('/api/manager/company/save', formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			if ( goBack ) navigate(-1);
			else {
				setData({...data, ...response.data.company});
		    	setLogo(response.data.company.logo_base64_url);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	const openLogoFileInput = () => {
		logoFileInput.current.value = null;
		logoFileInput.current.click();
	}

	const loadLogo = async (file) => {
		// Reset
		setDataField('logofile', null);
		setLogo(null);

		// Check if exists the file
        if ( !file ) return false;

        // Check extension
        var allowed = 'png jpg jpeg gif bmp';
        var extension = file.type.replace('image/', '');
        if ( !extension.length || allowed.indexOf(extension) === -1 ) {
            toast.error('Extensión no válida. Solo se permiten ficheros: png, jpg, gif y bmp');
            return false;
        }

        // Check size
        if ( file.size > config.upload_max_filesize ) {
            toast.error('El tamaño del fichero es superior al permitido ('+readableSize(config.upload_max_filesize)+')');
            return false;
        }

        // Read image and show
        let base64image = '';
        await new Promise((resolve) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(base64image = reader.result);
			reader.readAsDataURL(file);
		});
		setLogo(base64image);

        // Set data
        setDataField('logofile', file);
	}

	const removeLogo = (e) => {
		e.stopPropagation();
		setDataField('logofile', 'remove');
		setLogo(null);
	}

	const setSmtpDataField = (field, value) => {
		let smtp = data.smtp ? {...data.smtp} : {};
		smtp[field] = value;
		setDataField('smtp', smtp);
	}

	return (
		<LoggedLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de empresa</h1>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<form className="row" autocomplete="off" method="post" action=""> {/* Fix Chrome autofill */}
										<input autocomplete="false" name="password" type="password" className="d-none" /> {/* Fix Chrome autofill */}

										<div className="col-md-8">
											<div className="mb-2">
												<CustomInput label="Nombre / Razón social" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('name', e.target.value)} value={data.name ?? ''} />
												{ errors.name &&
													<div className="invalid-feedback d-block">{ errors.name[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<CustomInput label="CIF" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('vatnumber', e.target.value)} value={data.vatnumber ?? ''} />
												{ errors.vatnumber &&
													<div className="invalid-feedback d-block">{ errors.vatnumber[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<CustomInput label="Dirección" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('address', e.target.value)} value={data.address ?? ''} />
												{ errors.address &&
													<div className="invalid-feedback d-block">{ errors.address[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<CustomInput label="Código postal" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('postalcode', e.target.value)} value={data.postalcode ?? ''} />
												{ errors.postalcode &&
													<div className="invalid-feedback d-block">{ errors.postalcode[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<CustomInput label="Ciudad" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('city', e.target.value)} value={data.city ?? ''} />
												{ errors.city &&
													<div className="invalid-feedback d-block">{ errors.city[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<CustomInput label="Provincia" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('province', e.target.value)} value={data.province ?? ''} />
												{ errors.province &&
													<div className="invalid-feedback d-block">{ errors.province[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<CustomInput label="País" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('country', e.target.value)} value={data.country ?? ''} />
												{ errors.country &&
													<div className="invalid-feedback d-block">{ errors.country[0] }</div>
												}
											</div>
										</div>

										<div className="col-md-12 mt-4"></div>

										<div className="col-md-5">
											<div className="mb-2">
												<CustomInput label="Persona de contacto" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('contact_person', e.target.value)} value={data.contact_person ?? ''} />
												{ errors.contact_person &&
													<div className="invalid-feedback d-block">{ errors.contact_person[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-3">
											<div className="mb-2">
												<CustomInput label="Teléfono" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('phone', e.target.value)} value={data.phone ?? ''} />
												{ errors.phone &&
													<div className="invalid-feedback d-block">{ errors.phone[0] }</div>
												}
											</div>
										</div>
										<div className="col-md-4">
											<div className="mb-2">
												<CustomInput label="Email" type="text" className="form-control form-control-sm" onChange={(e) => setDataField('email', e.target.value)} value={data.email ?? ''} />
												{ errors.email &&
													<div className="invalid-feedback d-block">{ errors.email[0] }</div>
												}
											</div>
										</div>

										<div className="col-md-12 mt-4"></div>

										<div className="col-md-8"></div>

										<div className="col-md-4">
											<ImageWrapper>
												<label className="d-block">Logotipo</label>
												{ errors.logofile &&
													<div className="invalid-feedback d-block">{ errors.logofile[0] }</div>
												}
												<div>
													<div className="image-square" onClick={() => !logo ? openLogoFileInput() : ''}>
														<input type="file" ref={logoFileInput} onChange={(e) => loadLogo(e.target.files[0])} />

														{ logo ? 
															<React.Fragment>
																<img src={logo} alt="logo" />
																<i onClick={(e) => removeLogo(e)}>Eliminar logo</i>
															</React.Fragment> 
															: 
															
															<span className="text-center">Seleccionar</span> 
														}
													</div>
												</div>
											</ImageWrapper>
										</div>	
														
										<div className="col-md-12 mt-3">
											<LabeledFrame 
												label="Datos de servidor SMTP para envío de emails"
												buttons={
													<div className="form-check form-switch mb-0">
														<input className="form-check-input" type="checkbox" checked={data.smtp?.active ?? false} onChange={(e) => setSmtpDataField('active', e.target.checked)} value={data.smtp?.active ?? ''} />
														<label className="form-check-label">Activar</label>
													</div>
												}
											>
												<div className="row">
													<div className="col-md-4 mb-2">
														<CustomInput label="Servidor" type="text" className="form-control form-control-sm" onChange={(e) => setSmtpDataField('host', e.target.value)} value={data.smtp?.host ?? ''} disabled={!data.smtp?.active} />
														{ errors['smtp.host'] &&
															<div className="invalid-feedback d-block">{ errors['smtp.host'][0] }</div>
														}
													</div>
													<div className="col-md-4 mb-2">
														<CustomInput label="Puerto" type="text" className="form-control form-control-sm" onChange={(e) => setSmtpDataField('port', e.target.value)} value={data.smtp?.port ?? ''} disabled={!data.smtp?.active} />
														{ errors['smtp.port'] &&
															<div className="invalid-feedback d-block">{ errors['smtp.port'][0] }</div>
														}
													</div>
													<div className="col-md-4 mb-2">
														<CustomSelect label="Encriptación" className="form-control form-control-sm" onChange={(e) => setSmtpDataField('encryption', e.target.value)} value={data.smtp?.encryption ?? ''} disabled={!data.smtp?.active}>
															<option value="">No</option>
															<option value="tls">TLS</option>
															<option value="ssl">SSL</option>
														</CustomSelect>
														{ errors['smtp.encryption'] &&
															<div className="invalid-feedback d-block">{ errors['smtp.encryption'][0] }</div>
														}
													</div>
													<div className="col-md-6 mb-2">
														<CustomInput label="Usuario (email)" type="text" className="form-control form-control-sm" onChange={(e) => setSmtpDataField('username', e.target.value)} value={data.smtp?.username ?? ''} disabled={!data.smtp?.active}  />
														{ errors['smtp.username'] &&
															<div className="invalid-feedback d-block">{ errors['smtp.username'][0] }</div>
														}
													</div>
													<div className="col-md-6 mb-2">
														<CustomInput autocomplete="off" label="Contraseña" type="password" className="form-control form-control-sm" onChange={(e) => setSmtpDataField('password', e.target.value)} value={data.smtp?.password ?? ''} disabled={!data.smtp?.active}  />
														{ errors['smtp.password'] &&
															<div className="invalid-feedback d-block">{ errors['smtp.password'][0] }</div>
														}
													</div>
												</div>
											</LabeledFrame>
										</div>
									</form>
								</div>
								<div className="card-footer">
									<div className="row">
										<div className="col-6">
											
										</div>
										<div className="col-6 text-end">
											<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}