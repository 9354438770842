import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import FieldSmallComment from 'components/FieldSmallComment';
import { openPopupWindow } from 'helpers/generic';

const TableStyled = styled.table`
	width: 100%;
	font-size: 12px;

	thead {
		th {
			font-weight: normal;
			line-height: 30px;
			
			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3) {
				width: 40px;
			}

			&:nth-child(4),
			&:nth-child(5) {
				width: 70px;
			}
		}
	}

	tbody {
		td {
			line-height: 15px;

			&:not(:nth-child(1)) {
				text-align: center;
			}

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				text-align: center;
			}

			input[type=checkbox].chkbx {
				width: 15px;
				height: 15px;
			}	
		}
	}
`;

let axiosCancelToken = null;

export default function PermissionsTable(props) {

	let [users, setUsers] = useState([]);
	let readOnly = props.readOnly;

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const loadUsers = (input, callback) => {
	    	axios.get('/api/manager/users/list', {
				params: {
					search: input,
					no_paginate: true,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setUsers(response.data);
		  	}).catch((error) => {			
				if ( axios.isCancel(error) ) return;
			});	
	    }
	    loadUsers();
	}, []);

	const setPermission = (user_id, permission, status) => {
		let permissions = {...props.permissions};
		let user_permissions = permissions[user_id] ?? {};

		user_permissions[permission] = status;
		permissions[user_id] = user_permissions;

		props.setPermissions(permissions);
	}

	const openUserWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	let rows = [];
	if ( users ) users.forEach((el, idx) => {
		let user_permission = props.permissions && props.permissions[el.id] ? props.permissions[el.id] : {};

		// If principal user
		if ( el.company_role === 'principal' ) user_permission = {
			responsible: user_permission.responsible ?? false,
			view: true,
			add: true,
			edit: true,
			delete: true
		};

		rows.push(
			<tr key={idx}>
				<td>{el.name} <NavLink to={'/users/edit/' + el.id + '?popup=true'} onClick={(e) => openUserWindow(e)}><i className="bi bi-eye-fill"></i></NavLink></td>
				<td className="text-center">
					<div className="form-check form-switch d-inline-flex mb-0 pb-0" style={{minHeight: 0}}>
						<input disabled={readOnly} className="form-check-input" type="checkbox"  onChange={(e) => setPermission(el.id, 'responsible', e.target.checked)} checked={user_permission.responsible ?? false} />
					</div>	
				</td>
				<td><input disabled={readOnly} class="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'view', e.target.checked)} checked={user_permission.view ?? false} /></td>
				<td><input disabled={readOnly} class="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'add', e.target.checked)} checked={user_permission.add ?? false} /></td>
				<td><input disabled={readOnly} class="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'edit', e.target.checked)} checked={user_permission.edit ?? false} /></td>
				<td><input disabled={readOnly} class="chkbx" type="checkbox" onChange={(e) => setPermission(el.id, 'delete', e.target.checked)} checked={user_permission.delete ?? false} /></td>
			</tr>
		);
	});

	return (
		<React.Fragment>
			<TableStyled className="table table-sm table-bordered mb-2">
				<thead>
					<tr>
						<th>Usuario</th>
						<th>Responsable</th>
						<th>Ver</th>
						<th>Crear</th>
						<th>Modificar</th>
						<th>Eliminar</th>
					</tr>
				</thead>
				<tbody>
					{ rows }
				</tbody>
			</TableStyled>	
			<FieldSmallComment>Estos permisos se utilizan para que los usuarios puedan trabajar con ciertas secciones relacionadas con las comunidades. Por ejemplo: propietarios, inquilinos, propiedades...</FieldSmallComment>
		</React.Fragment>	
	);
}