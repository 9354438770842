import { downloadFile as downloadFileHelper, openPopupWindow } from "helpers/generic";
import axios from 'axios';

export default function DraftFileViewButton({ invoice }) {

    const buttonClick = () => {
        let url = '/api/manager/providers-invoices/download-draft-file/' + invoice.id + '?community_id=' + invoice.community_id;

        if ( invoice.draft_file_is_viewable ) {
            openPopup(url);
        } else {
            downloadFile(url);
        }
    }

    const openPopup = (url) => {
        // Open popup
        let popup = openPopupWindow('');

        // Set loading
        popup.document.body.innerHTML = '<div class="text-center">Cargando...</div>';

        // Download file
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            popup.location.href = blobUrl;
        }).catch((error) => {
            return error;
        });
    }

    const downloadFile = (url) => {
        downloadFileHelper(url);
    }

    return (
        <button className="btn btn-warning" onClick={buttonClick}>
            Revisar fichero subido <i className="bi bi-eye text-dark ms-1"></i>
        </button>
    )
}