import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import LabeledFrame from 'components/LabeledFrame';
import { openPopupWindow } from 'helpers/generic';

const TableResponsibles = styled.table`
	font-size: 12px;
	margin-bottom: 0;

	thead {
		tr {
			th {
				font-weight: 600;

				&:first-of-type {
					width: 100px !important;
				}
			}
		}
	}

	tbody {
		border-top-color: var(--bs-gray-400) !important;

		tr {
			td {
				border-bottom-width: 0;

				&:first-of-type {
					width: 100px !important;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Responsibles(props) {
    let [responsibles, setResponsibles] = useState([]);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
        if ( !props.community_id ) return;
        
        axios.get('/api/manager/users/list', {
            params: {
                community_id: props.community_id,
                no_paginate: true,
            },
            cancelToken: axiosCancelToken.token
        }).then((response) => {
            setResponsibles([...response.data]);
        }).catch((error) => {
            if ( axios.isCancel(error) ) return;
        });
    }, [props.community_id]);


	const openPopupInfoWindow = (e) => {
		e.preventDefault();

		openPopupWindow(e.currentTarget.href);
	}

	return (
        <React.Fragment>
            { responsibles?.length > 0 &&
                <LabeledFrame className="mb-3" label="Responsables de la comunidad">
                    <TableResponsibles className="table table-sm">
                        <thead>
                            <tr>
                                <th>Responsable</th>
                                <th>Teléfono</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {responsibles?.map((el, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>
                                            {el.name}&nbsp;
                                            <NavLink to={'/providers/edit/' + el.provider_id + '?popup=true'} onClick={(e) => openPopupInfoWindow(e)}><i className="bi bi-eye-fill"></i></NavLink>
                                        </td>
                                        <td>{el.phone}</td>
                                        <td>{el.email}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </TableResponsibles>
                </LabeledFrame>
            }
        </React.Fragment>
	);
}