import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import LabeledFrame from './LabeledFrame';
import { openPopupWindow } from 'helpers/generic';
import CustomInput from 'components/CustomInput';

const FieldsContainer = styled.div`
	
`;

const Field = styled.div`
	margin-bottom: 5px;
`;

let axiosCancelToken = null;

export default function CustomFieldsPanel(props) {
	// Get fields and format to json
	let fields = props.fields ? {...props.fields} : {};
	const setFields = props.setFields;
	const readOnly = props.readOnly ?? false;

	const [availableFields, setAvailableFields] = useState([]);
	const [forceReload, setForceReload] = useState(null);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => { 
			setForceReload(Math.random());
		};
	}, []);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getAvailableFields = async () => {
			await axios.get('/api/manager/custom-fields/'+props.type+'/list', {
				params: {
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setAvailableFields([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}
		getAvailableFields();
	}, [forceReload, props.type]);

	const setValue = (id, value) => {
		let newFields = {...fields};
		newFields[id] = value;
		setFields(newFields);
	}

	const openCustomFieldsWindow = (e) => {
		e.preventDefault();
    	openPopupWindow(e.currentTarget.href, 'customfields');
	}

	return (
		<LabeledFrame 
			className={props.className ?? ''}
			label={props.title ?? 'Campos personalizados'}
			buttons={
				!readOnly ?
					<React.Fragment>
						<NavLink to={'/custom-fields/' + props.type} onClick={(e) => openCustomFieldsWindow(e)} className="btn-unstyled text-primary text-decoration-none">
							<i className="bi bi-plus-circle-fill"></i>
						</NavLink>
					</React.Fragment>
					:
					null
			}
		>
			<FieldsContainer>
				{ availableFields?.length > 0 &&
					<React.Fragment>
						{availableFields.map((el, idx) => {
							return (
								<Field key={idx}>
									<div className="input-group">
										<CustomInput label={el.name} type="text" className="form-control form-control-sm" value={fields[el.id] ?? ''} onChange={(e) => setValue(el.id, e.target.value)} readOnly={readOnly} />
										{ el.fieldtype === 'link' &&
											<div class="input-group-append">
												<button className="btn btn-sm btn-primary text-white" onClick={() => window.open(fields[el.id])}><i className="bi bi-link"></i></button>
											</div>
										}
									</div>
								</Field>
							);
						})}
					</React.Fragment>
				}
			</FieldsContainer>
		</LabeledFrame>
	);
}