import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import PopupLayout from "layouts/PopupLayout";
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { formatNumber, openPopupWindow } from 'helpers/generic';
import { getCommunity } from 'helpers/community';
import EmpoweredSelector from 'components/EmpoweredSelector';
import ExportModal from 'components/ExportModal';

const Table = styled.table`
	& > thead > tr > th, 
	& > tbody > tr > td {
		&:nth-child(1) {
			width: 300px;
		}

		&:nth-child(2) {
			width: 50px;
			text-align: right;
		}

		&:nth-child(3) {
			width: 200px;
		}

		&:nth-child(4) {
			width: 300px;
		}

		&:nth-child(5) {
			white-space: nowrap;
		}

		&:nth-child(6) {
			width: 100px;
			text-align: right;
		}
	}

	tr {
		&.passed {
			opacity: 0.45;
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Receipts() {
	const [queryParams] = useSearchParams();

	let popup = queryParams.get('popup') === "true";
	let property_id = queryParams.get('property_id') ?? null;
	let payerable_type = queryParams.get('payerable_type') ?? null;
	let payerable_id = queryParams.get('payerable_id') ?? null;
	let hide_property_selector = queryParams.get('hide_property_selector') === "true";
	let hide_payerable_selector = queryParams.get('hide_payerable_selector') === "true";

	console.log(hide_property_selector);

	let community = getCommunity();
	let [forceReload, setForceReload] = useState(null);
	let [showExportModal, setShowExportModal] = useState(false);
	let [previsions, setPrevisions] = useState(undefined);
	let [estimate, setEstimate] = useState({});
	let [property, setProperty] = useState({
		id: property_id
	});
	let [payerable, setPayerable] = useState({
		id: payerable_id,
		type: payerable_type ? 'App\\Models\\' + payerable_type.substring(0, payerable_type.length-1) : null
	});
	let [search, setSearch] = useState(undefined);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('estimate');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setPrevisions({...previsions, data: undefined});
		_setPage(page);
	}

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	const loadEstimates = (input, callback) => {
		let params = {
			search: input,
			no_paginate: true,
			community_id: getCommunity()?.id,
		};

		axios.get('/api/manager/estimates/list', {
			params: params,
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				el.name = 'P. ' + (el.type === 'ordinary' ? 'Ordinario' : 'Extraordinario') + ' ' + el.number_full;
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadProperties = (input, callback, args) => {
		let params = {
			search: input,
			no_paginate: true,
			community_id: getCommunity()?.id,
			owner_id: args.owner_id,
			renter_id: args.renter_id
		};
		if ( payerable_type ) params[payerable_type.toLowerCase().substring(0, payerable_type.length-1) + '_id'] = payerable_id; 

		axios.get('/api/manager/properties/list', {
			params: params,
			cancelToken: axiosCancelToken?.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name + ' (' + el.number + ')'
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadPayerables = (input, callback, args) => {
		axios.get('/api/manager/previsions/get-payerables-list', {
			params: {
				community_id: getCommunity()?.id,
				search: input,
				no_paginate: true,
				property_id: args.property_id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label:  <div>
								{el.name}
								<div><small>{el.type.indexOf('Owner') !== -1 ? 'Propietario' : 'Inquilino'}</small></div>
							</div>
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const openPopupInfoWindow = (e) => {
		e.preventDefault();
		e.stopPropagation();

		openPopupWindow(e.currentTarget.href);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getPrevisions = async () => {
			await axios.get('/api/manager/previsions/list', {
				params: {
					community_id: community?.id,
					search: search,
					sort: sortField,
					direction: sortDirection,
					property_id: property?.id,
					payerable_type: payerable?.type,
					payerable_id: payerable?.id,
					estimate_id: estimate?.id
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setPrevisions([...response.data]);
		    	setSkeletonRows(response.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getPrevisions();
	}, [forceReload, page, search, sortField, sortDirection, community?.id, property, payerable?.id, payerable?.type, estimate?.id]);

	// Proxy for popup window to update when fields are changed on popup
	useEffect(() => {
		window.PopupProxyCallback = () => {
			setForceReload(Math.random());
		}
	}, []);

	if ( !community?.id ) return <Navigate to="/" />;

	const Layout = popup ? PopupLayout : LoggedLayout;

	return (
		<Layout>
			<section>
				<div className="page-title">
					<h1>Previsiones</h1>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadEstimates}
										onChange={(value) => setEstimate(value)}
										timeout={250}
										label={estimate?.name}
										placeholder="- Presupuesto -"
										value={estimate?.id}
									/>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									{ !hide_property_selector &&
										<EmpoweredSelector
											load={loadProperties}
											args={{
												owner_id: payerable?.type?.indexOf('Owner') !== -1 ? payerable?.id : null,
												renter_id: payerable?.type?.indexOf('Renter') !== -1 ? payerable?.id : null,
											}}
											onChange={(value) => {
												setProperty(value);
												
												// If payer is not on payers array, reset
												let isOwner = value?.owners?.filter((el) => {
													return el.id === payerable?.id && payerable.indexOf('Owner') !== -1;
												}).length ? true: false;

												let isRenter = value?.renters?.filter((el) => {
													return el.id === payerable?.id && payerable.indexOf('Renter') !== -1;
												}).length ? true: false;

												if ( value && !isOwner && !isRenter ) setPayerable(null);
											}}
											timeout={250}
											label={property?.name}
											placeholder="- Propiedad -"
											value={property?.id}
										/>
									}
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									{ !hide_payerable_selector &&
										<EmpoweredSelector
											load={loadPayerables}
											args={{property_id: property?.id}}
											onChange={(value) => setPayerable(value)}
											timeout={250}
											label={payerable?.name}
											placeholder="- Pagador -"
											value={payerable?.id}
										/>
									}
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									
								</div>
								<div className="col-md-1 text-end mb-2 mt-2 mb-md-0 mt-md-0">
									<button className="btn btn-sm btn-light" onClick={() => setShowExportModal(true)}>Exportar</button>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'estimate'} onClick={() => sortTableClick('estimate')}>Presupuesto</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'number'} onClick={() => sortTableClick('number')}>Emisión</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'date'} onClick={() => sortTableClick('date')}>Fecha</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'property'} onClick={() => sortTableClick('property')}>Propiedad</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'payerable'} onClick={() => sortTableClick('payerable')}>Pagador</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'amount'} onClick={() => sortTableClick('amount')}>Importe</ThSortable>
										</tr>
									</thead>
									<tbody>
										{ previsions?.length > 0 &&
											previsions.map((el, idx) => {
												let payerable_link_string = '';
												if ( el.payerable?.id ) {
													let split = el.payerable.type.split('\\');
													payerable_link_string = ('' + split.at(-1)).toLowerCase();
												}

												return ( 
													<tr key={idx} className={el.passed ? 'passed' : ''}>
														<td>
															{el.estimate.name}&nbsp;
															<NavLink to={'/estimates/edit/' + el.estimate.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
														</td>
														<td>{el.number}</td>
														<td>
															{ el.date && 
																<React.Fragment>
																	{moment(el.date).locale('es').format('MMMM')} / {moment(el.date).format('YYYY')}
																</React.Fragment> 
															}
															{ !el.date && '-' }	
														</td>
														<td>
															{el.property.name}&nbsp;
															<NavLink to={'/properties/edit/' + el.property.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
														</td>
														<td>
															{ el.payerable?.id ?
																<React.Fragment>
																	{el.payerable?.name} / {el.payerable?.vatnumber}&nbsp;
																	{ el.payerable?.id &&
																		<NavLink to={'/'+payerable_link_string+'s/edit/' + el.payerable.id + '?popup=true'} onClick={openPopupInfoWindow}><i className="bi bi-eye"></i></NavLink>
																	}
																</React.Fragment>
																:
																<div className="text-danger">
																	No tiene pagador
																</div>
															}
														</td>
														<td>{formatNumber(el.amount)} €</td>
													</tr>
												);
											})
										}

										{ previsions && !previsions.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ previsions === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
			{ showExportModal &&
				<ExportModal
					exportKey={'previsions'}
					fileName={'Previsiones ' + moment().format('DD-MM-YYYY HH:mm')}
					fields={{
						estimate: 				'Presupuesto',
						number: 				'Nº',
						date: 					'Fecha',
						property: 				'Propiedad',
						payerable: 				'Pagador',
						amount: 				'Importe',
					}}
					filters={{
						community_id: 		community?.id,
						search: 			search,
						sort: 				sortField,
						direction: 			sortDirection,
						property_id: 		property?.id,
						payerable_type: 	payerable?.type,
						payerable_id: 		payerable?.id,
						estimate_id: 		estimate?.id
					}}
					closeCallback={() => setShowExportModal(false)}
				/>
			}
		</Layout>
	);
}


