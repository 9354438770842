import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { CristalLoader, downloadLocalFile } from "helpers/generic";
import moment from "moment";

export default function BulkActionsDropdown(props) {
    const selectedItems = props.selectedItems;

	const [loading, setLoading] = useState(false);

    const downloadBulk = async () => {
        setLoading(true);

        if ( selectedItems.length > 1 ) {
            let zip = new JSZip();
            let withErrors = false;

            for await (let item of selectedItems) {
                if ( !item.file ) continue;

                let result = await downloadFileFix(item.file.id);
                if ( result ) {
                    let fileName = item.file.name;
                    if ( !fileName.includes('.') ) {
                        fileName += '.' + item.file.extension;
                    }
                    zip.file(fileName, result);
                } else {
                    withErrors = true;
                }
            }
    
            zip.generateAsync({type: 'blob'}).then((content) => {
                downloadLocalFile(content, 'Facturas recibidas ' + moment().format('DD-MM-YYYY') + ' ' +  moment().format('HH:mm:ss') + ' .zip');
            });

            if ( withErrors ) {
                toast.error('Se han descargado los gastos pero algunos contienen errores y no se han añadido al archivo comprimido');
            }
        } else if (selectedItems[0].file) { // Single expense
            let result = await downloadFileFix(selectedItems[0].file.id);
            downloadLocalFile(result, selectedItems[0].file.name + '.' + selectedItems[0].file.extension);
        }

        setLoading(false);
    }

    const downloadFileFix = async (fileId) => {
        return await axios({
            url: '/api/manager/documents/download-file/' + fileId,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            return response.data;
        }).catch((error) => {
            return null;
        });
    }

	return (
        <>
            {loading && <CristalLoader />}
            <button className="btn btn-light btn-sm py-1 ms-2" onClick={() => downloadBulk()}>
                <i className="bi bi-download"></i>&nbsp;
                Descargar 
            </button>

            {/* <div className="dropdown d-inline-block">
                <button className="btn btn-light btn-sm dropdown-toggle py-1" type="button" data-bs-toggle="dropdown" onClick={(e) => e.stopPropagation()}>
                    Acciones 
                </button>
                <ul className="dropdown-menu dropdown-tablerow-actions">
                    <li>
                        <button className="dropdown-item" onClick={() => downloadBulk()}>
                            <i className="bi bi-download"></i>&nbsp;
                            Descargar
                        </button>
                    </li>
                </ul>
            </div> */}
        </>
	);
}