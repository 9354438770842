import React, { useState, useEffect } from 'react';
import { Navigate, NavLink, useSearchParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import Paginator from 'components/Paginator';
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { getCommunity } from 'helpers/community';
import { getFromSession, appendToSession } from 'helpers/session';
import { authUserPermission } from 'helpers/community';
import { formatNumber } from 'helpers/generic';
import BankAggregatorLogModal from 'components/BankAggregatorLogModal';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 80px;
		}

		&:nth-child(3) {
			width: 150px;
		}

		&:nth-child(4),
		&:nth-child(5) {
			text-align: right;
			width: 120px;
		}

		&:nth-child(6) {
			width: 150px;
		}

		&:nth-child(7) {
			width: 65px;
		}
		
		@media (max-width: 768px) {
			&:nth-child(3),
			&:nth-child(4) {
				display: none;
			}
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Treasury() {
	const [queryParams] = useSearchParams();

	let filters = getFromSession('treasury-list');

	let community = getCommunity();
	let [treasuries, setTreasuries] = useState({});
	let [search, setSearch] = useState(filters?.search ?? undefined);
	let [sortDirection, setSortDirection] = useState(filters?.direction ?? 'asc');
	let [sortField, setSortField] = useState(filters?.sort ?? 'name');
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? (filters?.page ?? 1));
	const setPage = (page) => {
		setTreasuries({...treasuries, data: undefined});
		_setPage(page);
	}
	let [bankAggregatorLogModalOpened, setBankAggregatorLogModalOpened] = useState(null);

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getTreasuries = async () => {
			let params = {
				community_id: community?.id,
				page: page,
				search: search,
				sort: sortField,
				direction: sortDirection,
				with_balance: true
			};

			appendToSession('treasury-list', {page: page, search: search, sort: sortField, direction: sortDirection});

			await axios.get('/api/manager/treasury/list', {
				params: params,
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setTreasuries({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});	
		}

		getTreasuries();
	}, [page, search, sortField, sortDirection, community?.id]);

	if ( !community?.id ) return <Navigate to="/" />;

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Tesorería</h1>
					
					{ authUserPermission('add') &&
						<NavLink to="/treasury/add" className="btn btn-sm btn-light ms-auto">Nueva tesorería</NavLink>
					}
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<ThSortable direction={sortDirection} active={sortField === 'code'} onClick={() => sortTableClick('code')}>Código</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'type'} onClick={() => sortTableClick('type')}>Tipo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'contableaccount.balance'} onClick={() => sortTableClick('contableaccount.balance')}>Saldo</ThSortable>
											<ThSortable direction={sortDirection} active={sortField === 'real_balance'} onClick={() => sortTableClick('real_balance')}>Saldo real</ThSortable>
											<th>Última sincronización</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{ treasuries.data?.length > 0 &&
											treasuries.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.code ?? '-'}</td>
															<td>
																{el.name ?? '-'}
																{ el.default === 1 && 
																	<div><small><i className="bi bi-asterisk text-primary"></i> Por defecto</small></div>
																}
																{el.type === 'bankaccount' &&
																	<div><small>{el?.data?.iban}</small></div>
																}
															</td>
															<td>{el.type === 'bankaccount' ? 'Cuenta bancaria' : 'Caja / efectivo'}</td>
															<td>{el.contableaccount ? formatNumber(el.contableaccount.balance) : 0}</td>
															<td>{el.real_balance ? formatNumber(el.real_balance) : 0}</td>
															<td>
																<button className="btn btn-link btn-sm p-0 me-2" onClick={() => setBankAggregatorLogModalOpened(el)}><i className="bi bi-eye"></i></button>	
																{el.synced_at ? moment(el.synced_at).format('DD-MM-YYYY HH:mm') : ''}
															</td>
															<td className="text-end">
																{ !el.data_completed &&
																	<span className="badge bg-danger rounded-circle me-2">&nbsp;</span>
																}
																<NavLink className="btn-table" to={'/treasury/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ treasuries.data && !treasuries.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ treasuries.data === undefined && <TrSkeleton rows={skeletonRows} columns={7} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={treasuries?.current_page}
									max={treasuries?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			{bankAggregatorLogModalOpened &&
				<BankAggregatorLogModal 
					treasury={bankAggregatorLogModalOpened}
					closeCallback={() => {
						setBankAggregatorLogModalOpened(false);
					}}
				/>
			}
		</LoggedLayout>
	);
}


