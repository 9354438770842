import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LabeledFrame from 'components/LabeledFrame';
import PopupLayout from 'layouts/PopupLayout';
import { CristalLoader } from 'helpers/generic';
import EmpoweredSelector from 'components/EmpoweredSelector';
import TextEditor from 'components/TextEditor';
import Attachments from './Attachments';
import ExternalRecipients from './ExternalRecipients';
import Recipients from './Recipients';
import { authUserPermission, getCommunity } from 'helpers/community';

const TemplateSelectorWrapper = styled.div`
	.empowered-selector {
		.empowered-selector_label {
			margin: 0;
			padding: 0;
			background: transparent;
			height: auto;
			min-height: auto;
			font-size: 12px;
			border: 0;
			color: white;

			i {
				position: unset;
				margin-top: 3px;
				margin-left: 5px;
			}
		}

		.empowered-selector_menu {
			width: 250px;
			right: -11px;
		}
	}
`;

let axiosCancelToken = null;

export default function EmailsForm(props) {
	const navigate = useNavigate();
	const params = useParams();
	const [queryParams] = useSearchParams();

	const copy_from_id = queryParams.get('copy_from_id');
	const subject = queryParams.get('subject');

	let [data, setData] = useState({
		status: 'draft',
		community_id: getCommunity()?.id,
		subject: subject ?? ''
	});
	let [errors, setErrors] = useState({});
	let [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				setLoading(true);

				await axios.get('/api/manager/emails/get/' + params.id + '?community_id=' + getCommunity()?.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});

				setLoading(false);
			}
			getData();
		} else {
			const copyFromEmail = () => {
				// Get data
				axios.get('/api/manager/emails/get/' + copy_from_id, {
					cancelToken: axiosCancelToken.token,
					params: {
						community_id: getCommunity()?.id
					}
				}).then((response) => {
					let dataToCopy = {
						...response.data, 
						id: null, 
						recipients_ids: [], 
						recipients_external: [], 
						status: 'draft'
					};

					if ( dataToCopy.attachments ) dataToCopy.copy_attachments = [...dataToCopy.attachments];

					setData((data) => ({...data, ...dataToCopy}));
				}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			if ( copy_from_id ) copyFromEmail();
			else setData((prev) => ({...prev, recipients_type: 'owners'}));
		}
	}, [params.id, copy_from_id]);

	const loadTemplates = (input, callback, args) => {
		axios.get('/api/manager/templates/list', {
			params: {
				search: input,
				no_paginate: true,
				type: args.recipients_type
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.title
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectTemplate = (template) => {
		setData((prev) => ({...prev, message: template.content}));
	}

	const deleteEmail = () => {
		const c = window.confirm('¿Quieres eliminar este email?');
		if ( !c ) return;

		axios.post('/api/manager/emails/delete/' + data.id, {
			community_id: data.community_id
		}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
	    	toast.info('Email borrado')
	    	navigate('/emails');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const saveData = (goBack = true, send = false) => {
		setErrors({});

		let formData = new FormData();
		for(let i in data) {
			if ( data[i] === undefined || data[i] === null ) continue;
			formData.append(i, data[i]);
		}
		if ( data.remove_attachments ) {
			formData.delete('remove_attachments');
			for(let i in data.remove_attachments ) {
				formData.set('remove_attachments['+i+']', data.remove_attachments[i]);
			}
		}
		if ( data.recipients_ids ) {
			formData.delete('recipients_ids');
			for(let i in data.recipients_ids ) {
				formData.set('recipients_ids['+i+']', data.recipients_ids[i]);
			}
		}
		if ( data.recipients_external ) {
			formData.delete('recipients_external');
			for(let i in data.recipients_external ) {
				formData.set('recipients_external['+i+']', data.recipients_external[i]);
			}
		}
		if (data.filesToUpload) {
			formData.delete('filesToUpload');
			for(let i in data.filesToUpload ) {
				formData.set('filesToUpload['+i+']', data.filesToUpload[i]);
			}
		}
		if (data.copy_attachments) {
			formData.delete('copy_attachments');
			for(let i in data.copy_attachments ) {
				formData.set('copy_attachments['+i+'][name]', data.copy_attachments[i].name);
				formData.set('copy_attachments['+i+'][path]', data.copy_attachments[i].path);
			}
		}

		if ( send ) formData.set('send', 1);

		axios.post('/api/manager/emails/' + (data.id ? 'edit/' + data.id : 'add'), formData, {
			headers: {
				'Content-Type': 'multipart/form-data' 
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			setData({...response.data.email});
			if ( goBack ) navigate('/emails');
			else {
		    	navigate('/emails/edit/' + response.data.email.id);
			}
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData({...data, [field]: value});
	}

	if ( !data.community_id ) return <Navigate to="/"></Navigate>;

	return (
		<PopupLayout>
			{ loading && <CristalLoader /> }

			<section>
				<div className="page-title">
					<h1>Ficha de email</h1>
					<button onClick={() => navigate('/emails')} className="btn btn-sm btn-light ms-auto">Volver</button>
				</div>

				<div className="page-content">
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col md-6">
											<div className="row">
												<div className="col-lg-12">
													<div className="mb-2">
														<label>Asunto</label>
														<input type="text" className="form-control form-control-sm" onChange={(e) => setDataField('subject', e.target.value)} value={data.subject ?? ''} />
														{ errors.subject &&
															<div className="invalid-feedback d-block">{ errors.subject[0] }</div>
														}
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-2 mt-2">
														<LabeledFrame
															label="Mensaje"
															buttons={
																<TemplateSelectorWrapper>
																	<EmpoweredSelector
																		load={loadTemplates}
																		args={{recipients_type: data.recipients_type}}
																		onChange={(value) => selectTemplate(value)}
																		timeout={250}
																		label={null}
																		placeholder="Seleccionar plantilla"
																		value={null}
																	/>
																</TemplateSelectorWrapper>
															}
															buttonsBackground={'primary'}
															contentPadding="0 !important"
														>
															<TextEditor
																text={data.message ?? ''}
																setText={(text) => setData((prev) => ({...prev, message: text}))}
																type={data.recipients_type}
																border={false}
															/>
															{ errors.message &&
																<div className="invalid-feedback d-block">{ errors.message[0] }</div>
															}
														</LabeledFrame>
													</div>
												</div>
											</div>
										</div>
										<div className="col md-6">
											<div className="row">
												<div className="col-md-12">
													<div className="mb-3">
														<Attachments 
															data={data}
															setData={setData}
															errors={errors}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<ExternalRecipients 
															data={data}
															setData={setData}
															errors={errors}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="mb-3">
														<Recipients 
															data={data}
															setData={setData}
															errors={errors}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>	
								</div>
								<div className="card-footer" id="footer-fixed">
									<div className="row">
										<div className="col-4">
											{ data.id &&
												<React.Fragment>
													{ data.is_deletable === true && <button className="btn btn-sm btn-link text-danger" tabIndex="-1" onClick={() => deleteEmail()}>Eliminar</button> }
												</React.Fragment>
											}
										</div>
										<div className="col-8 text-end">
											{ data.status === 'draft' &&
												<React.Fragment>
													{ (authUserPermission('add') || (data.id && authUserPermission('edit'))) &&
														<React.Fragment>
															<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData(false)}>Guardar</button>							
															<button className="btn btn-sm btn-primary text-white d-inline ms-3" onClick={() => saveData()}>Guardar y salir</button>							
														</React.Fragment>
													}
													{/* { (data.recipients_ids?.length > 0 && authUserPermission('add')) && */}
													{ (authUserPermission('add')) &&
														<button className="btn btn-sm btn-success text-white d-inline ms-3" onClick={() => saveData(true, true)}>Guardar y enviar</button>							
													}
												</React.Fragment>
											} 
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</PopupLayout>
	);
}