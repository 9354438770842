import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import styled from 'styled-components';
import Select from 'react-select'
import LoggedLayout from 'layouts/LoggedLayout';
import { getCommunity } from 'helpers/community';
import { appendToSession, getFromSession } from 'helpers/session';
import EmpoweredSelector from 'components/EmpoweredSelector';
import List from './List';
import SectorsCheckList from './SectorsCheckList';

const SectorsSelectorWrapper = styled.div`
	display: inline-flex;
	position: relative;
	min-width: 200px;
	width: 100%;
	z-index: 3;

	#sectors-selector {
		width: 100%;
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Contracts() {
	let filters = getFromSession('contracts-list');

	let [sectors, setSectors] = useState([]);
	let [sectorsId, setSectorsId] = useState(filters?.sectors_id ?? []);
	let [search, setSearch] = useState(filters?.search ?? undefined);
	let [showExportModal, setShowExportModal] = useState(false);
	let [sectorsCheckEnabled, setSectorsCheckEnabled] = useState(false);
	let [community, setCommunity] = useState(filters?.community ?? {
		id: getCommunity().id,
		name: getCommunity().name
	});

	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
		}, 1000);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getSectors = async () => {
			await axios.get('/api/manager/sectors/list', {
				params: {
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setSectors([...response.data]);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getSectors();
	}, []);

	const loadCommunities = (input, callback) => {
		axios.get('/api/manager/communities/list', {
			params: {
				search: input,
				no_paginate: true,
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectCommunity = (value) => {
		setCommunity({
			id: value?.id,
			name: value?.name
		});
	}

	return (
		<LoggedLayout>
			<section>
				<div className="page-title" style={{zIndex: 5}}>
					<h1>Contratos</h1>
					
					<NavLink to="/contracts/add" className="btn btn-sm btn-light ms-auto">Nuevo contrato</NavLink>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0">
									<input type="search" className="form-control form-control-sm" placeholder="Buscar" defaultValue={search ?? ''} onChange={(e) => setSearchTimeout(e.target.value)} />
								</div>
								<div className="col-md-3 col-xl-2 mb-2 mb-xl-0">
									<EmpoweredSelector
										load={loadCommunities}
										onChange={(value) => selectCommunity(value)}
										timeout={250}
										label={community?.name ?? ''}
										placeholder="- Comunidad -"
										value={community?.id}
									/>
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
									<SectorsSelectorWrapper>
										<Select 
											id="sectors-selector"
											isMulti
											menuPosition={'fixed'}
											placeholder={'- Sectores -'}
											options={sectors.map(el => ({value: el.id, label: el.name}))}
											onChange={(e) => {
												let ids = e.map(el => el.value);
												setSectorsId(ids);
											}} 
											value={sectorsId.length > 0 ? sectors.filter(el => sectorsId.includes(el.id)).map(el => ({value: el.id, label: el.name})) : []}
										/>
									</SectorsSelectorWrapper>

									<div className={'form-check form-switch mb-0 d-inline-flex align-items-center'}>
										<input className="form-check-input mb-1 me-2" type="checkbox" checked={sectorsCheckEnabled} onChange={(e) => setSectorsCheckEnabled(e.target.checked)} />
										<label className="form-check-label">Activar chequeo de sectores</label>
									</div>
									{(sectorsCheckEnabled && !sectorsId.length) && <div className="text-danger">Debe seleccionar al menos un sector</div>}
								</div>
								<div className="col-md-3 mb-2 mt-2 mb-md-0 mt-md-0 text-end">
									{ !sectorsCheckEnabled &&
										<button className="btn btn-sm btn-light me-2" onClick={() => setShowExportModal(true)}>Exportar</button>
									}
								</div>
							</div>
						</div>
						{ sectorsCheckEnabled ?
							<SectorsCheckList 
								communityId={community?.id}
								sectorsId={sectorsId}
								search={search}
							/>
							:
							<List 
								communityId={community?.id}
								sectorsId={sectorsId}
								search={search}
								showExportModal={showExportModal}
								exportModalCloseCallback={() => setShowExportModal(false)}

								filters={filters}
								returnFilters={(filters) => {
									appendToSession('contracts-list', {...filters, community: community});
								}}
							/>
						}	
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


